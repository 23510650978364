<template>
  <div class="dashboard-nav">
		<div class="dashboard-nav-inner">

			<ul data-submenu-title="Principale">
				<!-- <li><router-link to="/dashboard-company-my-shop"><i class="fa fa-shopping-cart"></i> Ordini</router-link></li> -->
				<!-- <li><router-link to="/dashboard-company-my-shop"><i class="sl sl-icon-envelope-open"></i> Messaggi <span class="nav-tag messages">2</span></router-link></li> -->
				<li :class="$route.path === '/dashboard-my-shops' ? 'active' : ''"><router-link to="dashboard-my-shops"><i class="sl sl-icon-heart"></i> I miei Preferiti </router-link></li>
				<li v-if="this.$store.state.shop.shop"><router-link :to="'/shop?id='+this.$store.state.shop.shop.id"><i class="im  im-icon-Shop"></i>Preview mio Negozio</router-link></li>
				<li v-if="this.$store.state.user.user.role_id === 5"><router-link :to="'/dashboard-create-new-shop'"><i class="fa fa-plus"></i>Crea Nuovo Negozio</router-link></li>
				<!-- <li><a href="dashboard-bookings.html"><i class="fa fa-calendar-check-o"></i> Bookings</a></li> -->
				<!-- <li><a href="dashboard-wallet.html"><i class="sl sl-icon-wallet"></i> Wallet</a></li> -->
			</ul>
			
			<!-- <ul data-submenu-title="Listings">
				<li><a><i class="sl sl-icon-layers"></i> My Listings</a>
					<ul>
						<li><a href="dashboard-my-listings.html">Active <span class="nav-tag green">6</span></a></li>
						<li><a href="dashboard-my-listings.html">Pending <span class="nav-tag yellow">1</span></a></li>
						<li><a href="dashboard-my-listings.html">Expired <span class="nav-tag red">2</span></a></li>
					</ul>	
				</li>
				<li><a href="dashboard-reviews.html"><i class="sl sl-icon-star"></i> Reviews</a></li>
				<li><a href="dashboard-bookmarks.html"><i class="sl sl-icon-heart"></i> Bookmarks</a></li>
				<li><a href="dashboard-add-listing.html"><i class="sl sl-icon-plus"></i> Add Listing</a></li>
			</ul>	 -->

			<ul data-submenu-title="Account">
				<li :class="($route.path === '/dashboard-company-my-profile' || $route.path === '/dashboard-company') ? 'active' : ''"><router-link to="/dashboard-company-my-profile"><i class="sl sl-icon-user"></i> Il mio Profilo</router-link></li>
				<li v-if="this.$store.state.user.user ? this.$store.state.user.user.role_id === 5 ? true : false : false" :class="$route.path === '/dashboard-company-my-shop' ? 'active' : ''">
					<!--<router-link to="/dashboard-company-my-shop"><i class="im im-icon-Shop-3"></i> Il mio Negozio</router-link> -->
					
						<router-link to="/dashboard-company-my-shop?shop=0"><i class="im im-icon-Shop-3"></i> Il mio Negozio</router-link>
						<ul v-for="(shop, i) in this.$store.state.user.user.myworkshops" v-bind:key="shop.id" :i="i">
							<li><a v-on:click="changeShop(i)">{{shop.label}}</a></li>
						</ul>
					</li>
				
				<li><router-link to="/"><i class="sl sl-icon-power"></i> Home</router-link></li>
			</ul>
			

			<ul data-submenu-title="Applicazioni" v-if="get_app_third_part.length > 0">
				<span v-for="app in get_app_third_part" :key="app.name">
					<li :class="$route.query.app === app.name ? 'active' : ''">
						<a v-if="!app.value.admin_iframe" :href="app.value.admin_url" target="_blank"><i :class="app.name === 'myfood_listino' ? 'im im-icon-Bulleted-List' : 'im im-icon-Neutron'"></i> {{ app.value.label }}</a>
						<router-link v-else :to="'/app-third-part?app='+app.name"><i class="im im-icon-Neutron"></i> {{ app.value.label }}</router-link>
					</li>
					<!-- <li v-if="get_app_third_part.map(el => el.name).includes('myfood_listino')" :class="$route.path === '/dashboard-qr-code' ? 'active' : ''"><router-link to="/dashboard-qr-code"><i class="im im-icon-QR-Code"></i> Genera QR Menù</router-link></li> -->
					<li v-if="app.name === 'myfood_listino'" :class="$route.path === '/dashboard-qr-code' ? 'active' : ''"><router-link to="/dashboard-qr-code"><i class="im im-icon-QR-Code"></i> Genera QR Menù</router-link></li>
				</span>
				
			</ul>

			<!-- Nel v-if ci andrà il controllo sulla variabile della partita iva hashata. Sarà salvata nello store al login -->
			<!-- Nell'href ci andrà l'hash -->
			<!-- <ul data-submenu-title="Applicazioni" v-if="get_listino_token">
				<li><a :href="'https://myfood.okkam.it/login-vetrineonline?volid='+get_listino_token" target="_blank"><i class="im im-icon-Bulleted-List"></i> Menù</a></li>
				<li :class="$route.path === '/dashboard-qr-code' ? 'active' : ''"><router-link to="/dashboard-qr-code"><i class="im im-icon-QR-Code"></i> Genera QR Menù</router-link></li>
			</ul> -->
		</div>
	</div>
</template>

<script>
import ShopService from '@/services/ShopService'
import ProfileService from '@/services/ProfileService'
export default {
	name: 'side-bar',
	data() {
		return {
			arrayShops: [], //PER OPZIONE PIU SHOP
		}
	},
	computed: {
		get_app_third_part: function() {
			return this.$store.state.shop_meta.shop_meta ? this.$store.state.shop_meta.shop_meta.thrd_part_apps.value : []
		},
		get_listino_token: function() {
			return this.$store.state.shop.shop ? this.$store.state.shop.shop.listino_token ? this.$store.state.shop.shop.listino_token : null : null
		}
	},
	// PER OPZIONE PIU SHOP
	methods: {
		//fatte io
		async getShopData() {
			console.log("id "+this.$store.state.user.user.id)
			var dati = await ProfileService.getData(this.$store.state.user.user.id);
			this.$store.state.user.user.myworkshops=dati.data.myworkshops
			console.log(this.$store.state.user.user.myworkshops)
			console.log("shops")
			console.log(dati.data.myworkshops)
			this.arrayShops = dati.data.myworkshops
			return dati.data
		},

		async changeShop(index) {
			const datiShop = await this.getShopData()
			console.log("id "+this.arrayShops[index].id +" length array "+this.arrayShops.length)
			if(index >= 0 && index < this.arrayShops.length ){
			this.loading = true
			try {
				const shop_meta = await ShopService.getShopMeta(this.arrayShops[index].id)
				console.log("shop meta ")
				console.log(shop_meta.data)
				this.$store.dispatch('shop/setShop', shop_meta.data)
				this.$store.dispatch('shop_meta/setShopMeta', shop_meta.data.metas)
				this.$store.dispatch('shop_address/setAddress', shop_meta.data.addresses)
				console.log('data shop: ', shop_meta)

				console.log('IMMAGINI: ', shop_meta.data.logo, shop_meta.data.gallery)
				shop_meta.data.logo ? shop_meta.data.logo.value ? shop_meta.data.logo.value.id = shop_meta.data.logo.id : null : null
				this.$store.dispatch('shop_images/setLogo', shop_meta.data.logo ? shop_meta.data.logo.value : null)
				this.$store.dispatch('shop_images/setGallery', shop_meta.data.gallery.map((el) => {
				el ? el.value ? el.value.id = el.id : null : null
				return el.value
				}))
				this.loading = false
				//this.$eventHub.$on('loading', false) 
				console.log("cliccato su changeShop("+index+")")
				//this.$route.params.id = index
				this.$router.push({
					path: '/dashboard-company-my-shop?shop='+index
				})
				
			} catch(error) {
				console.log('Errore in loading shop data: ', error)
			}
			} else {
			console.log("index fuori range")
			}
		
		},
	}

}
</script>

<style>

</style>
<script>

import orari    from './orari';
import dayrow   from './dayrow';
import timeslot from './timeslot';

import Vue from 'vue'
// import {BootstrapVue} from 'bootstrap-vue'
// Vue.use(BootstrapVue)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
export default {
    name: 'orari',
    props: [ 'inputobj', 'edit', 'title'],
    components: {
        dayrow,
        timeslot,
    },
    created() {
        // test
        // var obj = [
        //     {
        //         days: [ 1, 2, 3, 4, 5 ], // Lu, Ma, Me, Gi, Ve (Do: 7)
        //         times: [
        //             { from: '08:00', to: '12:30' },
        //             { from: '15:30', to: '19:30' },
        //         ],  
        //     },      
        //     {       
        //         days: [ 6 ], // Sa
        //         times: [ 
        //             { from: '08:00', to: '15:00' },
        //         ],
        //     },
        // ];
        // this.obj      = new orari( obj );
        // this.original = JSON.parse( JSON.stringify( this.obj.json ) );
        //
        this.obj = new orari( this.inputobj || null );
    },
    computed: {
        items () {
            return this.obj.items;
        },
        _edit () {
            return this.edit ? this.edit : true;
        }
    },
    methods: {
        remove_item( obj ) {
            this.obj.delete_item( obj.index );
            this.key += 1;
        },
        remove_day( obj ) {
            this.obj.remove_day( obj.index, obj.day_index );
        },
        remove_time( obj ) {
            this.obj.remove_time( obj.item_index, obj.time_index );
            this.key += 1;
        },
        save_time( obj ) {
            this.obj.add_time( obj.item_index, obj.time );
        },
        add_day( obj ) {
            this.obj.add_day( obj.index, obj.day_index )
        },
        add_item() {
            this.obj.add_item();
        },
        check_changes() {
            return this.obj.string !== JSON.stringify( this.original );
        },
        save() {
            this.$emit('save', this.obj.json);
            console.log( 'emit "save"' );
            console.log( this.obj.json );
        },
        reset() {
            this.obj = new orari( this.original );
            this.original = JSON.parse( JSON.stringify( this.obj.json ) );
            this.key += 1;
        },
        add_time(obj) {
            this.$refs[ 'timeslot_' + obj.item_index ][0].set_add();
        },
    },
    data () {
        return {
            obj      : null,
            original : null,
            key      : 0,
        }
    },
};

</script>

<template>

    <div :key="key">
        <!-- <div style="background-color: #333; padding:1px; margin-bottom: 20px;"><h2 style="color:white; margin-left: 15px; margin-bottom: 10px; margin-top: 10px;">Orari di {{ title }}</h2></div>        -->
        <div class="mt-3"  v-if = "_edit">
            <div style="background-color: #f3f3f3; padding-bottom: 10px; padding-top: 10px; text-align: center;">
                <button class="button button_small info"
                    @click   = "add_item()"
                    :style="obj.used_days_index.length === 7 ? { cursor: 'not-allowed' } : { cursor: 'pointer' }"
                    style    = "margin-right: 10px;"
                ><i class="im im-icon-Add" style="margin-right: 5px;"></i>Aggiungi giornate di {{title}}</button>
                <!-- <button class="button button_small save"
                    @click   = "save()"
                    :style   ="check_changes() ? { cursor: 'pointer' } : { cursor: 'not-allowed' }"
                    style    = "margin-right: 10px;"
                ><i class="im im-icon-Save" style="margin-right: 5px;"></i>salva</button> -->
                <!-- <button class="button button_small reset"
                    @click   = "reset()"
                    :style   ="check_changes() ? { cursor: 'pointer' } : { cursor: 'not-allowed' }"
                    style    = "margin-right: 10px;"
                ><i class="im im-icon-Repeat-2" style="margin-right: 5px;"></i>reset</button> -->
            </div>
        </div>
        <template v-for="time_slot in items">
            <dayrow
                :inputdays  = "time_slot.days"
                :days       = "obj.days"
                :useddays   = "obj.used_days_index"
                :index      = "time_slot._index"
                :edit       = "_edit"
                @removeitem = "remove_item"
                @removeday  = "remove_day"
                @addday     = "add_day"
                @addtime    = "add_time"
            ></dayrow>
            <timeslot
                :inputtimes = "time_slot.times"
                :index      = "time_slot._index"
                :edit       = "_edit"
                :ref        = "'timeslot_' + time_slot._index"
                @removetime = "remove_time"
                @savetime   = "save_time"
            ></timeslot>
        </template>
        <div v-if="items.length > 0">
            <span>E' possibile selezionare più giorni con le stesse fascie orarie.</span><br>
            <span><a class="tutorial_orari" href="https://youtu.be/InaEnfV0n_0" target="_blank">Guarda il video dimostrativo! Clicca</a></span>
        </div>
        <div v-else>
            <span>Clicca "Aggiungi giornate di {{ title }}" per inserire gli orari</span><br>
            <span><a class="tutorial_orari" href="https://youtu.be/InaEnfV0n_0" target="_blank">Guarda il video dimostrativo! Clicca</a></span>
        </div>
        
    </div>
</template>

<style scoped>
  .button_small {
    padding: 6px 15px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    color: #343a40;
    font-weight: bold;
    font-size: 16px;
    transition: .3s;
  }
  .info {
    color: #343a40;
    font-weight: bold;
    background-color: transparent;
    opacity: 0.8;
  }
  .info:hover {
    color: #17a2b8;
  }
  .save {
    color: #343a40;
    font-weight: bold;
    background-color: transparent!important;
    opacity: 0.8;
  }
  .save:hover {
    color: #28a745;
  }
  .reset {
    color: #343a40;
    font-weight: bold;
    background-color: transparent!important;
    opacity: 0.8;
  }
  .reset:hover {
    color: #f91942;
  }
  .tutorial_orari:hover {
    color: #f91942;
    transition: .3s;
  }
</style>


<template>
  <div id="wrapper">
    <HeaderDashboard/>
    <div id="dashboard">
      <!-- Responsive Navigation Trigger -->
	    <a href="#" class="dashboard-responsive-nav-trigger"><i class="fa fa-reorder"></i> Dashboard Navigation</a>
      <SideBar/>

      <!-- Content
      ================================================== -->
      <div class="dashboard-content">

        <!-- Titlebar -->
        <div id="titlebar">
          <div class="row">
            <div class="col-md-12">
              <h2>App di terze parti</h2>
              <!-- Breadcrumbs -->
              <nav id="breadcrumbs">
                <ul>
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Dashboard</a></li>
                  <li>App di terze parti</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-lg-12 col-md-12">
            <h3>{{get_app_third_part.name}}</h3>
            <p>{{get_app_third_part.description}}</p>
          </div> -->
          <div class="col-lg-12 col-md-12">
            <IframeCustom :applications="[get_app_third_part]" />
          </div>
          <!-- Change Password -->
          <!-- <div class="col-lg-6 col-md-12">
            {{get_app_third_part.description}}
          </div> -->


          <!-- Copyrights -->
          <div class="col-md-12">
           <div class="copyrights" style="font-size:12px;">
            <!-- <div class="col-xs-12 text-center">
              CONFCOMMERCIO SERVIZI DELLA CITTA' DELLA RIVIERA DEL BRENTA S.R.L.
            </div>
            <div class="col-xs-12 text-center">
                sede legale: Viale Ancona 9 30172 Mestre Ve
            </div>
            <div class="col-xs-12 text-center">
              sedi operative:
            </div>
            <div class="col-xs-12 text-center">
              Via Venezia 8 30034 Mira VE
            </div>
            <div class="col-xs-12 text-center">
              Via Benedetto Cairolo 18/D  30031 Dolo VE
            </div> -->
            <div class="col-xs-12 text-center">
              C.f.  e P.Iva 04498740275
            </div>
            <!-- <div class="col-xs-12 text-center">
              codice sdi  6EWHWLT
            </div> -->
            <div class="col-xs-12 text-center">
              <a class="privacy_policy_footer iubenda-black iubenda-embed" href="https://www.iubenda.com/privacy-policy/82105399" title="Privacy Policy ">Privacy e Policy</a>
            </div>
            <div class="col-xs-12 text-center">
              <router-link to="/all-shops">Tutti i Negozi</router-link>
            </div>
            <div class="col-xs-12 text-center">
              <a style="color: grey" href="mailto:assistenza@vetrineonline.com">assistenza@vetrineonline.com</a></h4>
            </div>
           </div>
          </div>

        </div>

      </div>
      <!-- Content / End -->

    </div>
  </div>
</template>

<script>
import HeaderDashboard from '@/components/HeaderDashboard'
import SideBar from '@/components/SideBar'
import IframeCustom from '@/components/Iframe'
export default {
  name: 'app-third-part',
  components: {
    HeaderDashboard,
    SideBar,
    IframeCustom
  },
  data() {
    return {

    }
  },
  mounted() {
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        document.body.appendChild(custom)
      })
    console.log('app: ', this.$route.query.app)
  },
  computed: {
		get_app_third_part: function() {
			return this.$store.state.shop_meta.shop_meta.thrd_part_apps.value.filter(el => el.name === this.$route.query.app)[0]
		}
	}
}
</script>

<style>

</style>

<script>

import _ from 'underscore';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

//

// const codes = require('./codes.json');

//const normalize = require('normalize.css');
const codes = require('../../utils/ateco_codes.json');

//

export default {
    name: 'atecoautocomplete',
    components: {
        VueBootstrapTypeahead
    },
    data() {
        return {
            ateco_rows     : [],
            selected_ateco : null,
            search         : '',
            max_matches    : 30,
            selected       : null
        }
    },
    props: {
        extsearch: {
            type: String,
            required: false,
            default: '',
        },
        disabled_element: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    mounted () {
        if ( this.extsearch ) {
            var selected_objs = codes.filter( x => x.code === this.extsearch );
            if ( selected_objs.length === 1 ) {
                this.$refs.ateco_autocomplete.inputValue = `${selected_objs[0].code} ${selected_objs[0].description}`;
                this.selected = selected_objs[0].code;
            }
        }
    },
    created (){
    }, 
    methods: {
        async get_ateco(str) {
            var suggestions_raw = [];
            var suggestions     = [];
            if ( /^\d/.test( str ) ) {
                suggestions_raw = codes.filter( x => x.code.startsWith( str ) );
            } else if ( /^[a-z]/i.test( str ) ) {
                suggestions_raw = codes.filter( x => {
                    var patt = new RegExp( str, 'i' );
                    return patt.test(x.description)
                });
            }
            var suggestions = JSON.parse( JSON.stringify( suggestions_raw ) );
            console.log( suggestions[0] );
            suggestions.sort(function(a, b) {
                return a.order - b.order;
            });
            console.log( suggestions[0] );
            this.ateco_rows = suggestions;
        },
    },

    watch: {
        search : _.debounce(function(addr) {
            // if (addr.length > 1)
                this.get_ateco(addr)
            }, 500),
        selected_ateco : function(ateco) {
            this.$emit('ateco_selected', { ateco_id: ateco.id, ateco: ateco.ateco });
            this.selected = ateco.code;
        },
    }
}

</script>

<template>

    <vue-bootstrap-typeahead
        class="ateco"
        :data="ateco_rows"
        v-model="search"
        :serializer="s => { return s.code + ' ' + s.description }"
        placeholder="Cerca codice ateco..."
        @hit="selected_ateco = $event"
        ref="ateco_autocomplete"
        :maxMatches="max_matches"
        :minMatchingChars="1"
    >
    </vue-bootstrap-typeahead>

</template>


<style>
    .special {
        background-color: lightgreen;
        color: red;
    }
    .ateco div input{
        width: 100%;
        color: rgb(51, 51, 51) !important;
        font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: none;
        height: 51px;
        line-height: 51px;
        padding: 0 20px;
        outline: none;
        font-size: 15px;
        color: #808080;
        margin: 0 0 16px 0;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        display: block;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.06);
        font-weight: 500;
        opacity: 1;
        border-radius: 3px;
    }
    .list-group shadow.vbt-autcomplete-list {
        background: white !important;
    }
    .vbt-autcomplete-list[data-v-48792d67] {
        max-height: 350px;
        overflow-y: auto;
        padding-top: 5px;
        position: absolute;
        z-index: 999;
    }
    .shadow {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
    .list-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
    }
    .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }
    .list-group-item:first-child {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
        .list-group-item {
        position: relative;
        display: block;
        padding: .75rem 1.25rem;
        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.125);
    }
    .list-group-item-action {
        width: 100%;
        color: #495057;
        text-align: inherit;
    }
    a {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
    }
    .list-group-item-action {
        width: 100%;
        color: #495057;
        text-align: inherit;
    }
</style>

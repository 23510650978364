<template>
<div>
  <div class="text-center" v-if="loading" style="position:absolute; top: 5%; left: 32%; height: 500px;">
    <div class="loader"></div>
  </div>
  <div id="user_data" :class="loading ? 'dashboard-list-box margin-top-0 fs-container opacity' : 'dashboard-list-box margin-top-0'">
    <h4 class="gray">Modifica i tuoi dati</h4>
    <div class="dashboard-list-box-static">

      <!-- Change Password -->
      <div class="my-profile">
        <!-- <label class="margin-top-0">Nome Negozio</label>
        <input v-model="shop_name" :disabled="success" type="text" :class="error ? 'input-text is-invalid' : 'input-text' ">

        <label for="shop_name">Categoria Negozio:
          <b-form-select v-model="shop_type" :options="options_shop_type" :disabled="success" :state="error ? false : null"></b-form-select>
        </label>

        <label class="margin-top-0">Partita Iva</label>
        <input v-model="pi" :disabled="success" type="text" :class="error ? 'input-text is-invalid' : 'input-text' ">

        <label class="margin-top-0">Codice Fiscale</label>
        <input v-model="cf" :disabled="success" type="text" :class="error ? 'input-text is-invalid' : 'input-text' "> -->

        <label class="margin-top-0">Nome</label>
        <input v-model="name" maxlength="64" :disabled="success" type="text" :class="'input-text' ">

        <label class="margin-top-0">Cognome</label>
        <input v-model="surname" maxlength="64" :disabled="success" type="text" :class="'input-text' ">

        <label>Cellulare</label>
        <input placeholder="Inserisci il numero di cellulare" maxlength="64" v-model="contacts.cellulare" type="text">

        <label>Telefono</label>
        <input placeholder="Inserisci il telefono" maxlength="64" v-model="contacts.telefono" type="text">

        <label>Indirizzo</label>
        <Address v-bind:id_address="'maphomeaddress'" ref="home"/>
        <div class="col-sm-12">
          <span class="text-warning">{{ error_maps }}</span>
        </div>

        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        
        <div class="col-sm-12" v-if="success" style="border: 1px solid green; padding: 20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span v-html="success ? 'Dati Aggiornati!' : ''"></span>
          </span>
        </div>

        
        <button :class="checkForm() ? 'button margin-top-15' : 'button margin-top-15 disabled'" :disabled="!checkForm()" @click="updateData()">Aggiorna Dati</button>

      </div>

    </div>
  </div>
  </div>
</template>

<script>
import DefaultService from '@/services/DefaultService'
import ProfileService from '@/services/ProfileService'
import Address from '@/components/Address'
import AddressService from '@/services/AddressService'
import Vue from 'vue'
import { FormSelectPlugin } from 'bootstrap-vue'
Vue.use(FormSelectPlugin)
export default {
  name: 'update-user-data',
  data () {
    return {
      loading: true,
      success: false,
      error: null,
      error_maps: null,
      // shop_name: '',
      // shop_type: null,
      // options_shop_type: [
      //   { value: null, text: 'Scegli una categoria' }
      // ],
      // pi: '',
      // cf: '',
      name: '',
      surname: '',
      contacts: {
        telefono: '',
        cellulare: ''
      }
    }
  },
  components: {
    Address
  },
  async mounted () {
    this.$watch(
        () => {
            return this.$refs['home'].data_parsed
        },
      (val) => {
        //console.log('Parsed: ', val)
        if(!val.n_civ) {
          this.error_maps = 'Il civico non è stato trovato. Verrai posizionato al centro della via!'
        } else if (!val.cap) {
          this.error_maps = 'Il cap non è stato trovato!'
        } else if (!val.citta) {
          this.error_maps = 'La citta non è stata trovata!'
        } else if (!val.country) {
          this.error_maps = 'La nazione non è stata trovata!'
        } else if (!val.localita) {
          this.error_maps = 'La localita non è stata trovata'
        } else if (!val.provincia) {
          this.error_maps = 'La provincia non è stata trovata!'
        } else if (!val.regione) {
          this.error_maps = 'La regione non è stata trovata!'
        } else if (!val.strada) {
          this.error_maps = 'La via non è stata trovata!'
        } else {
          this.error_maps = null
        }
      }
    )
    try {
      //const type = await DefaultService.getTypeDefault();
      // if (type) {
        this.name = this.$store.state.user.user.first_name
        this.surname = this.$store.state.user.user.last_name
        this.contacts = this.$store.state.user_meta.user_meta.contacts.value ? this.$store.state.user_meta.user_meta.contacts.value : {cellulare: '', telefono: ''}
        if (this.$store.state.user_address.address && this.$store.state.user_address.address.filter((el) => el.label === 'default')[0].geodata) {
          this.$refs['home'].addressInput = this.$store.state.user_address.address.filter((el) => el.label === 'default')[0].geodata.formatted_address
          this.$refs['home'].stop = true
        } else {
          this.$refs['home'].addressInput = ''
        }
      // }
    } catch(error) {
      //console.log('err: ', error)
      error.response ? this.error = error.response.data.message : this.error = error + '! Ricarica la pagina'
    } finally {
      this.loading = false
    }
  },
  methods: {
    checkForm() {
      return true
    },
    async updateData() {
      this.loading = true
      //console.log('autocmplete: ', this.$refs['home'].addressInput)
      try {
        let ris = await ProfileService.updateDatatProfile(
          this.$store.state.user.user.id,
          {
            first_name: this.name,
            last_name: this.surname
          }
        )
        if (ris) {
          ris.data.myworkshops = this.$store.state.user.user.myworkshops
          ris.data.token = this.$store.state.user.user.token
          this.$store.dispatch('user/setUser', ris.data)
          let metaRis = await ProfileService.saveMetaUser(this.$store.state.user.user.id, {contacts: this.contacts})
          //console.log('meta user: ', metaRis)
          this.$store.dispatch('user_meta/setUserMeta', metaRis.data)
          if (!this.$refs['home'].obj && !this.$refs['home'].stop && (this.$refs['home'].addressInput !== '' && this.$refs['home'].addressInput !== null ))
            throw 'Errore: specifica un indirizzo valido!'
          try {
            if (!this.$refs['home'].stop && (this.$refs['home'].addressInput !== '' && this.$refs['home'].addressInput !== null )) {
              const addressDefaultRis = await AddressService.saveUserAddress(this.$refs['home'].obj, this.$store.state.user_address.address.filter((el) => el.label === 'default')[0].id) // cambia id
              this.$store.dispatch('user_address/setDefaultAddress', addressDefaultRis.data)
            }
            this.error = null
            this.success = true
            setTimeout(() => {
              //location.reload()
              this.success = false
            }, 2000)
          } catch (error) {
            console.log(error.response.data.message)
            error.response ? this.error = error.response.data.message : this.error = error
            setTimeout(() => {
              this.error = null
            }, 3000)
          } finally {
            this.loading = false
          }
        }
      } catch (error) {
        console.log('ciao:',error);
        error.response ? this.error = error.response.data.message : this.error = error
        setTimeout(() => {
          this.error = null
        }, 3000)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.is-invalid {
    color: #f91942 !important;
    border-color: #f91942 !important;
  }
  .is-valid {
    /* color: green !important; */
    border-color: green !important;
    /* background: #e6e6e6 !important; */
  }
  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
  }
</style>

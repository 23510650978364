<script>

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]
import ClassificationCatList from './cat_list';
import ClassificationSubcatList from './subcat_list';

export default {

    name: 'classification',
    props: [],
    components: { ClassificationCatList, ClassificationSubcatList, },
    data() {
        return {
            cat_id: null,
        };
    },
    computed: {
        classification_loaded() { return this.$store.state.classification.obj === null ? false : true },
        selected_ids: {
            get() { return this.$store.state.classification.selected_ids },
            set( selected_ids ) { return this.$store.dispatch( 'classification/set_selected_ids', selected_ids ); },
        },
    },
    watch: {},
    mounted() {
        this.observer = new MutationObserver(mutations => {
          for (const m of mutations) {
            const newValue = m.target.getAttribute(m.attributeName);
            this.$nextTick(() => {
              this.on_class_change(newValue, m.oldValue);
            });
          }
        });
        this.observer.observe(this.$refs['small_dialog'], {
          attributes: true,
          attributeOldValue : true,
          attributeFilter: ['class'],
        });
    },
    methods: {
        open_dialog_classification() {
            document.getElementById('link-search').click();
        },
        remove_subcat( subcat_id ) {
            var selected_ids = JSON.parse( JSON.stringify( this.selected_ids ) );
            var index = selected_ids.map( x => x.id ).indexOf( subcat_id );
            selected_ids.splice( index, 1 );
            this.selected_ids = selected_ids;
            this.$emit( 'submit-search', 'page' )
        },
        on_class_change(classAttrValue) {
            const classList = classAttrValue.split(' ');
            if ( classList.includes('mfp-hide') ) {
                this.$emit( 'submit-search', 'page' )
            }
        },
        set_cat_id( obj ) {
            this.cat_id = obj.cat_id;
        },
        close_dialog() {
            var exit = document.getElementsByClassName("mfp-close");
            exit[0].click();
        },
        
    },
    created () {},

}

</script>

<template>

    <div>

        <div @click="open_dialog_classification" style="border: 1px solid #dbdbdb; padding: 6px; min-height: 51px; cursor: pointer;">
            <div v-if="selected_ids.length === 0" style="vertical-align: middle; color: grey; padding-left: 15px; padding-top: 6px;">Clicca per filtrare i negozi</div>
            <template v-if="selected_ids.length > 0">
                <div v-for="type in selected_ids" style="display: inline; cursor: default;">
                    <span style="font-weight: 600; font-size: 14px; background-color: red; padding: 3px; padding-left: 6px; padding-right: 6px; border-radius: 4px; margin-right: 3px; color: white">{{ type.label }} <i class="fa fa-close" style="cursor: pointer;" @click.stop="remove_subcat( type.id )"></i></span>
                </div>
            </template>
        </div>

        <a style="display: none;" href="#small-dialog" :class="'button sign-in sign-in_1 popup-with-zoom-anim'" id="link-search"><i class="sl sl-icon-login hidemobile"></i> Cerca</a>

        <!-- Search PopUp -->
        <div v-if="classification_loaded" id="small-dialog" ref="small_dialog" class="zoom-anim-dialog mfp-hide" style="max-width: 900px; height: 540px; padding: 10px">
            <div class="small-dialog-header header-classification">
                <h3>Imposta la categoria</h3>
            </div>
            <div class="small-dialog-content">
                <div class="col"> <!-- column -->
                    <div class="row"> <!-- row -->
                        <div class="col-md-4 hidden-sm hidden-xs"  > <!-- left menu -->
                            <classification-cat-list
                                :cat_id="cat_id"
                                @set-category="set_cat_id"
                                />
                        </div>
                        <div class="col-md-8 col-xs-12 col-sm-12"> <!-- right body menu -->
                            <classification-subcat-list
                                :cat_id="cat_id"
                                />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6"> </div>
                        <div class="col-xs-5 text-right"> <!-- column -->
                            <input type="submit" @click="close_dialog" class="button border fw margin-top-10 button-search" style="padding: 3px; width: 80px;" name="search" value="Cerca" />
                        </div>
                        <div class="col-xs-1"> </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- -->

    </div>

</template>


<style>
</style>


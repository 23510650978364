<template>
  <div id="shops-list">
    <HeaderCustom />
    <div class="text-center" style="height: 500px;" v-if="loading">
        <div class="loader"></div>
    </div>
    <!-- Content ================================================== -->
    <div v-show="!loading" :class="getting_result ? 'fs-container opacity' : 'fs-container'">
      <!-- MAPS -->
      <div class="fs-inner-container content">
        <div class="fs-content">

          <!-- Search -->
          <section class="search">

            <div class="row">
              <div class="col-md-12">

                  <!-- Row With Forms -->
                  <div class="row with-forms">
                    <div id="listing-nav" class="listing-nav-container">
                      <ul class="listing-nav">
                        <li><a href="" :class="toggle_filter === 1 ? 'active' : 'not-active'" @click="switchFilter('geo')">Ricerca geografica</a></li>
                        <li><a href="" :class="toggle_filter === 0 ? 'active' : 'not-active'" @click="switchFilter('looking-for')">Ricerca per negozio</a></li>
                      </ul>
                    </div>
                    <!-- Main Search Input -->
                    <div v-show="toggle_filter === 0" id="shop-search" class="col-fs-12">
                      <div class="input-with-icon">
                        <i class="sl sl-icon-magnifier"></i>
                        <LookingFor ref="looking-for"/>
                      </div>
                      <span class="view_info_tab" @click="switchFilter('geo')">Passa alla ricerca per categoria e area geografica</span>
                    </div>
                    <!-- Filters -->
                    <div v-show="toggle_filter === 1" id="geo-search" class="col-fs-12" style="padding: 0;">

                      <!-- Classification Input -->
                      <div class="col-fs-4" style="margin-bottom: 5px;">
                        <div class="col-xs-12 col-md-12" style="padding: 0px;" id="select-container" z-index="9">
                            <ClassificationSearch @submit-search="changePage" />
                        </div>

                      </div>
                      <div class="col-fs-4" style="margin-bottom: 5px;">
                        <div class="input-with-icon location">
                          <div id="autocomplete-container">
                            <AddressNewVersion
                                v-bind:id_address = "'maplocationaddress'"
                                ref               = "location"
                                id                = "my_id"
                                @no-text          = "autocomplete_reset"
                                />
                          </div>
                        </div>
                      </div>

                      <!-- Panel Dropdown -->
                      <div class="panel-dropdown col-fs-4">
                        <a href="#">Raggio di ricerca</a>
                        <span><i> ({{ info_radius }}km)</i></span>
                        <div class="panel-dropdown-content">
                          <!-- <input class="distance-radius" type="range" min="1" max="20" step="1" v-model="radius" data-title="Raggio di ricerca dei negozi dalla tua posizione"> -->
                          <div class="range-output">{{info_radius}}</div>
                          <i class="data-radius-title">Raggio di ricerca dei negozi dalla tua posizione</i>
                          <!--<input type="range" @change="changePage('range')" class="range" min="1" max="100" step="1" v-model="radius">-->
                          
                          <label for="500m" style="margin: 2px 1px 1px 2px;display:inline">
                            <input type="radio" @change="changePage('range')" id="500m" value="0.5" v-model="radius"> 0.5 km
                          </label>
                          <label for="500m" style="margin: 2px 1px 1px 2px;display:inline">
                            <input type="radio"  @change="changePage('range')"  id="1km" value="1" v-model="radius">  1 km
                          </label>
                          <label for="2km" style="margin: 2px 1px 1px 2px;display:inline">
                            <input type="radio"  @change="changePage('range')"  id="2km" value="2" v-model="radius">  2 km
                          </label><br>
                          <label for="5km" style="margin: 2px 1px 1px 2px;display:inline">
                            <input type="radio"  @change="changePage('range')" id="5km" value="5" v-model="radius">  5 km
                          </label>
                          <label for="10km" style="margin: 2px 1px 1px 2px;display:inline">
                            <input type="radio" @change="changePage('range')" id="10km" value="10" v-model="radius" checked>  10 km
                          </label>
                          <div class="panel-buttons">
                            <button class="panel-cancel">Chiudi</button>
                            <!-- <button @click="changePage()" class="panel-apply">Applica</button> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-fs-12" style="margin-top:20px;">
                        <span class="view_info_tab" @click="switchFilter('looking-for')">Passa alla ricerca per nome negozio</span>
                      </div>
                      <!-- Panel Dropdown / End -->
                      
                    </div>
                    <!-- Filters / End -->
      
                  </div>
                  <!-- Row With Forms / End -->

              </div>
            </div>

          </section>
          <!-- Search / End -->


        <section class="listings-container margin-top-30">
          <!-- Sorting / Layout Switcher -->
          <div class="row fs-switcher">

            <div class="col-md-6">
              <!-- Showing Results -->
              <p class="showing-results">{{ shop_list_length }} Risultat{{ shop_list_length === 1 ? 'o' : 'i' }} Trovat{{ shop_list_length === 1 ? 'o' : 'i' }} </p>
            </div>

          </div>
          <List ref="list" v-show="!loading" :loading="loading"/>

          <!-- Pagination Container -->
          <div class="row fs-listings">
            <div class="col-md-12">

              <!-- Pagination -->
              <div class="clearfix"></div>
              <div class="row">
                <div class="col-md-12">
                  <!-- Pagination -->
                  <div class="pagination-container margin-top-15 margin-bottom-40" v-if="shop_list_length > 0">
                    <nav class="pagination">
                      <ul>
                        <li v-if="getter_page != 1" @click="pageBack()">
                          <i class="im im-icon-Arrow-Left"></i>
                        </li>
                        <li>
                          <router-link :to="'/shops-list?page=1'" :class="getter_page == 1 ? 'current-page' : ''">1</router-link>
                        </li>
                        <li v-if="get_num_page() > 1 && (parseInt(getter_page) === 1 || parseInt(getter_page) === 2)">
                          <router-link :to="'/shops-list?page=2'" :class="getter_page == 2 ? 'current-page' : ''">2</router-link>
                        </li>
                        <li v-if="get_num_page() === 3">
                          <router-link :to="'/shops-list?page=3'" :class="getter_page == 3 ? 'current-page' : ''">3</router-link>
                        </li>
                        <li v-if="get_num_page() > 3">
                          ...
                        </li>
                        <li v-if="getter_page != 1 && getter_page != 2 && getter_page != get_num_page() && getter_page != get_num_page() - 1 && get_num_page() >= getter_page">
                          <router-link :to="'/shops-list?page='+getter_page" class="current-page">{{ getter_page }}</router-link>
                        </li>
                        <li v-if="getter_page != 1 && getter_page != 2 && getter_page != get_num_page() && getter_page != get_num_page() - 1 && get_num_page() >= getter_page">
                          ...
                        </li>
                        <li v-if="get_num_page() > 3 && (parseInt(getter_page) === get_num_page() || parseInt(getter_page) === get_num_page()-1)">
                          <router-link :to="'/shops-list?page='+parseInt(get_num_page()-1)" :class="getter_page == get_num_page()-1 ? 'current-page' : ''">{{ get_num_page()-1 }}</router-link>
                        </li>
                        <li v-if="get_num_page() > 3">
                          <router-link :to="'/shops-list?page='+parseInt(get_num_page())" :class="getter_page == get_num_page() ? 'current-page' : ''">{{ get_num_page() }}</router-link>
                        </li>
                        <li v-if="getter_page != get_num_page()" @click="pageForward()">
                          <i class="im im-icon-Arrow-Right"></i>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <!-- Pagination / End -->
              
              <!-- Copyrights -->
             <div class="copyrights margin-top-0" style="font-size:12px;">
            <div class="col-xs-12 text-center">
              C.f.  e P.Iva 04498740275
            </div>
            <div class="col-xs-12 text-center">
              <a class="privacy_policy_footer iubenda-black iubenda-embed" href="https://www.iubenda.com/privacy-policy/82105399" title="Privacy Policy ">Privacy e Policy</a>
            </div>
            <div class="col-xs-12 text-center">
              <router-link class="rl" to="/all-shops">Tutti i Negozi</router-link>
            </div>
            <div class="col-xs-12 text-center">
              <a style="color: grey" href="mailto:assistenza@vetrineonline.com">assistenza@vetrineonline.com</a></h4>
            </div>
          </div>

            </div>
          </div>
          <!-- Pagination Container / End -->
        </section>

        </div>
      </div>
      <div :class="$store.state.user.isUserLoggedIn ? 'fs-inner-container map-fixed map-fixed_logged' : 'fs-inner-container map-fixed map-fixed_notlogged'">

        <!-- Map -->
        <div id="map-container" style="min-height:500px;">
            <GoogleMaps ref="maps" v-show="!loading" class="custom_map"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import HeaderCustom from '@/components/Header'
import HeaderNew from '@/components/HeaderNew'
import List from '@/components/ListingPage/List'
import GoogleMaps from '@/components/ListingPage/GoogleMaps'
import LookingFor from '@/components/ListingPage/LookingFor'
import _ from 'underscore'
import MapTest from '@/components/ListingPage/MapTest'
import FooterCustom from '@/components/Footer'
import ListingService from '@/services/ListingService'
import DefaultService from '@/services/DefaultService'
import { FormSelectPlugin } from 'bootstrap-vue'
import { BFormTags } from 'bootstrap-vue'
import { FormTagPlugin } from 'bootstrap-vue'
import AddressNewVersion from '@/components/AddressNewVersion'
import config from '@/config'
import geoip from '@/utils/geoip'
import Vue from 'vue'
Vue.use(FormSelectPlugin)
import { TabsPlugin } from 'bootstrap-vue'
Vue.use(TabsPlugin)
import ClassificationSearch from '@/components/classification/classification_search';

export default {
  name: 'shops-list',
  data () {
    return {
      loading               : true,
      getting_result        : false,
      shop_list_length      : null,
      tot_shops             : null,
      location              : '',
      latitude              : null,
      longitude             : null,
      _latitude             : null,
      _longitude            : null,
      page                  : null,
      radius                : 10,
      shop_type             : [],
      options_shop_type     : [ { value: null, text: 'Tutte le categorie' } ],
      search                : '',
      toggle_filter         : 1,
      config                : null,
      filter_classification : { sectors: [], subcategories: [] },
    }
  },
  components: {
    HeaderCustom,
    HeaderNew,
    FooterCustom,
    List,
    GoogleMaps,
    LookingFor,
    MapTest,
    AddressNewVersion,
    ClassificationSearch,
    BFormTags,
  },
  async mounted() {
    await this.$store.dispatch('classification/load_data');
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    } else {
      this.page = 1
    }
    this.loading = true
    
    this.$watch(
      () => {
        return this.$refs['location'].address
      },
      (val) => {
        this.latitude = val.geodata.lat()
        this.longitude = val.geodata.lng()
        this.changePage('address')
      }
    )

    this.$watch(
      () => {
          return this.$refs['looking-for'].search
      },
      _.debounce((val) => {
        this.search = val
        if (val.length > 2)
          this.changePage('looking-for')
      }, 500
    ))
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        document.body.appendChild(custom)
      })
    let all_types = await DefaultService.getTypeDefault()
    this.options_shop_type = [{ value: null, text: 'Tutte le categorie' }].concat(all_types.data.map(el => {
      let obj = {}
      obj.value = el.id
      obj.text = el.label
      obj.disabled = false
      return obj
    }))
    // Getting Coordinates
    if (!this.$store.state.user.isUserLoggedIn || !this.$store.state.user_address.address.filter(el => el.label === 'default')[0].geodata || this.$store.state.user_address.address.filter(el => el.label === 'default')[0].length === 0) {
      try {
        const coordinates = await this.$getLocation({
          enableHighAccuracy: true,
          timeout: 8000, //defaults to Infinity
          maximumAge: 10})
        this.latitude = coordinates.lat
        this._latitude = coordinates.lat
        this.longitude = coordinates.lng
        this._longitude = coordinates.lng
      } catch (err) {
        console.log('error in getting coordinates: ', err)
        this.latitude = 45.4398
        this._latitude = 45.4398
        this.longitude = 12.3319
        this._longitude = 12.3319
      }
    } else {
      this.location = this.$store.state.user_address.address.filter(el => el.label === 'default')[0].geodata.formatted_address
      this.latitude = this.$store.state.user_address.address.filter(el => el.label === 'default')[0].geodata.geometry.location.lat
      this.longitude = this.$store.state.user_address.address.filter(el => el.label === 'default')[0].geodata.geometry.location.lng
      this.$refs['location'].addressInput = this.$store.state.user_address.address.filter((el) => el.label === 'default')[0].geodata.formatted_address
      this.$refs['location'].stop = true
    }

//  const list_shops = await ListingService.getListShop(
//    this.latitude,
//    this.longitude,
//    this.page,
//    this.radius*1000
//  );

    const list_shops = await ListingService.getListShopClassification(
      this.latitude,
      this.longitude,
      this.page,
      this.radius*1000,
      this.selected_ids.map( x => x.id ),
      null
    );
    console.log(list_shops.data.data)
    
    list_shops.data.data = list_shops.data.data.filter(el => {
      return el.addresses.map(el2 => el2.label).includes('negozio')
    });
    this.shop_list_length = list_shops.data.tot
    this.tot_shops = list_shops.data.tot
    this.$refs['list'].shop_list = list_shops.data.data
    let shopsToMap = []
    list_shops.data.data.forEach(element => {
      let obj = {}
      obj.position = element.addresses.filter(el2 => el2.label === 'negozio')[0].geodata.geometry.location
      obj.name = element.label
      obj.image = element.gallery.length > 0 ? this.config.backend_url+element.gallery[0].value.path : require('@/assets/images/noimage.png')
      obj.type = element.mytypes[0].label
      obj.id = element.id
      shopsToMap.push(obj)
    })
    this.$refs['maps'].shops_list = shopsToMap
    this.$refs['maps'].center = {lat:this.latitude, lng:this.longitude}

    list_shops.data.data.forEach(element => {
      this.options_shop_type.filter(el => el.value === element.mytypes[0].id)[0].disabled = false
    })
    
    this.orderTypes()
    this.loading = false
  },
  computed: {
    selected_ids: {
        get() { return this.$store.state.classification.selected_ids },
        set( selected_ids ) { return this.$store.dispatch( 'classification/set_selected_ids', selected_ids ); },
    },
    info_radius: function () {
      return this.radius
    },
    getter_page: function () {
      return this.page
    }
  },
  created: function() {
    this.$eventHub.$on('getting_result', (value) => {
      this.getting_result = value
    })
    this.config = config
  },
  beforeDestroy () {
    this.$eventHub.$off('getting_result')
  },
  methods: {
    autocomplete_reset() {
        this.latitude  = this._latitude;
        this.longitude = this._longitude;
        this.changePage('page')
    },
    get_num_page() {
      return Math.ceil(this.tot_shops/this.config.pagination.rows_per_page)
    },
    orderTypes() {
      this.options_shop_type = this.options_shop_type.sort((x,y) => {
        return (x.disabled === y.disabled) ? 0 : x.disabled ? 1 : -1
      })
      this.options_shop_type = this.options_shop_type.filter(el => el.value)
      this.options_shop_type = [{ value: null, text: 'Tutte le categorie' }].concat(this.options_shop_type)
    },
    async changePage(isPageFilter) {
      if (this.$route.query.page !== '1' && isPageFilter !== 'page') {
        this.$router.push({
          path: '/shops-list?page=1'
        })
      }
      try {
        this.getting_result = true
        var list_shops
        if (isPageFilter === 'looking-for' || (isPageFilter === 'page' && this.toggle_filter === 0 && this.search !== '')) {
          list_shops = await ListingService.getListShop(
            null,
            null,
            this.page,
            null,
            null,
            this.search
          )
        } else {
          list_shops = await ListingService.getListShopClassification(
            this.latitude,
            this.longitude,
            this.page,
            this.radius*1000,
            this.selected_ids.map( x => x.id ),
            null
          )
        }
        this.options_shop_type = this.options_shop_type.map(el => {
          el.disabled = false
          return el
        })
        list_shops.data.data = list_shops.data.data.filter(el => {
          return el.addresses.map(el2 => el2.label).includes('negozio')
        })
        this.shop_list_length = list_shops.data.tot
        this.tot_shops = list_shops.data.tot
        this.$refs['list'].shop_list = list_shops.data.data
        let shopsToMap = []
        list_shops.data.data.forEach(element => {
          let obj = {}
          obj.position = element.addresses.filter(el2 => el2.label === 'negozio')[0].geodata ? element.addresses.filter(el2 => el2.label === 'negozio')[0].geodata.geometry.location : null
          obj.name = element.label
          // obj.image = element.gallery.length > 0 ? element.gallery[0].path : require('@/assets/images/noimage.png')
          obj.image = element.gallery.length > 0 ? this.config.backend_url+element.gallery[0].value.path : require('@/assets/images/noimage.png')
          obj.type = element.mytypes[0].label
          obj.id = element.id
          shopsToMap.push(obj)
        })
        this.$refs['maps'].shops_list = shopsToMap
        if (isPageFilter === 'looking-for' || (isPageFilter === 'page' && this.toggle_filter === 0 && this.search !== '')) {
          if (list_shops.data.center)
            this.$refs['maps'].center = {lat: list_shops.data.center.latitude, lng: list_shops.data.center.longitude}
          else
            this.$refs['maps'].center = {lat:this.latitude, lng:this.longitude}
          if (list_shops.data.range !== 0)
            this.$refs['maps'].radius = list_shops.data.range/2
          else
            this.$refs['maps'].radius = this.radius*1000
          this.$refs['maps'].isCenter = false
        } else {
          this.$refs['maps'].radius = this.radius*1000
          this.$refs['maps'].center = {lat:this.latitude, lng:this.longitude}
          this.$refs['maps'].isCenter = true
        }
        list_shops.data.data.forEach(element => {
          this.options_shop_type.filter(el => el.value === element.mytypes[0].id)[0].disabled = false
        })
        if (!this.options_shop_type.map(el => !el.disabled ? el.value : null).includes(this.shop_type))
          this.shop_type = null
        this.orderTypes()
        setTimeout(() => {
          this.getting_result= false
        }, 500)
      } catch(err) {
        console.log('error in chanegPage: ', err)
      }
    },
    pageForward() {
      this.$router.push({
        path: '/shops-list?page='+(parseInt(this.getter_page)+1)
      })
    },
    pageBack() {
      this.$router.push({
        path: '/shops-list?page='+(parseInt(this.getter_page)-1)
      })
    },
    switchFilter(filter) {
      if (filter !== 'looking-for') {
        this.toggle_filter = 1
        this.changePage(filter)
      } 
      else {
        this.toggle_filter = 0
        if (this.search.length > 2)
          this.changePage(filter)
      }
    }
  },
  watch: {
    $route(to, from) {
      this.page = to.query.page
      this.changePage('page')
    },
    shop_type( newvalue, oldvalue ) {
    }
  }
}
</script>

<style>
  .mfp-close { visibility: hidden; }
  .button-search {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; 
  }
  .header-classification {
    width: calc(100% + 20px);
    left: -10px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  /* .fs-container {
    margin-top: 75px;
  } */
  .sign-in_1 {
    color: #333 !important;
  }
  .to_dashboard_2 {
    color: rgb(249, 25, 66) !important;
  }
  .to_dashboard_2:hover {
    color: white !important;
  }
  .fs-inner-container.content {
    width: 55%;
    background-color: #f7f7f7;
    z-index: 995;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.12);
    position: relative;
    padding-top: 80px !important;
  }
  .fs-inner-container.map-fixed {
    height: 100vh;
    position: fixed;
    top: 0;
    padding-top: 80px !important;
  }
  .disabled {
    background:#e6e6e6;
    cursor: not-allowed;
  }
  .range {
    -webkit-appearance: none;
    width: 203.03px;
    margin: 13.8px 0;
  }
  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 9.1px 0;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1.8px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.03), 0px 0px 1px rgba(13, 13, 13, 0.03);
    background: #f91942;
    border-radius: 1.1px;
    border: 0px solid rgba(0, 0, 0, 0);
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1.2px solid #f91942;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9.1px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #fa3256;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 1.8px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.03), 0px 0px 1px rgba(13, 13, 13, 0.03);
    background: #f91942;
    border-radius: 1.1px;
    border: 0px solid rgba(0, 0, 0, 0);
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1.2px solid #f91942;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 1.8px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #f20631;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 2.2px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.03), 0px 0px 1px rgba(13, 13, 13, 0.03);
  }
  input[type=range]::-ms-fill-upper {
    background: #f91942;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 2.2px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.03), 0px 0px 1px rgba(13, 13, 13, 0.03);
  }
  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1.2px solid #f91942;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    height: 1.8px;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #f91942;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #fa3256;
  }
  .custom_select {
    /* height: 35px; */
  }
  @media only screen and (max-width: 991px) {
    .fs-inner-container.map-fixed {
      height: 500px !important;
      position: relative !important;
      top: 0 !important;
      padding-top: 0!important;
      width: 100% !important;
    }
    .fs-inner-container.content {
      width: 100% !important;
      padding-top: 0!important;
    }
    .fs-inner-container.map-fixed_logged {
      margin-top:135px;
    }
    .fs-inner-container.map-fixed_notlogged {
      margin-top:210px;
    }
  }
  .panel-dropdown .panel-dropdown-content {
    width: 300px !important;
  }
  .im-icon-Arrow-Left {
    margin-right:15px;
    cursor: pointer;
  }
  .im-icon-Arrow-Left:hover {
    color:rgb(249, 25, 66);
    transition: .3s;
  }
  .im-icon-Arrow-Right {
    margin-left:15px;
    cursor: pointer;
  }
  .im-icon-Arrow-Right:hover {
    color:rgb(249, 25, 66);
    transition: .3s;
  }
  .not-active {
    border: none;
    color: #666 !important;
  }
  .view_info_tab {
    color: rgb(249, 25, 66);
    cursor: pointer;
    margin-top: 15px;
  }
</style>

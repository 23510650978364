require('dotenv').config();
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import '@/assets/css/nprogress.css'
import LoadScript from 'vue-plugin-load-script'
import * as VueGoogleMaps from 'vue2-google-maps'
// import geoipLite from 'geoip-lite'
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
// window.OneSignal = window.OneSignal || [];
// window.OneSignal.push(() => {
//   window.OneSignal.init({
//     appId: "20d3b7b0-85e1-49d7-8f9a-1e74f587be12",
//     allowLocalhostAsSecureOrigin: true,
//   });
//   windows.OneSignal.setExternalUserId(3);
//   window.OneSignal.getUserId( function(userId) {
//     console.log('player_id of the subscribed user is : ' + userId);
//     // Make a POST call to your server with the user ID        
//   });
// });

Vue.use(LoadScript)
// Vue.use(geoipLite)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAozc3FZXiTBQ3LJ7aCCilsdTldh_J8E5k',
    libraries: 'places'
  },
})
Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue()
// Vue.use(BootstrapVue)
// Vue.use(BootstrapVueIcons)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

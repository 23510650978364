<template>
  <div>
    <!-- Header Container
    ================================================== -->
    <header id="header-container" class="fixed fullwidth dashboard">

      <!-- Header -->
      <div id="header" class="not-sticky">
        <div class="container">
          
          <!-- Left Side Content -->
          <div class="left-side left-side-dash">
            
            <!-- Logo -->
            <div id="logo">
              <!-- <router-link to="/" ><h4 style="color:#f91942;">Vetrine OnLine</h4></router-link> -->
              <router-link class="dashboard-logo" to="/" ><img  src="@/assets/images/Logo Vetrineonline.com.png" :data-sticky-logo="require('@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png')" alt=""></router-link>
              <router-link to="/" ><img src="@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png" :data-sticky-logo="require('@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png')" alt=""></router-link>
              <!-- <router-link to="/" style="margin-top: 25px" class="dashboard-logo"><h4 style="color:#f91942;">Vetrine OnLine</h4></router-link> -->
              <!-- <router-link to="/" ><h4 style="color:#f91942;">Vetrine OnLine</h4></router-link> -->
            </div>

            <!-- Mobile Navigation -->
            <!-- <div class="mmenu-trigger">
              <button class="hamburger hamburge-collapse" type="button">
                <span class="hamburger-box">
                  <span class="hamburger-inner"></span>
                </span>
              </button>
            </div> -->

            <!-- Main Navigation -->
            <nav id="navigation" class="style-1">
              <ul id="responsive">

                <li><router-link class="not_current" to="/">Home</router-link></li>

                <li><router-link to="/dashboard-company" class="current">Dashboard</router-link>
                  <ul>
                    <!-- <li><a href="#">Ordini</a></li> -->
                    <!-- <li><a href="#">Messaggi</a></li> -->
                    <li><router-link to="/dashboard-company-my-profile">Il Mio Profilo</router-link></li>
                    <li v-if="this.$store.state.user.user.role_id === 5"><router-link to="/dashboard-company-my-shop?shop=0">Il Mio Negozio</router-link></li>
                    <li><router-link to="/dashboard-my-shops">I Miei Preferiti</router-link></li>
                  </ul>
                </li>
                <!-- <li><a class="not_current" href="/shops-list">Cerca Negozi</a></li> -->
                <li v-if="this.$store.state.user.user.role_id === 5 && this.$store.state.shop.shop"><router-link class="not_current" :to="'/shop?id='+this.$store.state.shop.shop.id">Preview mio Negozio</router-link></li>                  
              </ul>
            </nav>
            <div class="clearfix"></div>
            <!-- Main Navigation / End -->
            
          </div>
          <!-- Left Side Content / End -->

          <!-- Right Side Content / End -->
          <div class="right-side right-side-dash">
            <div class="header-widget">
              <!-- User Menu -->
              <div class="user-menu" v-if="this.$store.state.user.isUserLoggedIn">
                <div class="user-name-dashboard"><i class="sl sl-icon-user" style="margin-right:10px;"></i>Ciao, {{ this.$store.state.user.user.first_name }}!</div>
                <ul>
                  <li v-if="this.$store.state.user.user.role_id === 5"><router-link to="/dashboard-company-my-shop"><i class="sl sl-icon-settings"></i> Dashboard</router-link></li>
                  <!-- <li><a href="/shops-list"><i class="im im-icon-Bulleted-List"></i> Cerca Negozi</a></li> -->
                  <li v-if="this.$store.state.user.user.role_id === 5 && this.$store.state.shop.shop"><router-link :to="'/shop?id='+this.$store.state.shop.shop.id"><i class="im  im-icon-Shop"></i> Mio Negozio</router-link></li>
                  <li><a @click="logout()"><i class="sl sl-icon-power"></i> Logout</a></li>
                </ul>
              </div>
            </div>
            <!-- Header Widget / End -->
          </div>
          <!-- Right Side Content / End -->

        </div>
      </div>
      <!-- Header / End -->

    </header>
    <div class="clearfix"></div>
    <!-- Header Container / End -->
  </div>
</template>

<script>
export default {
  name: 'header-dashboard',
  mounted() {  
  },
  methods: {
    logout () {
      console.log('logout')
      var role_id = this.$store.state.user.user.role_id
      this.$store.dispatch('user/setToken', null)
      this.$store.dispatch('user/setUser', null)
      this.$store.dispatch('shop/setShop', null)
      this.$store.dispatch('shop_meta/setShopMeta', null)
      this.$store.dispatch('shop_address/setAddress', null)
      this.$store.dispatch('user_address/setAddress', null)
      this.$store.dispatch('user_meta/setUserMeta', null)
      this.$store.dispatch('shop_images/setLogo', null)
      this.$store.dispatch('shop_images/setGallery', null)
      this.$store.dispatch('my_shops/setMyShops', [])
      if(role_id === 5) {
        this.$store.dispatch('presence_ws/setStatus', '')
      }
      // window.location.href = '/'

      location.reload();
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style scoped>
  #header {
    position: relative;
    z-index: 999;
    padding: 18px 0 8px;
    box-shadow: 0 0 12px 0 rgba(0,0,0,.12) !important;
    font-size: 16px;
  }
  #navigation.style-1 ul li a.not_current {
    color: #444 !important;
    cursor: pointer;
  }
  #navigation.style-1 ul li a.not_current:hover {
    background: rgba(249,25,66,.06) !important;
    color: #f91942 !important;
  }
  .current {
    background: rgba(249,25,66,.06) !important;
    color: #f91942 !important;
  }
  .user-name-dashboard, .user-name-dashboard i, .transparent-header #header:not(.cloned) .user-menu .user-name-dashboard:after {
    color: #444 !important;
  }
  #header-container.fixed {
    height: 80px;
  }
</style>

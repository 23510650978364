<template>
  <div>
    <div class="text-center" v-if="loading" style="position:absolute; top: 15%; left: 32%; height: 500px;">
      <div class="loader"></div>
    </div>
    <div :class="loading ? 'dashboard-list-box margin-top-0 fs-container opacity' : 'dashboard-list-box margin-top-0'">
    <h4 class="gray">Informazioni Generali</h4>
    <div class="dashboard-list-box-static">
      
      <!-- Details -->
      <div class="my-profile">

        <label>Nome Negozio</label>
        <input style="color:#333;" maxlength="64" placeholder="Inserisci il nome del negozio.." v-model="shop_name" :disabled="success" type="text" :class="be_error_shop_name ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text' ">
        <span class="error" v-if="be_error_shop_name">{{ be_error_shop_name }}</span>

        <!-- <label for="shop_name">Categoria Negozio:
          <b-form-select style="color: #333;" v-model="shop_type" :options="options_shop_type" :class="success ? 'input-text is-valid': ''"></b-form-select>
        </label> -->

        <label for="shop_name">Settori:</label>
        <SectorEdit ref="classification_sector" v-if="classification" :sectors="classification.s" />

        <label for="shop_name">Categorie:</label>
        <ClassificationEdit ref="classification_category" v-if="classification" :classification="{ c: classification.c, u: classification.u }" @submit-save="" />

        <label>Ragione Sociale</label>
        <input style="color:#333;" maxlength="1024" placeholder="Inserisci la ragione sociale.." v-model="ragione_sociale" :disabled="success" type="text" :class="be_error_rs ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text' ">
        <span class="error" v-if="be_error_rs">{{ be_error_rs }}</span>

        <label>Codice Ateco</label>
        <!-- <input style="color:#333;" placeholder="Inserisci il codie ateco.." v-model="codice_ateco" :disabled="success" type="text" :class="error ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text' "> -->
        <CodiceAteco v-if="!loading" maxlength="64" ref="ateco" :extsearch="codice_ateco"/>

        <label>Partita Iva</label>
        <input style="color:#333;" maxlength="11" placeholder="Inserisci la partita iva.." v-model="pi" :disabled="success" type="text" :class="be_error_pi ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text' ">
        <span class="error" v-if="be_error_pi">{{ be_error_pi }}</span>

        <label>Codice Fiscale</label>
        <input style="color:#333;" maxlength="16" placeholder="Inserisci il codie fiscale.." v-model="cf" :disabled="success" type="text" :class="be_error_cf ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text' ">
        <span class="error" v-if="be_error_cf">{{ be_error_cf }}</span>

        <label for="aiutoStato"><input type="checkbox" id="aiutoStato" v-model="aiutoStato">  Aiuti di Stato</label>

        <label>Descrizione Attvità</label>
        <textarea style="color:#333;" maxlength="1024" name="notes" v-model="description" id="notes" cols="30" rows="10" :disabled="success" type="text" :class="false ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text' " placeholder="Inserisci una descrizione della tua attività"></textarea>
        
        <div class="col-sm-12" v-if="error">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        
        <div class="col-sm-12" v-if="success">
          <span style="color:green;" class="col-sm-12 text-center">
            <span v-html="success ? 'Dati Aggiornati!' : ''"></span>
          </span>
        </div>
        
        <button class="button margin-top-15" @click="save()">Salva</button>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ShopService from '@/services/ShopService'
import DefaultService from '@/services/DefaultService'
import CodiceAteco from '@/components/Negoziante/ateco_autocomplete'
import Vue from 'vue'
import { FormSelectPlugin } from 'bootstrap-vue'
import ClassificationEdit from '@/components/classification/classification_edit';
import SectorEdit from '@/components/classification/sector_edit';
Vue.use(FormSelectPlugin)
export default {
  name: 'shop-data',
  components: {
    CodiceAteco,
    ClassificationEdit,
    SectorEdit,
  },
  data () {
    return {
      loading            : true,
      shop_name          : '',
      pi                 : '',
      cf                 : '',
      codice_ateco       : null,
      ateco_key          : 0,
      ragione_sociale    : null,
      description        : null,
      shop_type          : null,
      options_shop_type  : [],
      classification     : null,
      success            : false,
      be_error_shop_name : null,
      be_error_pi        : null,
      be_error_cf        : null,
      be_error_rs        : null,
      be_error_classification: null,
      error              : null,
      sectors            : [],
      key_sector         : 0,
      aiutoStato         : false,
    }
  },
  computed: {
      all_sectors() { return this.$store.getters['classification/sectors']; },
  },
  async mounted() {
    console.log("provo a stampare settori e categorie")
    console.log(this.classification)
      // const shop_meta = await ShopService.getShopMeta(this.$store.state.user.user.myworkshops[0].id)    
      // this.$store.dispatch('shop/setShop', shop_meta.data)
     try {
        const type = await DefaultService.getTypeDefault();
        console.log(type)
        if (type) {
          type.data.forEach(element => {
            this.options_shop_type.push({value: element.id, text: element.label})
          })
          this.shop_name = this.$store.state.shop.shop.label
          this.classification = this.$store.state.shop.shop.classification || {};
          this.all_sectors.map( x => {
              if ( this.classification.s.includes( x.id ) ) { this.sectors.push( x.id ); }
          });
          //this.key_sector++;
          this.pi = this.$store.state.shop.shop.partita_iva
          this.cf = this.$store.state.shop.shop.codice_fiscale
          this.codice_ateco = this.$store.state.shop.shop.codice_ateco
          this.ragione_sociale = this.$store.state.shop.shop.ragione_sociale
          this.description = this.$store.state.shop.shop.descrizione
          this.shop_type = this.$store.state.shop.shop.mytypes[0].id
          this.aiutoStato = this.$store.state.shop.shop.rna
        }
      } catch(error) {
        console.log( error );
        console.log('err: ', error.response.data.message)
        error.response ? this.error = error.response.data.message : this.error = error + '! Ricarica la pagina'
      } finally {
        this.loading = false
      }
  },
  methods: {
    edit_sector() {
        console.log( 'ciao' );
    },
    checkAiutiStato() {
      if(aiutoStato){
        if(this.ragione_sociale == null || this.ragione_sociale == ''){
          console.log("err rs "+this.be_error_rs)
          this.be_error_rs = 'dati non validi'
          console.log("err rs "+this.be_error_rs)
          return false
        } else{
          return true
        }
      } else {
        console.log("no check")
      }
    },
    checkNumCat(){
      if(new_classification.c > 3){
        this.be_error_classification = "Il massimo numero di categorie consentito è 3"
        return false
      }
      return true
    },
    async save() {
      this.checkAiutiStato()
      //this.checkNumCat()
      this.loading = true
      this.error = null
      this.be_error_shop_name = null
      this.be_error_pi = null
      this.be_error_cf = null
      this.be_error_rs = null
      this.be_error_classification = null
      var new_classification = {
          s: this.$refs['classification_sector'].new_sectors,
          c: this.$refs['classification_category'].new_classification.c,
          u: this.$refs['classification_category'].new_classification.u,
      };
      console.log( new_classification );
      
      try {
        let obj = {}
        obj.label = this.shop_name
        obj.partita_iva = this.pi
        obj.codice_fiscale = this.cf
        obj.descrizione = this.description
        obj.ragione_sociale = this.ragione_sociale
        obj.codice_ateco = this.$refs['ateco'].selected
        obj.type = this.shop_type
        obj.classification = new_classification;
        obj.rna = this.aiutoStato
        let ris = await ShopService.saveInfoShop(obj,this.$store.state.user.user.myworkshops[this.$route.query.shop].id)
        if (ris) {
          this.$store.dispatch('shop/setDataShop', ris.data)
          this.codice_ateco = this.$store.state.shop.shop.codice_ateco
          this.ateco_key+=1
          this.error = null
          this.success = true
          //console.log(ris)
          setTimeout(() => {
            this.success = false
            //location.reload()
          }, 2000)
        }
      } catch (error) {
        this.success = false
        console.log('err: ', error.response.data.message)
        error.response ? this.error = error.response.data.message : this.error = error
        if (error.response) {
          if (error.response.data.message === 'dati non validi') {
            Object.keys(error.response.data.errors).forEach(el2 => {
              console.log("el2 "+el2)
              if (el2 === 'partita_iva') {
                // this.error = error.response.data.errors[el2].message
                this.be_error_pi = error.response.data.errors[el2].message
              }
              if (el2 === 'codice_fiscale') {
                // this.error = error.response.data.errors[el2].message
                this.be_error_cf = error.response.data.errors[el2].message
              }
              
              if (el2 === 'label') {
                // this.error = error.response.data.errors[el2].message
                this.be_error_shop_name = error.response.data.errors[el2].message
              }
              if (el2 === 'model') {
                this.error = error.response.data.errors[el2].message
              }
              if (el2 !== 'partita_iva' && el2 !== 'codice_fiscale' && el2 !== el2 === 'ragione_sociale' && el2 != 'label' && el2 !== 'model') {
                this.error = error.response.data.errors[el2].message
              }
            })
          }
        }
        setTimeout(() => {
          // this.error = null
        }, 3000)
      } finally {
        this.loading=false
      }
    }
  }
}
</script>

<style scoped>
  .error {
    color: #f91942;
  }
  .is-invalid {
    color: #f91942 !important;
    border-color: #f91942 !important;
  }
  .is-valid {
    /* color: green !important; */
    border-color: green !important;
    /* background: #e6e6e6 !important; */
  }
  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
  }
</style>

<script>
import Vue from 'vue'
import { FormSelectPlugin } from 'bootstrap-vue'
Vue.use(FormSelectPlugin)
export default {
    name: 'timeslot',
    props: [ 'inputtimes', 'index', 'edit', ],
    created() {
        this.times = this.inputtimes ? this.inputtimes : [];
        for ( var i = 0; i < 24; i++ ) {
            var text = i < 10 ? `0${ i }` : String( i );
            this.hours.push( { value: text, text } );
        }
    },
    computed: {
    },
    methods: {
        remove_time( time ) {
            this.$emit( 'removetime', { item_index: this.index, time_index: time._index } );
        },
        set_add() {  // public
            this.new_item = true;
        },
        save_new_time() {
            if ( this.from_h !== null && this.from_m !== null && this.to_h !== null && this.to_m !== null  ) {
                var obj = {
                    item_index : this.index,
                    time       : {
                        from : [ this.from_h, this.from_m ].join(':'),
                        to   : [ this.to_h, this.to_m ].join(':'),
                    },
                };
                this.$emit( 'savetime', obj );
                this.new_item = false;
            }
        },
    },
    data () {
        return {
            days     : null,
            selected : null,
            hours    : [{value: null, text: 'ora'}],
            minutes  : [ {value: null, text: 'minuti'}, { value: '00', text: '00' }, { value: '15', text: '15' }, { value: '30', text: '30' }, { value: '45', text: '45' }, ],
            from_h   : null,
            from_m   : null,
            to_h     : null,
            to_m     : null,
            new_item : false,
        }
    },
};

</script>

<template>

    <div style="margin-top: 25px;">
        <div>
            <div v-for="(time, indx) in times" :key="indx" class="col-xs-12">
                <div
                    style="margin-right: 2px; background-color: #f3f3f3; padding:10px; border-radius: 4px;"
                    class="col-xs-12 col-md-8"
                ><i class="im im-icon-Hour col-xs-1" style="margin-right: 6px;"></i><span class="col-xs-3 col-md-2"><b>{{ time.from }}</b></span><i class="im im-icon-Arrow-Right2 col-xs-1" style="margin-right: 6px;"></i> <span class="col-xs-3 col-md-2"><b>{{ time.to }}</b></span> <i class="fa fa-remove col-xs-1" v-if="edit" style="cursor: pointer; margin-top:5px; color: #f91942; opacity: 0.8" @click="remove_time(time)"></i></div>
            </div><hr class="col-xs-12">
            <div class="form-group form-inline" style="margin-top: 10px;" v-if="new_item">
                <h4 class="h4">Fascia Oraria</h4>
                <div class="col-xs-12 col-md-12" style="background-color: #f3f3f3;">
                    <label style="margin-top: 10px; margin-bottom: 10px;" for="from_h">dalle: &nbsp;</label>
                    <b-form-select v-model="from_h" id="orari_from_h" :options="hours" size="sm" class="col-xs-12 col-sm-3 custom_select"></b-form-select>
                    <label for="from_m" class="col-xs-12 col-sm-1 custom_label">&nbsp; : &nbsp;</label>
                    <b-form-select v-model="from_m" id="orari_from_m" :options="minutes" size="sm"  class="col-xs-12 col-sm-3 custom_select"></b-form-select>
                    <span class="col-sm-5"></span>
                </div>
                <div class="col-xs-12 col-md-12" style="background-color: #f3f3f3; margin-top: 8px;">
                    <label style="margin-top: 10px; margin-bottom: 10px;" for="from_h">&nbsp;alle: &nbsp;</label>
                    <b-form-select v-model="to_h" id="orari_to_h" :options="hours" class="col-xs-12 col-sm-3 custom_select"></b-form-select>
                    <label for="to_h" class="col-xs-12 col-sm-1 custom_label">&nbsp; : &nbsp;</label>
                    <b-form-select v-model="to_m" id="orari_to_m" :options="minutes" class="col-xs-12 col-sm-3 custom_select"></b-form-select>
                    <span class="col-sm-5"></span>
                </div>
                <div class="col-xs-12 col-md-12" style="background-color: #4a4a4a; margin-top: 8px; padding: 10px;">
                    <div class="add-slot-btn">
                        <button @click="save_new_time()">Salva</button>
                        <button @click="new_item = false">Annulla</button>
                    </div>
                    <!-- &nbsp;&nbsp;<button class="button button_small save" @click="save_new_time()">salva</button>
                    &nbsp;&nbsp;<button class="button button_small" @click="new_item = false">annulla</button> -->
                </div>
                <hr class="col-xs-12">
            </div>
        </div>
    </div>

</template>
<style scoped>
  .h4 {
    padding: 0 0;
    border-bottom: none;
    margin-bottom: 20px;
  }
  .custom_select {
    height: 35px;
    line-height: 35px;
    padding: 0px 18px;
  }
  .custom_label {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
  }
  .button_small {
    padding: 6px 15px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    background: rgba(249,25,66,.06) !important;
    color: #f91942 !important;
    transition: 0.3s;
  }
  .button_small:hover {
    background: rgba(249,25,66,.16) !important;
  }
  .save {
    background: rgba(40,167,69,.06) !important;
    color: #28a745 !important; 
  }
  .save:hover {
    background: rgba(40,167,69,.16) !important;
  }
</style>



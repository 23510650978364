<template>
  <div class="login">
    <div class="text-center" v-if="loading" style="position:absolute; top: 2%; left: 48%; height: 100px !importan;">
      <div class="loader_login"></div>
    </div>
    <div :class="loading ? 'fs-container opacity' : ''">
      <p class="form-row form-row-wide">
        <label for="email">Email:
          <i class="im im-icon-Mail"></i>
          <input v-model="email" required type="email" :disabled="success" :class="error || error_email ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text'" name="email" id="email" />
        </label>
      </p>

      <p class="form-row form-row-wide">
        <label for="password">Password:
          <i class="im im-icon-Lock-2"></i>
          <input v-model="password" required :disabled="success" :class="error ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text'" type="password" name="password" id="password"/>
        </label>
        <!-- <span class="lost_password">
          <a href="#" >Password dimenticata?</a>
        </span> -->
      </p>
      <div class="col-sm-12" v-if="error || error_email">
        <span style="color:red;" class="col-sm-12 text-center">
          {{ error }} {{ error_email }}
        </span>
      </div>
      <div class="col-sm-12">
        <span style="color:green;" class="col-sm-12 text-center">
          {{success? 'Login avvenuto con successo' : ''}}
        </span>
      </div>

      <div class="col-sm-12">
        <span style="color:green;" class="col-sm-12 text-center">
          {{reset? 'Successo! Controlla la tua casella di posta' : ''}}
        </span>
      </div>

      <div class="form-row">
        <button :class="checkForm() ? 'button border margin-top-5 custom_close' : 'button border custom_close margin-top-5 disabled'" :disabled="!checkForm()" @click="login">Login</button>
          <label class="psw_forgotten" @click="reset_password()">Password dimenticata?</label>
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import NotificationService from '@/services/NotificationService'
// import WSPresence from '@/utils/ws_presence'
export default {
  name: 'login',
  data () {
    return {
      loading: false,
      email: '',
      password: '',
      error: null,
      error_email: null,
      success: false,
      reset: false,
      state: null,
      remember: false
    }
  },
  methods: {
    validationEmail () {
      if (this.email.length > 3 && /.+@.+/.test(this.email))
        return true
      return false
    },
    validationPassword () {
        if (this.password.length > 3)
          return true
        return false
    },
    checkForm() {
      return (this.validationEmail() &&
        this.validationPassword()
      )
    },
    async reset_password () {
      if (!this.loading) {
        this.loading = true
        this.error = null
        this.error_email = null
        if (this.email === '') {
          this.error_email = "Inserisci l'email!"
          this.loading = false
        } else {
          try {
            let ris = await AuthenticationService.sendEmailResetPassword({email: this.email})
            if (ris) {
              this.error = null
              this.error_email = null
              this.reset = true
              this.loading = false
              setTimeout(() => {
                location.reload()  
              }, 2000)
            }
          } catch(error) {
            // this.error_email = "Errore! Contatta l'assistenza tecnica"
            error.response ? this.error_email = error.response.data.message : this.error_email = error + "! Contatta l'assistenza"
            this.loading = false
            setTimeout(() => {
              this.error = null
              this.error_email = null
            }, 3000)
          }
        }
      }
    },
    async login () {
      this.loading = true
      try {
        this.error = null
        const response = await AuthenticationService.login({
          email: this.email,
          password: this.password
        })
        this.success = true
        console.log('Login res: ', response)
        try {
          let ids = []
          ids.push(response.data.id)
          // let notify = await NotificationService.pushNotificationToUser(ids)
          // console.log('ok notifica: ', notify)
          this.$store.dispatch('user/setToken', response.data.token) // serve?
          this.$store.dispatch('user/setUser', response.data)
          this.$store.dispatch('user_address/setAddress', response.data.addresses)
          this.$store.dispatch('user_meta/setUserMeta', response.data.metas)
          this.$store.dispatch('my_shops/setMyShops', response.data.myshops)
          if(response.data.role_id === 5) {
            this.$store.dispatch('shop/setShop', response.data.myworkshops[0].api)
            this.$store.dispatch('presence_ws/setStatus', 'free')
          }
          this.$store.dispatch('classification/load_data');
          setTimeout(() => {
            // this.$router.push({
            //   path: '/dashboard-company'
            // })
            if(response.data.role_id !== 5) {
              // WSPresence.start(10)
              window.location.href = '/dashboard-company-my-profile'
              // this.$router.push({
              //   path: '/dashboard-company-my-profile'
              // })
            } else {
              window.location.href = '/dashboard-company-my-shop?shop=0'
              // this.$router.push({
              //   path: '/dashboard-company-my-shop'
              // })
            }
          }, 2000)
        } catch(error) {
          console.log(error)
          if (error.response.data.message === "l'utente non è abilitato al login")
            this.error = 'Controlla la tua casella di posta e conferma la registrazione'
          else
            this.error = 'Credenziali non valide'
          setTimeout(() => {
            this.error = null
          }, 3000)
          this.loading = false
        }
      } catch (error) {
        console.log(error)
        if (error.response.data.message === "l'utente non è abilitato al login")
          this.error = 'Controlla la tua casella di posta e conferma la registrazione'
        else
          this.error = 'Credenziali non valide'
        setTimeout(() => {
          this.error = null
        }, 3000)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    if (this.$store.state.user.user != null) {
      this.email = this.$store.state.user.user.email
    }
  }
}
</script>

<style scoped>
  .is-invalid {
    color: red !important;
    border-color: red !important;
  }
  .is-valid {
    color: green !important;
    border-color: green !important;
    background: #e6e6e6 !important;
  }
  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
  }
  .psw_forgotten {
    color: #333;
    cursor: pointer;
    transition: .3s;
    margin-top: 20px;
  }
  .psw_forgotten:hover {
    color: #f91942;
    transition: .3s;
  }
  .loader_login {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #fa3256;
    width: 70px;
    height: 70px;
    margin: auto;
    margin-top: 250px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

</style>

<script>

export default {
    name: 'dayrow',
    props: [ 'inputdays', 'days', 'useddays', 'index', 'edit' ],
    created() {
        this.selected = this.inputdays;
    },
    computed: {
    },
    methods: {
        get_day_class(day_index) {
            if ( this.selected.includes( parseInt(day_index) ) ) { return 'bg-info'; }
            return this.useddays.includes( parseInt(day_index) ) ? 'bg-dark' : 'bg-light';
        },
        get_day_style(day_index) {
            if ( this.edit === false ) { return { cursor: 'text' }; }
            return this.get_day_class( day_index ) === 'bg-dark' ? { cursor: 'not-allowed' } : { cursor: 'pointer' }
        },
        change_day( day_index ) {
            var day_class = this.get_day_class( day_index );
            if ( this.edit === false || day_class === 'bg-dark' ) { return; }
            var emit = day_class === 'bg-info' ? 'removeday' : 'addday';
            this.$emit( emit, { day_index, index: this.index } );
        },
        remove_item( day_index ) {
            this.$emit( 'removeitem', { index: this.index } );
        },
        add_time_slot() {
            this.$emit( 'addtime', { item_index: this.index } );
        },
    },
    data () {
        return {
            selected : null,
        }
    },
};

</script>

<template>

    <div style="margin-top: 25px;">
        <div class="">
            <button
                v-for      = "( day, day_index ) in days"
                :class   = "get_day_class( day_index )"
                v-bind:key = "day_index"
                style      = "margin-right: 2px; cursor: pointer;"
                :style     = "get_day_style( day_index )"
                @click     = "change_day( day_index )"
            >{{ day.short_name }}</button>
            <button class="button button_small info button_orari_mobile"
                v-if     = "edit"
                variant  = "warning"
                style    = "margin-left: 14px; cursor: pointer;"
                @click   = "add_time_slot()"
            ><i class="sl sl-icon-plus" style="margin-right: 5px;"></i>fascia oraria</button>
            <button class="button button_small button_orari_mobile"
                v-if     = "edit"
                variant  = "danger"
                style    = "margin-left: 5px; cursor: pointer;"
                @click   = "remove_item()"
            ><i class="im im-icon-Remove" style="margin-right: 5px;"></i>blocco</button>
        </div>
    </div>

</template>
<style scoped>
  .button_small {
    padding: 6px 15px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
  }
  .info {
    color: white;
    background-color: #17a2b8;
    opacity: 0.8;
  }
  .bg-info {
    background-color: #17a2b8;
    color: white;
    border-color: #17a2b8;
    border-radius: 4px;
  }
  .bg-dark {
    background-color: #343a40!important;
    color: white;
    border-color: #343a40;
    border-radius: 4px;
  }
  .bg-light {
    color: #343a40;
    background-color: #f8f9fa!important;
    border-color: #343a40;
    border-radius: 4px;
  }
  @media only screen and (max-width: 600px) {
    .button_orari_mobile {
        margin-top: 25px;
    }
  }

</style>


<script>
import RegisterCompany from '../RegisterCompany.vue';
const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]
import ClassificationCatList from './cat_list';
import ClassificationSubcatList from './subcat_list';

export default {

    name: 'classification-edit-registration',
    props: [ 'classification' ],
    components: { ClassificationCatList, ClassificationSubcatList, RegisterCompany },
    data() {
        return {
            cat_id             : null,
           // new_category : {'s':[], 'c': [], 'u':[]},
            new_classification: {'s':[], 'c': [], 'u':[]},
            key                : 0,
            key_cats           : 0,
            key_cat            : 0,
            key_subcat         : 0,
            div_category       : false,
            
        };
    },
    computed: {
        classification_loaded() {  
            console.log("Stampo classification:")
            console.log(this.classification)
            return this.$store.state.classification.obj === null ? false : true
         },
        selected_ids: {
            get() { return this.$store.state.classification.selected_ids },
            set( selected_ids ) { return this.$store.dispatch( 'classification/set_selected_ids', selected_ids ); },
        },
    },
    watch: {},
    mounted() {
        //console.log("Provo a stampare new_category:")
        //console.log(this.new_category)
        //this.$store.dispatch('classification/setnew_classification', this.classification);
        this.observer = new MutationObserver(mutations => {
          for (const m of mutations) {
            const newValue = m.target.getAttribute(m.attributeName);
            this.$nextTick(() => {
              this.on_class_change(newValue, m.oldValue);
            });
          }
        });
        this.observer.observe(this.$refs['dialog_edit_classification'], {
          attributes: true,
          attributeOldValue : true,
          attributeFilter: ['class'],
        });
    },
    created() {
        this.reset_classification();
    },
    methods: {  
        open_dialog_edit_category() {
            
            if( this.div_category == true){
                this.div_category = false
            }
            else{
                this.div_category = true
            }
        },
        reset_classification() {
         //   this.new_classification = JSON.parse( JSON.stringify( this.classification ) );
         //   this.$store.dispatch('classification/set_selected_ids_by_id', this.new_classification.u);
            this.new_classification = {'s':[], 'c': [], 'u':[]}
            this.$store.dispatch('classification/set_selected_ids_by_id', this.new_classification.u);
            this.key++;
            this.key_cats++;
        },
        remove_subcat( type ) {
            //var selected_ids = JSON.parse( JSON.stringify( this.selected_ids ) );
            //var index = selected_ids.map( x => x.id ).indexOf( type.id );
            //selected_ids.splice( index, 1 );
            //this.selected_ids = selected_ids;
            this.set_subcategory( { action: 'remove', subcat: type } );
        },
        on_class_change(classAttrValue) {
            const classList = classAttrValue.split(' ');
            if ( classList.includes('mfp-hide') ) {
                this.$emit( 'submit-search', 'page' )
            }
        },
        set_cat_id( obj ) {
            this.cat_id = obj.cat_id;
        },
        close_dialog() {
           // var exit = document.getElementsByClassName("mfp-close");
           // exit[0].click();
           if( this.div_category == true){
                this.div_category = false
            }
            else{
                this.div_category = true
            }

        },
        set_subcategory( obj ) {
            if ( obj.action === 'add' ) {
                 console.log("Stampo new_category")
                console.log(this.new_classification);
                console.log("Stampo new_category.u")
                console.log(this.new_classification.u);
            this.new_classification.u.push( obj.subcat.id );
                if ( this.new_classification.c.includes( obj.subcat.parentId ) === false ) {
                    this.new_classification.c.push( obj.subcat.parentId );
                }
            } else if ( obj.action === 'remove' ) {
                // lista subcat x cat
                // verifico che ne abbia almeno una
                // in caso rimuovo la cat
                var index = this.new_classification.u.indexOf( obj.subcat.id );
                if ( index > -1 ) { this.new_classification.u.splice( index, 1 ); }
                var subcat_list = this.$store.getters['classification/subcategories']( obj.subcat.parentId );
                var has_subcats = false;
                for ( var i = 0; i < subcat_list.length; i++ ) {
                    var subcat = subcat_list[ i ];
                    //if ( subcat.id === obj.subcat.id ) { continue; }// escludo quella che devo eliminare
                    if ( this.new_classification.u.includes( subcat.id ) ) { has_subcats = true; break; }
                }
                if ( !has_subcats ) {
                    console.log( 'remove_category' );
                    var index = this.new_classification.c.indexOf( obj.subcat.parentId );
                    if ( index > -1 ) { this.new_classification.c.splice( index, 1 ); }
                }
            }
            this.$store.dispatch('classification/set_selected_ids_by_id', this.new_classification.u);
            this.key_cats++;
            console.log( obj );
        }
    },

}

</script>

<template>

    <div>

        <div @click="open_dialog_edit_category" style="border: 1px solid #dbdbdb; padding: 6px; min-height: 51px; cursor: pointer;" :key="key_cats">
            <div v-if="selected_ids.length === 0" style="vertical-align: middle; color: grey; padding-left: 15px; padding-top: 6px;">Clicca per impostare le categorie</div>
            <template v-if="selected_ids.length > 0">
                <div v-for="type in selected_ids" style="display: inline; cursor: default;">
                    <span style="font-weight: 600; font-size: 14px; background-color: red; padding: 3px; padding-left: 6px; padding-right: 6px; border-radius: 4px; margin-right: 3px; color: white">{{ type.label }} <i class="fa fa-close" style="cursor: pointer;" @click.stop="remove_subcat( type )"></i></span>
                </div>
            </template>
        </div>
        <!--<p style="color: black;">{{new_classification}}</p>-->
        <!-- Search PopUp -->
          <div v-if="div_category" id="small-dialog-category" ref="dialog_edit_classification">
            
            <div class="small-dialog-content" :key="key">
                <div class="col"> <!-- column -->
                    <div class="row"> <!-- row -->
                       <!-- <div class="col-md-4 hidden-sm hidden-xs"  > 
                            <classification-cat-list
                                :key              = "key_cat"
                                :cat_id           = "cat_id"
                                :input_categories = "new_category.c || []"
                                @set-category     = "set_cat_id"
                                />
                        </div>-->
                       
                        <div class="col-md-12 col-xs-12 col-sm-12"> <!-- right body menu -->
                            <classification-subcat-list
                                :key                 = "key_subcat"
                                :cat_id              = "cat_id"
                                :input_subcategories = "new_classification.u || []"
                                @set-subcategory     = "set_subcategory"
                                />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-1"> </div>
                        <div class="col-xs-5 text-right"> <!-- column -->
                            <input type="submit" @click="reset_classification" class="button border fw margin-top-10 button-search" style="padding: 3px; width: 80px; background-color: #FFC107; color: grey;" name="reset" value="Reset" />
                        </div>
                        <div class="col-xs-5 text-right"> <!-- column -->
                            <input type="submit" @click="close_dialog" class="button border fw margin-top-10 button-search" style="padding: 3px; width: 80px;" name="search" value="OK" />
                        </div>
                        <div class="col-xs-1"> </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>


<style>
</style>


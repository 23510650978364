<template>
<div>
  <div class="text-center" style="position:absolute; top: 15%; left: 32%; height: 500px;" v-if="loading">
    <div class="loader"></div>
  </div>
  <div :class="loading ? 'dashboard-list-box margin-top-0 fs-container opacity' : 'dashboard-list-box margin-top-0'">
    <h4 class="gray">Dettagli Negozio</h4>
    <div class="dashboard-list-box-static">

      <!-- Details -->
      <div class="my-profile">
        
        <label>Indirizzo del Negozio</label>
        <Address :id_address="'mapaddress'" ref="shop_address" key="0"/>
        <div class="col-sm-12" v-if="error_maps_shop" style="margin-bottom: 15px;">
          <span class="text-warning">{{ error_maps_shop }}</span>
        </div>

        <label>Sede Legale</label>
        <Address :id_address="'maplegaladdress'" ref="legal_address" key="1"/>
        <div class="col-sm-12" v-if="error_maps_legal" style="margin-bottom: 15px;">
          <span class="text-warning">{{ error_maps_legal }}</span>
        </div>

        <!-- <label>Indirizzo del Negozio</label>
        <input placeholder="Inserisci l'indirizzo del negozio" v-model="address" type="text">
        
        <label>Sede Legale</label>
        <input placeholder="Inserisci la sede lagale" v-model="sede_legale" type="text"> -->

        <label>Email del negozio</label>
        <input placeholder="Inserisci una email del negozio" maxlength="64" v-model="contacts.email" type="email">

        <label>Cellulare del negozio</label>
        <input placeholder="Inserisci il numero di cellulare del negozio" maxlength="64" v-model="contacts.cellulare" type="text">

        <label>Numero di whatsapp</label>
        <input placeholder="Inserisci il numero di whatsapp" maxlength="64" v-model="contacts.whatsapp" type="text">

        <label>Fax del negozio</label>
        <input placeholder="Inserisci il fax del negozio" maxlength="64" v-model="contacts.fax" type="text">

        <label>Telefono del negozio</label>
        <input placeholder="Inserisci il telefono del negozio" maxlength="64" v-model="contacts.telefono" type="text">

        <label>Sito web</label>
        <input placeholder="Inserisci l'url del tuo sito web (https://miosito.it)" maxlength="64" v-model="contacts.web_url" type="text">

        <label>Pagina Facebook</label>
        <input placeholder="Inserisci l'url della tua pagina facebook (www.facebook.com/nomePagina)" maxlength="64" v-model="contacts.facebook_url" type="text">

        <label>Profilo Instagram</label>
        <input placeholder="Inserisci l'url del tuo profilo Instagram (www.instagram.com/nomePagina)" maxlength="64" v-model="contacts.instagram_url" type="text">
        
        <label>Metodi di Pagamento Supportati</label>
        <div class="col-xs-12" style="margin-bottom: 20px;" v-if="options_metodi_pagamento_supp">
          <div :class="indx > 2 ? 'col-xs-12 col-md-4 margin_checkbox' :'col-xs-12 col-md-4'" v-for="(opt, indx) in options_metodi_pagamento_supp" :key="indx">
            <div>
              <input class="form-check-input" type="checkbox" :value="opt.value" v-model="metodi_pagamento_supp">
              <span class="form-check-label" style="margin-left: 20px;"><i :class="opt.text === 'PayPal' ? 'fa fa-paypal' : opt.text === 'Carta' ? 'im im-icon-Credit-Card2' : opt.text === 'Contanti' ? 'im im-icon-Money-2' : 'sl sl-icon-settings'"></i> {{ opt.text }} <i v-if="opt.text !== 'PayPal' && opt.text !== 'Carta' && opt.text !== 'Contanti'"  class="im im-icon-Remove custom_p_icon" style="margin-left: 10px; color: #f91942; cursor:pointer;" @click="remove_payment_method(opt.text)"></i></span>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 add_custom" v-if="!pagamento_custom.flag">
            <div @click="pagamento_custom.flag = true" style="margin-top:10px;"><i class="col-xs-2 sl sl-icon-plus" style="margin-top:5px;"></i> <span class="col-xs-4">Aggiungi</span></div>
          </div>
          <div class="col-xs-12" v-if="pagamento_custom.flag">
            <!-- <div class="col-xs-12"> -->
              <input class="col-xs-10 input_custom" maxlength="64" :style="pagamento_custom.error ? 'margin-top:10px; border-color:red;' : 'margin-top:10px;'" v-model="pagamento_custom.input" placeholder="Metodo.." type="text" />
              <i class="fa fa-remove col-xs-1 cancel" @click="pagamento_custom.input = ''; pagamento_custom.flag = false" style=" margin-top: 20px;"></i>
              <i class="sl sl-icon-check col-xs-1 add_payment_icon" @click="add_payment_method()" style=" margin-top: 20px;"></i>
            <!-- </div> -->
          </div>
        </div>

        <label>Metodo di Pagamento Preferito</label>
         <div class="col-xs-12" style="margin-bottom: 20px;">
           <div :class="indx > 2 ? 'col-xs-12 col-md-4 margin_checkbox' :'col-xs-12 col-md-4'" v-for="(opt, indx) in metodi_pagamento_supp" :key="indx">
            <div>
              <input class="form-check-input" type="radio" name="metodo_pagamento_preferito" :value="opt" v-model="metodo_pagamento_preferito">
              <span class="form-check-label" style="margin-left: 20px;" ><i :class="options_metodi_pagamento_supp.filter((el) => el.value === opt)[0].text === 'PayPal' ? 'fa fa-paypal' : options_metodi_pagamento_supp.filter((el) => el.value === opt)[0].text === 'Carta' ? 'im im-icon-Credit-Card2' : options_metodi_pagamento_supp.filter((el) => el.value === opt)[0].text === 'Contanti' ? 'im im-icon-Money-2' : 'sl sl-icon-settings'"></i> {{ options_metodi_pagamento_supp.filter((el) => el.value === opt)[0].text }} </span>
            </div>
          </div>
         </div>

         <label>Metodi di Consegna Supportati</label>
        <div class="col-xs-12" style="margin-bottom: 20px;" v-if="options_consegna_pagamento_supp">
          <div :class="indx > 2 ? 'col-xs-12 col-md-4 margin_checkbox' :'col-xs-12 col-md-4'" v-for="(opt, indx) in options_consegna_pagamento_supp" :key="indx">
            <div>
              <input class="form-check-input" type="checkbox" :value="opt.value" v-model="metodi_consegna_supp">
              <span class="form-check-label" style="margin-left: 20px;"><i :class="opt.text === 'In Negozio' ? 'im im-icon-Shop-2' : opt.text === 'A domicilio' ? 'im im-icon-Home-5' : 'sl sl-icon-settings'"></i> {{ opt.text }} <i v-if="opt.text !== 'In Negozio' && opt.text !== 'A domicilio'"  class="im im-icon-Remove custom_p_icon" style="margin-left: 10px; color: #f91942; cursor:pointer;" @click="remove_delivery_method(opt.text)"></i></span>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 add_custom" v-if="!consegna_custom.flag">
            <div @click="consegna_custom.flag = true" :style="options_consegna_pagamento_supp.length > 2 ? 'margin-top:10px;' : ''"><i class="col-xs-2 sl sl-icon-plus" :style="'margin-top:5px;'"></i> <span class="col-xs-4">Aggiungi</span></div>
          </div>
          <div class="col-xs-12" v-if="consegna_custom.flag">
            <!-- <div class="col-xs-12"> -->
              <input class="col-xs-10 input_custom" maxlength="64" :style="consegna_custom.error ? 'margin-top:10px; border-color:red;' : 'margin-top:10px;'" v-model="consegna_custom.input" placeholder="Consegna.." type="text" />
              <i class="fa fa-remove col-xs-1 cancel" @click="consegna_custom.input = ''; consegna_custom.flag = false" style=" margin-top: 20px;"></i>
              <i class="sl sl-icon-check col-xs-1 add_payment_icon" @click="add_consegna_method()" style=" margin-top: 20px;"></i>
            <!-- </div> -->
          </div>
        </div>

        <label>Metodo di Consegna Preferito</label>
         <div class="col-xs-12" style="margin-bottom: 20px;">
           <div :class="indx > 2 ? 'col-xs-12 col-md-4 margin_checkbox' :'col-xs-12 col-md-4'" v-for="(opt, indx) in metodi_consegna_supp" :key="indx">
            <div>
              <input class="form-check-input" type="radio" name="metodo_consegna_preferito" :value="opt" v-model="metodo_consegna_preferito">
              <span class="form-check-label" style="margin-left: 20px;"><i :class="options_consegna_pagamento_supp.filter((el) => el.value === opt)[0].text === 'In Negozio' ? 'im im-icon-Shop-2' : options_consegna_pagamento_supp.filter((el) => el.value === opt)[0].text === 'A domicilio' ? 'im im-icon-Home-5' : 'sl sl-icon-settings'"></i> {{options_consegna_pagamento_supp.filter((el) => el.value === opt)[0].text}} </span>
            </div>
          </div>
         </div>
        <br><br>
        <label>Guarda il tutorial per impostare gli Orari! <a class="tutorial_orari" href="https://youtu.be/InaEnfV0n_0" target="_blank">Clicca</a></label>
        <label>Orario di Apertura</label>
        <Orari ref="opening" v-bind:title="'Apertura'" :inputobj="getOrari.apertura" v-if="orari_apertura"></Orari><br><br><br>
        
        <label>Orario di Consegna</label>
        <Orari ref="delivery" v-bind:title="'Consegna'" :inputobj="getOrari.consegna" v-if="orari_consegna"></Orari><br><br><br>
        
        <label>Orario di Chiamata</label>
        <Orari ref="contact" v-bind:title="'Chiamata'" :inputobj="getOrari.chiamata" v-if="orari_chiamata"></Orari>
        <!-- <Orari ref="calling" v-bind:title="'Chiamata'"/> -->
        
        <label>App di terze parti</label>
        <div class="col-xs-12" style="margin-bottom: 20px;">
          <div class="col-xs-12" v-for="(opt, indx) in options_app_third_part" :key="indx">
          <!-- <div :class="indx > 2 ? 'col-xs-12 col-md-4 margin_checkbox' :'col-xs-12 col-md-4'" v-for="(opt, indx) in options_app_third_part" :key="indx"> -->
            <div>
              <input class="form-check-input" type="checkbox" :value="opt.value" v-model="app_third_part">
              <span class="form-check-label" style="margin-left: 20px;">{{ opt.text }}</span>
            </div>
          </div>
        </div>

        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>

        <div class="col-sm-12" v-if="error_third_app">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-for="error_app in error_third_app" :key="error_app">{{ error_app }}<br></span>
          </span>
        </div>

        <!-- <div class="col-sm-12" v-if="error_third_app">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-for="error_app in error_third_app" :key="error_app">{{ error_app }}</span>
          </span>
        </div> -->

        <div class="col-sm-12" :style="success ? 'border: 1px solid green; padding: 20px; border-radius: 4px;' : 'padding: 20px;'">
          <span style="color:green;" class="col-sm-12 text-center">
            <span v-html="success ? 'Dati Aggiornati!' : ''"></span>
          </span>
        </div>
        
        <button class="button margin-top-15" @click="save()">Salva</button>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Address from '@/components/Address'
import AddressService from '@/services/AddressService'
import ShopService from '@/services/ShopService'
import DefaultService from '@/services/DefaultService'
import Vue from 'vue'
import {FormCheckboxPlugin, BFormFile, BIconFullscreenExit} from 'bootstrap-vue'
import Orari from '@/components/Orari'
Vue.use(FormCheckboxPlugin)
Vue.component('b-form-file', BFormFile)
export default {
  name: 'shop-meta-data',
  components: {
    Orari,
    Address
  },
  data() {
    return {
      loading: true,
      error: null,
      error_maps_shop: null,
      error_maps_legal: null,
      success: false,
      contacts: {
        email: null,
        cellulare: null,
        whatsapp: null,
        fax: null,
        telefono: null,
        web_url: null,
        facebook_url: null,
        instagram_url: null,
      },
      orari_apertura: null,
      orari_consegna: null,
      orari_chiamata: null,
      logo: null,
      gallery: [],
      address: null,
      flag_address: false,
      sede_legale: null,
      flag_sede_legale: false,
      metodi_pagamento_supp: [],
      metodo_pagamento_preferito: null,
      options_metodi_pagamento_supp: null,
      pagamento_custom: {
        flag: false,
        input: '',
        error: null
      },
      metodi_consegna_supp: [],
      metodo_consegna_preferito: null,
      options_consegna_pagamento_supp: null,
      consegna_custom: {
        flag: false,
        input: '',
        error: null
      },
      options_app_third_part: [],
      app_third_part: [],
      error_third_app: []
    }
  },
  async mounted() {
    // prendi tutti i dati dell'utente e del negozio. Potrebbero non esserci tutti (registrazione spezzettata)!
    // Rendi tutto editabile.
    // SHOP ADDRESS
    this.$watch(
        () => {
            return this.$refs['shop_address'].data_parsed
        },
      (val) => {
        console.log('Parsed SHOP: ', val)
        if(!val.n_civ) {
          this.error_maps_shop = 'Il civico non è stato trovato. Verrai posizionato al centro della via!'
        } else if (!val.cap) {
          this.error_maps_shop = 'Il cap non è stato trovato!'
        } else if (!val.citta) {
          this.error_maps_shop = 'La citta non è stata trovata!'
        } else if (!val.country) {
          this.error_maps_shop = 'La nazione non è stata trovata!'
        } else if (!val.localita) {
          this.error_maps_shop = 'La localita non è stata trovata'
        } else if (!val.provincia) {
          this.error_maps_shop = 'La provincia non è stata trovata!'
        } else if (!val.regione) {
          this.error_maps_shop = 'La regione non è stata trovata!'
        } else if (!val.strada) {
          this.error_maps_shop = 'La via non è stata trovata!'
        } else {
          this.error_maps_shop = null
        }
      }
    )
    // LEGAL ADDRESS
    this.$watch(
        () => {
            return this.$refs['legal_address'].data_parsed
        },
      (val) => {
        console.log('Parsed LEGAL: ', val)
        if(!val.n_civ) {
          this.error_maps_legal = 'Il civico non è stato trovato. Verrai posizionato al centro della via!'
        } else if (!val.cap) {
          this.error_maps_legal = 'Il cap non è stato trovato!'
        } else if (!val.citta) {
          this.error_maps_legal = 'La citta non è stata trovata!'
        } else if (!val.country) {
          this.error_maps_legal = 'La nazione non è stata trovata!'
        } else if (!val.localita) {
          this.error_maps_legal = 'La localita non è stata trovata'
        } else if (!val.provincia) {
          this.error_maps_legal = 'La provincia non è stata trovata!'
        } else if (!val.regione) {
          this.error_maps_legal = 'La regione non è stata trovata!'
        } else if (!val.strada) {
          this.error_maps_legal = 'La via non è stata trovata!'
        } else {
          this.error_maps_legal = null
        }
      }
    )
    try {
      // const shop_meta = await ShopService.getShopMeta(this.$store.state.user.user.myworkshops[0].id)    
      // this.$store.dispatch('shop_meta/setShopMeta', shop_meta.data.metas)
      // this.$store.dispatch('shop_address/setAddress', shop_meta.data.addresses)
      // if (shop_meta) {
        let all_app = await DefaultService.getAppDefault()
        console.log('app terze parti: ', all_app)
        if (all_app) {
          this.options_app_third_part = all_app.data.map(el => {
            return { value: el.name, image: el.image, text: el.label, name: el.name, user_id: el.user_id, user: el.user, logo: el.logo, metas: el.metas}
          })
        }
        this.orari_apertura = this.$store.state.shop_meta.shop_meta.timetable.value ? this.$store.state.shop_meta.shop_meta.timetable.value : []
        this.orari_consegna = this.$store.state.shop_meta.shop_meta.delivery_timetable.value ? this.$store.state.shop_meta.shop_meta.delivery_timetable.value : []
        this.orari_chiamata = this.$store.state.shop_meta.shop_meta.contact_timetable.value ? this.$store.state.shop_meta.shop_meta.contact_timetable.value : []

        this.options_metodi_pagamento_supp = this.$store.state.shop_meta.shop_meta.payment_types.value ? this.fillOptionsPayment(this.$store.state.shop_meta.shop_meta.payment_types.value) : []
        this.options_consegna_pagamento_supp = this.$store.state.shop_meta.shop_meta.delivery_types.value ? this.fillOptionsDelivery(this.$store.state.shop_meta.shop_meta.delivery_types.value) : []
        if (this.$store.state.shop_meta.shop_meta.contacts.value)
          this.contacts = this.$store.state.shop_meta.shop_meta.contacts.value
        if (this.$store.state.shop_address.address && this.$store.state.shop_address.address.filter((el) => el.label === 'negozio')[0].geodata) {
          this.$refs['shop_address'].addressInput = this.$store.state.shop_address.address.filter((el) => el.label === 'negozio')[0].geodata.formatted_address
          this.$refs['shop_address'].stop = true
        } else {
          this.$refs['shop_address'].addressInput = ''
        }
        if (this.$store.state.shop_address.address && this.$store.state.shop_address.address.filter((el) => el.label === 'ragione_sociale')[0].geodata) {
          this.$refs['legal_address'].addressInput = this.$store.state.shop_address.address.filter((el) => el.label === 'ragione_sociale')[0].geodata.formatted_address
          this.$refs['legal_address'].stop = true
        } else {
          this.$refs['legal_address'].addressInput = ''
        }
        this.app_third_part = this.$store.state.shop_meta.shop_meta.thrd_part_apps.value.map(el => el.name)
        //this.$refs['shop_address'].addressInput = (this.$store.state.shop_address.address && this.$store.state.shop_address.address.filter((el) => el.label === 'negozio').length > 0) ? this.$store.state.shop_address.address.filter((el) => el.label === 'negozio')[0].geodata.formatted_address : null
        //this.$refs['legal_address'].addressInput = (this.$store.state.shop_address.address && this.$store.state.shop_address.address.filter((el) => el.label === 'ragione_sociale').length > 0) ? this.$store.state.shop_address.address.filter((el) => el.label === 'ragione_sociale')[0].geodata.formatted_address : null
        //this.$refs['shop_address'].addressInput = 'ciao'
      // }
    } catch (error) {
      console.log('err: ', error)
      error.response ? this.error = error.response.data.message : this.error = error + '! Ricarica la pagina'
    } finally {
      this.loading = false
    }
  },
  computed: {
    getOrari: function() {
      return {apertura: this.orari_apertura, consegna: this.orari_consegna, chiamata: this.orari_chiamata}
    }
  },
  methods: {
    setLoading: function (loading) {
      this.loading = loading
    },
    fillOptionsPayment (methodsPayment) {
      let obj = []
      for (let i = 0; i<methodsPayment.length; i++) {
        switch (methodsPayment[i].label) {
          case 'PayPal':
            //obj.push({ text: '<i class="fa fa-paypal"></i> PayPal', value: 'PayPal', enabled: methodsPayment[i].enabled, favorite: methodsPayment[i].favorite })
            obj.push({ text: 'PayPal', value: 'PayPal', enabled: methodsPayment[i].enabled, favorite: methodsPayment[i].favorite })
            break;
          case 'Credit Card':
            //obj.push({ text: '<i class="im im-icon-Credit-Card2"></i> Carta', value: 'Credit Card', enabled: methodsPayment[i].enabled, favorite: methodsPayment[i].favorite })
            obj.push({ text: 'Carta', value: 'Credit Card', enabled: methodsPayment[i].enabled, favorite: methodsPayment[i].favorite })
            break;
          case 'Cash':
            //obj.push({ text: '<i class="im im-icon-Money-2"></i> Contanti', value: 'Cash', enabled: methodsPayment[i].enabled, favorite: methodsPayment[i].favorite })
            obj.push({ text: 'Contanti', value: 'Cash', enabled: methodsPayment[i].enabled, favorite: methodsPayment[i].favorite })
            break;
          default:
            //obj.push({text: `<i class="sl sl-icon-settings"></i> ${methodsPayment[i].label} <i class="im im-icon-Remove custom_p_icon" style="margin-left: 10px; color: #f91942; cursor:pointer;" ref="remove_custom_payment"></i>`, value: methodsPayment[i].label, enabled: methodsPayment[i].enabled, favorite: methodsPayment[i].favorite})
            obj.push({text: `${methodsPayment[i].label}`, value: methodsPayment[i].label, enabled: methodsPayment[i].enabled, favorite: methodsPayment[i].favorite})
        }
        if (methodsPayment[i].enabled) {
          this.metodi_pagamento_supp.push(methodsPayment[i].label)
        }
        if (methodsPayment[i].favorite) {
          this.metodo_pagamento_preferito = methodsPayment[i].label
        }
      }
      return obj
    },
    fillOptionsDelivery (methodsDelivery) {
      let obj = []
      for (let i = 0; i<methodsDelivery.length; i++) {
        switch (methodsDelivery[i].label) {
          case 'In Negozio':
            //obj.push({ text: '<i class="im im-icon-Home-5"></i> In Negozio', value: 'In Negozio' })
            obj.push({ text: 'In Negozio', value: 'In Negozio' })
            break;
          case 'A domicilio':
            //obj.push({ text: '<i class="im im-icon-Shop-2"></i> A domicilio', value: 'A domicilio' })
            obj.push({ text: 'A domicilio', value: 'A domicilio' })
            break;
          default:
            //obj.push({text: `<i class="sl sl-icon-settings"></i> ${methodsDelivery[i].label} <i class="im im-icon-Remove custom_p_icon" style="margin-left: 10px; color: #f91942; cursor:pointer;" ref="remove_custom_payment"></i>`, value: methodsDelivery[i].label})
            obj.push({text: `${methodsDelivery[i].label}`, value: methodsDelivery[i].label})
        }
        if (methodsDelivery[i].enabled) {
          this.metodi_consegna_supp.push(methodsDelivery[i].label)
        }
        if (methodsDelivery[i].favorite) {
          this.metodo_consegna_preferito = methodsDelivery[i].label
        }
      }
      return obj
    },
    add_payment_method() {
      if (this.pagamento_custom.input !== '') {
        this.options_metodi_pagamento_supp.push({text: `${this.pagamento_custom.input}`, value: this.pagamento_custom.input})
        this.metodi_pagamento_supp.push(this.pagamento_custom.input)
        this.pagamento_custom.input = '';
        this.pagamento_custom.flag = false;
        this.pagamento_custom.error = null
      } else {
        this.pagamento_custom.error = true;
      }
    },
    add_consegna_method() {
      if (this.consegna_custom.input !== '') {
        this.options_consegna_pagamento_supp.push({text: `${this.consegna_custom.input}`, value: this.consegna_custom.input})
        this.metodi_consegna_supp.push(this.consegna_custom.input)
        this.consegna_custom.input = '';
        this.consegna_custom.flag = false;
        this.consegna_custom.error = null
      } else {
        this.consegna_custom.error = true;
      }
    },
    remove_payment_method(item) {
      console.log('item: ', item);
      if (this.metodi_pagamento_supp.includes(item)) {
        this.metodi_pagamento_supp=this.metodi_pagamento_supp.filter(el => el !== item)
      }
      this.options_metodi_pagamento_supp = this.options_metodi_pagamento_supp.filter((el) => el.text !== item)
      console.log(this.options_metodi_pagamento_supp)
      return
    },
    remove_delivery_method(item) {
      console.log('item: ', item);
      if (this.metodi_consegna_supp.includes(item)) {
        this.metodi_consegna_supp=this.metodi_consegna_supp.filter(el => el !== item)
      }
      this.options_consegna_pagamento_supp = this.options_consegna_pagamento_supp.filter((el) => el.text !== item)
      console.log(this.options_consegna_pagamento_supp)
      return
    },
    getAppThirdMeta(app) {
      let arr = []
      for(let i = 0; i<app.length; i++) {
        let app_1 = this.options_app_third_part.filter(el => el.value === app[i])[0]
        arr.push(app_1)
      }
      console.log('arr1: ', arr)
      arr = arr.map(el => {
        let obj = {}
        obj.name = el.name
        obj.value = {}
        obj.value.admin_url = el.metas.conf.value.admin_url
        obj.value.user_url = el.metas.conf.value.user_url
        obj.value.azienda = el.metas.conf.value.azienda
        obj.value.riferimento = el.metas.conf.value.riferimento
        obj.value.cellulare = el.metas.conf.value.cellulare
        obj.value.whatsapp = el.metas.conf.value.whatsapp
        obj.value.telefono = el.metas.conf.value.telefono
        obj.value.tipologia = el.metas.conf.value.tipologia
        obj.value.descrizione = el.metas.conf.value.descrizione
        obj.value.descrizione_estesa = el.metas.conf.value.descrizione_estesa
        obj.value.autore = el.metas.conf.value.autore
        obj.value.web_autore = el.metas.conf.value.web_autore
        obj.value.supporto = el.metas.conf.value.supporto
        obj.value._label = el.metas.conf.value._label
        obj.value.base_admin_url = el.metas.conf.value.base_admin_url
        obj.value.base_user_url = el.metas.conf.value.base_user_url
        // obj.description = el.description
        // obj.id = el.value
        // obj.user_id = el.user_id
        // obj.gallery = el.gallery
        // obj.logo = el.logo
        // obj.metas = el.metas
        return obj
      })
      console.log('arr: ', arr)
      return arr
    },
    async save () {
      this.loading = true
      let paymentsValue = []
      for (let i = 0; i<this.options_metodi_pagamento_supp.length; i++) {
        let obj = {}
        obj.label = this.options_metodi_pagamento_supp[i].value
        obj.enabled = this.metodi_pagamento_supp.includes(this.options_metodi_pagamento_supp[i].value) ? true : false
        obj.favorite = this.metodo_pagamento_preferito === this.options_metodi_pagamento_supp[i].value ? true : false
        paymentsValue.push(obj)
      }
      console.log(paymentsValue)

      let deliveryValue = []
      for (let i = 0; i<this.options_consegna_pagamento_supp.length; i++) {
        let obj = {}
        obj.label = this.options_consegna_pagamento_supp[i].value
        obj.enabled = this.metodi_consegna_supp.includes(this.options_consegna_pagamento_supp[i].value) ? true : false
        obj.favorite = this.metodo_consegna_preferito === this.options_consegna_pagamento_supp[i].value ? true : false
        deliveryValue.push(obj)
      }
      console.log(deliveryValue)
      if (this.contacts.web_url) {
        if (!this.contacts.web_url.includes('http://') && !this.contacts.web_url.includes('https://')) {
          this.contacts.web_url = 'http://' + this.contacts.web_url
        }
      }
      if (this.contacts.facebook_url) {
        if (!this.contacts.facebook_url.includes('http://') && !this.contacts.facebook_url.includes('https://')) {
          this.contacts.facebook_url = 'https://' + this.contacts.facebook_url
        }
      }
      if (this.contacts.instagram_url) {
        if (!this.contacts.instagram_url.includes('http://') && !this.contacts.instagram_url.includes('https://')) {
          this.contacts.instagram_url = 'https://' + this.contacts.instagram_url
        }
      }
      try {
        // Gli oggetti vanno presi dallo store.
        const ris = await ShopService.saveMetaShop(
          {
            timetable: /*this.createObject('timetable',this.$refs['opening'].obj.json),*/ this.$refs['opening'].obj.json,
            contact_timetable: /*this.createObject('contact_timetable',this.$refs['contact'].obj.json),*/ this.$refs['contact'].obj.json,
            delivery_timetable: /*this.createObject('delivery_timetable',this.$refs['delivery'].obj.json),*/ this.$refs['delivery'].obj.json,
            payment_types: /*this.createObject('payment_types',paymentsValue),*/paymentsValue,
            delivery_types: /*this.createObject('delivery_types',deliveryValue),*/deliveryValue,
            contacts: /*this.createObject('contacts',this.contacts)*/this.contacts,
            thrd_part_apps: this.getAppThirdMeta(this.app_third_part)
          },
          this.$store.state.user.user.myworkshops[this.$route.query.shop].id)
        if (ris) {
          this.$store.dispatch('shop_meta/setShopMeta', ris.data)
          if (ris.data.errors){
            if (!(Object.keys(ris.data.errors).length === 0 && ris.data.errors.constructor === Object)) {
              console.log("c'è l'errore")
              // Sarà necessario un forEach per ogni app!
              // this.app_third_part = this.app_third_part.filter(el => el!== 'myfood_listino')
              // for (let i = 0; i<ris.data.errors.thrd_part_apps['myfood_listino'].length; i++) {
              //   this.error_third_app.push(ris.data.errors.thrd_part_apps['myfood_listino'][i])
              // }
              let arr_value_error = []
              Object.keys(ris.data.errors.thrd_part_apps).forEach(el => {
                var value = el;
                console.log('value', value)
                arr_value_error.push(value)
                this.app_third_part = this.app_third_part.filter(el => el!== value)
                for (let i = 0; i<ris.data.errors.thrd_part_apps[value].length; i++) {
                  this.error_third_app.push(ris.data.errors.thrd_part_apps[value][i])
                }
              })
              setTimeout(()=>{
                this.error_third_app = []
              }, 3000)
            } 
          }
          console.log('address: ', this.$refs )
          if (!this.$refs['shop_address'].obj || !this.$refs['legal_address'].obj) {
            if (!this.$refs['shop_address'].obj && !this.$refs['legal_address'].obj && !this.$refs['shop_address'].stop && !this.$refs['legal_address'].stop && (this.$refs['legal_address'].addressInput !== '' && this.$refs['legal_address'].addressInput !== null ) && (this.$refs['shop_address'].addressInput !== '' && this.$refs['shop_address'].addressInput !== null ))
              throw 'Errore: specifica degli indirizzi validi!'
            else if (!this.$refs['shop_address'].obj && !this.$refs['shop_address'].stop && (this.$refs['shop_address'].addressInput !== '' && this.$refs['shop_address'].addressInput !== null ))
              throw 'Errore: specifica un indirizzo del negozio valido!'
            else if (!this.$refs['legal_address'].obj && !this.$refs['legal_address'].stop && (this.$refs['legal_address'].addressInput !== '' && this.$refs['legal_address'].addressInput !== null ))
              throw 'Errore: specifica un indirizzo della sede legale valido!'
          }
          try {
            let addresses = []
            if (!this.$refs['shop_address'].stop  && (this.$refs['shop_address'].addressInput !== '' && this.$refs['shop_address'].addressInput !== null )) {
              const addressShopRis = await AddressService.saveAddress(this.$refs['shop_address'].obj, this.$store.state.shop_address.address.filter((el) => el.label === 'negozio')[0].id) // cambia id
              this.$store.dispatch('shop_address/setShopAddress', addressShopRis.data)
            }
            if (!this.$refs['legal_address'].stop  && (this.$refs['legal_address'].addressInput !== '' && this.$refs['legal_address'].addressInput !== null )) {
              const addresslegalRis = await AddressService.saveAddress(this.$refs['legal_address'].obj, this.$store.state.shop_address.address.filter((el) => el.label === 'ragione_sociale')[0].id) //cambia id
              this.$store.dispatch('shop_address/setLegalAddress', addresslegalRis.data)
            }
            // if (addressShopRis && addresslegalRis) {
            
            // addresses.push(addressShopRis.data)
            // addresses.push(addresslegalRis.data)
            
            // if (!this.$refs['shop_address'].stop || !this.$refs['legal_address'].stop) {
            //   this.$store.dispatch('shop_address/setAddress', addresses)
            // }
            this.error = null
            this.success = true
            console.log('shop meta ris:', ris)
            setTimeout(() => {
              this.success = false
              //location.reload()
            }, 2000)
            // }
          } catch(error) {
            this.success = false
            console.log(error)
            error.response ? this.error = error.response.data.message : this.error = error
            setTimeout(() => {
              this.error = null
            }, 3000)
          } finally {
            this.loading = false
          }
        }
      } catch (error) {
        this.success = false
        console.log(error)
        error.response ? this.error = error.response.data.message : this.error = error
        setTimeout(() => {
          this.error = null
        }, 3000)
      } finally {
        this.loading = false
      }
    },
    createObject(meta, obj) {
      switch(meta) {
        case 'timetable':
          let timetable = this.$store.state.shop_meta.shop_meta.timetable
          timetable.value = obj
          console.log('obj timetable: ', timetable)
          return timetable
          break;
        case 'contact_timetable':
          let contact_timetable = this.$store.state.shop_meta.shop_meta.contact_timetable
          contact_timetable.value = obj
          console.log('obj contact_timetable: ', contact_timetable)
          return contact_timetable
          break;
        case 'delivery_timetable':
          let delivery_timetable = this.$store.state.shop_meta.shop_meta.delivery_timetable
          delivery_timetable.value = obj
          console.log('obj delivery_timetable: ', delivery_timetable)
          return delivery_timetable
          break;
        case 'payment_types':
          let payment_types = this.$store.state.shop_meta.shop_meta.payment_types
          payment_types.value = obj
          console.log('obj payment_types: ', payment_types)
          return payment_types
          break;
        case 'delivery_types':
          let delivery_types = this.$store.state.shop_meta.shop_meta.delivery_types
          delivery_types.value = obj
          console.log('obj delivery_types: ', delivery_types)
          return delivery_types
          break;
        case 'contacts':
          let contacts = this.$store.state.shop_meta.shop_meta.contacts
          contacts.value = obj
          console.log('obj contacts: ', contacts)
          return contacts
          break;
        default:
          return null
      }
    }
  }
}
</script>

<style scoped>
  .add_custom div, .cancel, .add_payment_icon {
    cursor: pointer;
    transition: .3s;
  }
  .add_custom div:hover, .cancel:hover {
    color: #f91942;
  }
  .tutorial_orari:hover {
    color: #f91942;
    transition: .3s;
  }
  .add_payment_icon:hover {
    color: green;
  }
  .margin_checkbox div{
    margin-top: 10px;
  }
  .input_custom {
    height: 35px;
    line-height: 35px;
    width: 78% !important;
  }
  .col-xs-2, .col-xs-4 {
    padding: 0px 0px;
  }
</style>

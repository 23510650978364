<template>
  <div class="register">
		<div class="text-center" v-if="loading" style="position:absolute; top: 40%; left: 43%; height: 100px !importan;">
      <div class="loader_login"></div>
    </div>
    <div :class="loading ? 'fs-container opacity' : ''">
      <p class="form-row form-row-wide">
        <label for="name">Nome:
          <i class="im im-icon-Male"></i>
          <input v-model="name" type="text" :disabled="success" :class="error_name || be_error_name ? 'input-text is-invalid' : validationName() ? 'input-text is-valid' : 'input-text' " name="name" id="name"/>
        </label>
        <span class="error" v-if="error_name">Inserisci il nome</span>
        <span class="error" v-if="be_error_name">{{ be_error_name }}</span>
      </p>

      <p class="form-row form-row-wide">
        <label for="surname">Cognome:
          <i class="im im-icon-Male-2"></i>
          <input v-model="surname" type="text" :disabled="success" :class="error_surname || be_error_surname ? 'input-text is-invalid' : validationSurname() ? 'input-text is-valid' : 'input-text' " name="surname" id="surname" />
        </label>
        <span class="error" v-if="error_surname">Inserisci il cognome</span>
        <span class="error" v-if="be_error_surname">{{ be_error_surname }}</span>
      </p>
        
      <p class="form-row form-row-wide">
        <label for="email2">Indirizzo Email:
          <i class="im im-icon-Mail"></i>
          <input v-model="email" type="text" :disabled="success" :class="error_email || be_error_email ? 'input-text is-invalid' : validationEmail() ? 'input-text is-valid' : 'input-text' " name="email" id="email2" />
        </label>
        <span class="error" v-if="error_email">Inserisci l'email <i>(valida)</i></span>
        <span class="error" v-if="be_error_email">{{ be_error_email }}</span>
      </p>

      <p class="form-row form-row-wide">
        <label for="password1">Password:
          <i class="im im-icon-Lock-2"></i>
          <input v-model="password" type="password" :disabled="success" :class="error_password || be_error_password ? 'input-text is-invalid' : validationPassword() ? 'input-text is-valid' : 'input-text' " name="password1" id="password1"/>
        </label>
        <span class="error" v-if="error_password">Inserisci la password <i>(almeno 8 caratteri)</i></span>
        <span class="error" v-if="be_error_password">{{ be_error_password }}</span>
      </p>

      <p class="form-row form-row-wide">
        <label for="conferma_password">Conferma Passowrd:
          <i class="im im-icon-Lock-2"></i>
          <input v-model="conferma_password" :disabled="success" :class="error_conferma_password || be_error_password ? 'input-text is-invalid' : validationConfermaPassword() ? 'input-text is-valid' : 'input-text' " type="password" name="conferma_password" id="conferma_password"/>
        </label>
        <span class="error" v-if="error_conferma_password">Inserisci di nuovo password <i>(almeno 8 caratteri e uguale a password)</i></span>
        <span class="error" v-if="be_error_conferma_password">{{ be_error_conferma_password }}</span>
      </p>

      <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
        <span style="color:red;" class="col-sm-12 text-center">
          <span v-html="error"></span>
        </span>
      </div>
      <div class="col-sm-12">
        <span style="color:green;" class="col-sm-12 text-center">
          {{success? 'Registrazione avvenuto con successo' : ''}}
        </span>
        <span style="color:green;" class="col-sm-12 text-center">
          {{success? "Controlla l'email e conferma la registrazione!" : ''}}
        </span>
      </div>
      <div class="form-row">
        <div class="checkboxes margin-top-10">
          <input id="remember-me" type="checkbox" v-model="privacy" name="check">
          <label for="remember-me">Accetto l'informativa sulla <a href="https://www.iubenda.com/privacy-policy/82105399" download class="privacy" target="_blank">privacy</a></label>
          <span class="error" v-if="error_privacy && !privacy">Accetta la privacy.</span>
        </div>
        <button :class="checkForm() ? 'button border fw margin-top-10' : 'button border fw margin-top-10'" @click="registerCustomer()"> Registrati </button>
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
export default {
  name: 'register-customer',
  data () {
    return {
      email: '',
      password: '',
      conferma_password: '',
      name: '',
      surname: '',
      success: false,
      error: null,
      error_email: null,
      error_password: null,
      error_conferma_password: null,
      error_name: null,
      error_surname: null,
      error_privacy: null,
      be_error_email: null,
      be_error_password: null,
      be_error_conferma_password: null,
      be_error_name: null,
      be_error_surname: null,
      privacy: false,
      loading: false,
      error_psw_from_db: "The password provided failed to match the following rules:\n              <br>\n              1. It must contain ONLY the following characters: lower case, upper case, numerics.\n              <br>\n              2. It must be at least 8 characters in length and not greater than 32 characters in length.\n            "
    }
  },
  methods: {
    validationEmail () {
      if (this.email.length > 3 && /.+@.+/.test(this.email)) {
        this.error_email = null
        return true
      }
      return false
    },
    validationPassword () {
      if (this.password.length > 7) {
        this.error_password = null
        return true
      }
      return false
    },
    validationConfermaPassword () {
      if(this.password === this.conferma_password && this.conferma_password.length > 7) {
        this.error_conferma_password = null
        return true
      }
      return false
    },
    validationName() {
      if (this.name != '') {
        this.error_name = null
        return true
      }
      return false
    },
    validationSurname() {
      if (this.surname != '') {
        this.error_surname = null
        return true
      }
      return false
    },
    checkForm() {
      return (
        this.validationName() &&
        this.validationSurname() &&
        this.validationEmail() &&
        this.validationPassword() &&
        this.validationConfermaPassword() &&
        this.privacy
      )
    },
    checkValidationPreSave() {
      if (!this.checkForm()) {
        this.error = 'Errore! Controlla tutti i campi'
        if (!this.validationEmail()) {
          this.error_email = true
        }
        if (!this.validationPassword()) {
          this.error_password = true
        }
        if (!this.validationConfermaPassword()) {
          this.error_conferma_password = true
        }
        if (!this.validationName()) {
          this.error_name = true
        }
        if (!this.validationSurname()) {
          this.error_surname = true
        }
        if (!this.privacy) {
          this.error_privacy = true
        }

        return false
      } else {
        return true
      }
    },
    resetError() {
      this.error_email = null,
      this.error_password = null,
      this.error_conferma_password = null,
      this.error_name = null,
      this.error_surname = null,
      this.error_privacy = null,
      this.be_error_email = null,
      this.be_error_password = null,
      this.be_error_conferma_password = null,
      this.be_error_name = null,
      this.be_error_surname = null,
      this.error = null
    },
    async registerCustomer() {
      this.loading = true
      this.resetError()
      if(!this.checkValidationPreSave()) {
        this.loading = false
        return 0
      } else {
        try {
          this.error = null
          const response = await AuthenticationService.register({
            email: this.email,
            password: this.password,
            first_name: this.name,
            last_name: this.surname,
            role_id: 3
          })
          if (response) {
            console.log(response)
            //this.$store.dispatch('setToken', response.data.token)
            // this.$store.dispatch('user/setUser', response.data.user)
            let risLogin = /*await this.login()*/ 1
            if (risLogin === 1) {
              this.success = true
              setTimeout(() => {
                location.reload();
                //window.location.href = '/dashboard-company-my-profile'
              }, 3000)
            } else {
              this.error = 'Errore durante la registrazione!'
              setTimeout(() => {
                this.error = null
              }, 3000)
            }
          }
        } catch (error) {
          if (error.response) {
            if (error.response.data.message === 'dati non validi') {
              Object.keys(error.response.data.errors).forEach(el2 => {
                if (el2 === 'first_name') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_name = true
                }
                if (el2 === 'last_name') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_surname = true
                }
                if (el2 === 'email') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_email = true
                }
                if (el2 === 'password') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_password = error.response.data.errors[el2].message
                  this.be_error_conferma_password = error.response.data.errors[el2].message
                }
                if (el2 === 'model') {
                  this.error = error.response.data.errors[el2].message
                }
                if (el2 === 'not valid password') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_password = error.response.data.errors[el2].message
                  this.be_error_conferma_password = error.response.data.errors[el2].message
                }
              })
            } else {
              if (error.response.data.message === `Username "${this.email}" is already taken`) {
                this.error = 'Questa email è già in uso'
                this.be_error_email = 'Questa email è già in uso'
              } if (error.response.data.message === `email "${this.email}" is already taken`) {
                this.error = 'Questa email è già in uso'
                this.be_error_email = 'Questa email è già in uso'
              } if (error.response.data.message === `l'email "${this.email}" è già registrata`) {
                this.error = 'Questa email è già in uso'
                this.be_error_email = 'Questa email è già in uso'
              } if (error.response.data.error === this.error_psw_from_db) {
                this.error = 'La password deve essere minimo di 8 caratteri e non può contenere caratteri speciali'
                this.be_error_password = 'La password deve essere minimo di 8 caratteri e non può contenere caratteri speciali'
                this.be_error_conferma_password = 'La password deve essere minimo di 8 caratteri e non può contenere caratteri speciali'
              } else {
                this.success = false
                this.error = error.response.data.message || error.response.data.error
              }
            }
            
          } else {
            this.error = error
          }
          setTimeout(() => {
            // this.error = null
          }, 3000)
        } finally {
          this.loading = false
        }
      }
    },
    async login() {
      try {
        this.error = null
        const response = await AuthenticationService.login({
          email: this.email,
          password: this.password
        })
        this.success = true
        console.log('Login res: ', response)
        // window.OneSignal = window.OneSignal || [];
        // window.OneSignal.push(() => {
        //   window.OneSignal.init({
        //     appId: "20d3b7b0-85e1-49d7-8f9a-1e74f587be12",
        //     allowLocalhostAsSecureOrigin: true,
        //   });
        //   window.OneSignal.setExternalUserId(response.data.id);
        //   window.OneSignal.getUserId( function(userId) {
        //     console.log('player_id of the subscribed user is : ' + userId);
        //     // Make a POST call to your server with the user ID        
        //   });
        // });
        this.$store.dispatch('user/setToken', response.data.token) // serve?
        this.$store.dispatch('user/setUser', response.data)
        this.$store.dispatch('user_address/setAddress', response.data.addresses)
        this.$store.dispatch('user_meta/setUserMeta', response.data.metas)
        this.$store.dispatch('my_shops/setMyShops', response.data.myshops)
        return 1
      } catch (error) {
        console.log(error)
        this.error = 'Errore in login'
        return -1
      }
    }
  }
}
</script>

<style scoped>
  .error {
    color: #f91942;
  }
  .is-invalid {
    color: #f91942 !important;
    border-color: #f91942 !important;
  }
  .is-valid {
    /* color: green !important; */
    border-color: green !important;
    /* background: #e6e6e6 !important; */
  }
  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
  }
  .privacy {
    color: #333;
    cursor: pointer;
    transition: .3s;
  }
  .privacy:hover {
    color: #f91942 !important;
    transition: .3s;
  }
  .loader_login {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #fa3256;
    width: 70px;
    height: 70px;
    margin: auto;
    margin-top: 250px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  /* .register {
    height: 500px;
    overflow-y: auto;
  } */
</style>
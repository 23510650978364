<template>
<div>
  <div class="text-center" v-if="loading" style="position:absolute; top: 5%; left: 32%; height: 500px;">
    <div class="loader"></div>
  </div>
  <div :class="loading ? 'dashboard-list-box margin-top-0 fs-container opacity' : 'dashboard-list-box margin-top-0'">
    <h4 class="gray">{{ title }}</h4>
    <div class="dashboard-list-box-static">

      <!-- Change Password -->
      <div class="my-profile">
        <!-- <label class="margin-top-0">Password Corrente</label>
        <input v-model="current_password" type="password" :class="error ? 'input-text is-invalid' : validationCurrentPassword() ? 'input-text is-valid' : 'input-text' "> -->

        <label>Nuovo Password <span style="font-size:13px;"><i>(almeno 8 caratteri)</i></span></label>
        <input maxlength="128" v-model="new_password" type="password" :class="error ? 'input-text is-invalid' : validationNewPassword() ? 'input-text is-valid' : 'input-text' ">

        <label>Conferma nuova Password <span style="font-size:13px;"><i>(almeno 8 caratteri)</i></span></label>
        <input maxlength="128" v-model="conferma_password" type="password" :class="error ? 'input-text is-invalid' : validationConfermaPassword() ? 'input-text is-valid' : 'input-text' ">

        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        
        <div class="col-sm-12" v-if="success" style="border: 1px solid green; padding: 20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span v-html="success ? 'Password Aggiornata!' : ''"></span>
          </span>
        </div>

        
        <button :class="checkForm() ? 'button margin-top-15' : 'button margin-top-15 disabled'" :disabled="!checkForm()" @click="changePassword()">Cambia Password</button>

      </div>

    </div>
  </div>
</div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
export default {
  name: 'change-password',
  props: ['title', 'type', 'token'],
  data () {
    return {
      loading: false,
      current_password: '',
      new_password: '',
      conferma_password: '',
      success: false,
      error: null
    }
  },
  methods: {
    // validationCurrentPassword () {
    //     if (this.current_password.length > 3)
    //         return true
    //     return false
    // },
    validationNewPassword () {
        if (this.new_password.length > 7)
            return true
        return false
    },
    validationConfermaPassword () {
        if(this.new_password === this.conferma_password && this.conferma_password.length > 7)
            return true
        return false
    },
    checkForm() {
      return (
        // this.validationCurrentPassword() &&
        this.validationNewPassword() &&
        this.validationConfermaPassword()
      )
    },
    async changePassword() {
      this.loading = true
      if (this.type === 'update') {
        try {
          let ris = await AuthenticationService.updatePassword(this.$store.state.user.user.id, {new_password: this.new_password})
          if (ris) {
            console.log('password cambiata')
            this.success = true

            // CI VORRA' UNA DISPATCH IN BASE A COSA TORNA

            setTimeout(() => {
              // location.reload();
              this.new_password = ''
              this.conferma_password= ''
              this.success=false
            }, 2000)
          }
        } catch(error) {
          console.log(error)
          error.response ? this.error = error.response.data.message : this.error = error
          if(!this.error) {
            this.error="Si è verificato un errore nell'aggiornamento della password. Contattare l'assistenza tecnica!"
          }
          setTimeout(() => {
            this.error = null
          }, 3000)
        } finally {
          this.loading = false
        }
      } else if (this.type === 'reset') {
        try {
          let ris = await AuthenticationService.resetPassword(this.token, {new_password: this.new_password})
          if (ris) {
            console.log('password resettata')
            this.success = true

            // QUA NO DISPATCH

            setTimeout(() => {
              // location.reload();
              this.new_password = ''
              this.conferma_password= ''
              this.success=false
              this.$router.push({
                path: '/'
              })
            }, 2000)
          }
        } catch(error) {
          console.log(error)
          error.response ? this.error = error.response.data.message : this.error = error
          if(!this.error) {
            this.error="Si è verificato un errore nel reset della password. Contattare l'assistenza tecnica!"
          }
          setTimeout(() => {
            this.error = null
          }, 3000)
        } finally {
          this.loading = false
        }
      } else {
        this.loading = false
        this.error = 'Nessun type riconosciuto!'
        setTimeout(() => {
          this.error = null
        }, 3000)
      }
    }
  }
}
</script>

<style scoped>
  .is-invalid {
    color: red !important;
    border-color: red !important;
  }
  .is-valid {
    /* color: green !important; */
    border-color: green !important;
    /* background: #e6e6e6 !important; */
  }
  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
    cursor: not-allowed;
  }
</style>
// la funzione principale è main
// bisogna passarle la variabile placeRsultData restituito da vue-google-autocomplete
function _find_attribute( address, tags, key = null ) {
  if ( typeof tags === 'string' ) { tags = [ tags ]; }
  var res = null;
  for ( var i = 0; i < address.address_components.length; i++ ) {
      var cmp = address.address_components[ i ];
      var found = true;
      if ( cmp.hasOwnProperty('types') ) {
          cmp.types.map( x => { if ( tags.includes( x ) === false ) { found = false; } } );
      }
      if ( found === true ) {
          if ( key === null ) {
              res = {
                  long_name : cmp.long_name,
                  short_name: cmp.short_name,
              };
          } else {
              res = cmp[ key ];
          }
          break;
      }
  }
  return res;
}
module.exports = {

  main(address) {

    // var address = il parametro placeResultData restituito da vue-google-autocomplete

    var myesc = {};
    if ( address.types.includes('street_address') ) {
        myesc.type = 'street_address';
    } else if ( address.types.includes('route') ) {
        myesc.type = 'route';
    } else {
        myesc.type = 'unknown';
    }
    myesc.cap       = _find_attribute(address, 'postal_code', 'long_name');
    myesc.country   = _find_attribute(address, ['country','political'], 'long_name');
    myesc.regione   = _find_attribute(address, ['administrative_area_level_1','political'], 'long_name');
    myesc.citta     = _find_attribute(address, ['administrative_area_level_3','political'], 'long_name');
    myesc.provincia = _find_attribute(address, ['administrative_area_level_2','political'], 'short_name');
    myesc.localita  = _find_attribute(address, ['locality','political'], 'long_name');
    myesc.strada    = _find_attribute(address, 'route', 'long_name');
    myesc.n_civ     = _find_attribute(address, 'street_number', 'long_name');
    myesc.geodata   = address;

    return myesc;

  }
}
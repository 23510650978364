//////////////////////////////////////////////////

var moment = require('moment-timezone');

class orari {

    constructor( obj = [] ) {
        this.items = [];
        if ( obj.length > 0 ) {
            obj.map( x => { this.items.push( new time_slot( x, this.items.length ) ); } );
        }
    }

    /// getters ////////////////////////////

    get days() {
        return {
            '1': { long_name: 'Lunedì',    short_name: 'Lu', is_weekend: false },
            '2': { long_name: 'Martedì',   short_name: 'Ma', is_weekend: false },
            '3': { long_name: 'Mercoledì', short_name: 'Me', is_weekend: false },
            '4': { long_name: 'Giovedì',   short_name: 'Gi', is_weekend: false },
            '5': { long_name: 'Venerdì',   short_name: 'Ve', is_weekend: false },
            '6': { long_name: 'Sabato',    short_name: 'Sa', is_weekend: true  },
            '7': { long_name: 'Domenica',  short_name: 'Do', is_weekend: true  },
        };
    }

    get empty_days() {
        var used_days_index = this.used_days_index;
        var empty_days = {};
        for ( var i = 1; i < 8; i++ ) {
            if ( used_days_index.includes( i ) === false ) { empty_days[ i ] = this.days[ String(i) ]; }
        }
        return empty_days;
    }

    get used_days_index() {
        var used_days = [];
        for ( var i = 0; i < this.items.length; i++ ) {
            var used_days = used_days.concat( this.items[ i ].days );
        }
        return used_days;
    }

    get json() {
        var obj = [];
        this.items.map( x => { if ( x.is_valid ) { obj.push( x.json ) }; } );
        return obj;
    }

    get string() {
        return JSON.stringify( this.items.map( x => x.json ) );
    }

    /// public methods /////////////////////

    add_day( item_index, day_index ) {
        this.items[ item_index ].add_day( day_index );
    }

    remove_day( item_index, day_index ) {
        this.items[ item_index ].remove_day( day_index );
    }

    remove_time( item_index, time_index ) {
        this.items[ item_index ].remove_time( time_index );
    }

    add_time( item_index, time ) {
        this.items[ item_index ].add_time( time );
    }

    add_item( obj = { days: [], times: [] } ) {
        if ( this._areCommonElements( this.used_days_index, obj.days ) === false ) {
            var timeslot = new time_slot( obj, this.items.length );
            this.items.push( timeslot );
            var res = timeslot.json;
            res._index = this.items.length - 1;
            return res;
        }
        throw 'la lista di giorni indicata non è valida';
    }

    delete_item( item_index ) {
        if ( this.items[ item_index ] ) {
            delete this.items[ item_index ];
            this.items = this.items.filter(val => val);
            for ( var i = 0; i < this.items.length; i++ ) { this.items[ i ]._index = i; }
            return true;
        }
        return false;
    }

    is_open( input_time = null, input_day_of_week = null ) { // inputs: HH:mm, 1..7 (Dom: 7)
        var day_of_week = input_day_of_week === null ? moment().format('E') : input_day_of_week;
        var time = input_time === null ? moment() : moment( input_time + ` ${ day_of_week }`, 'HH:mm E' );
        for ( var i = 0; i < this.items.length; i++ ) {
            if ( this.items[ i ].check_day( day_of_week ) ) {
                return this.items[ i ].check_time( time );
            }
        }
        return false;
    }

    /// static methods /////////////////////

    static get_days() {
        var obj = new orari();
        return obj.days;
    }

    /// private methods ////////////////////

    _areCommonElements(arr1, arr2) { // verifica che la lista di giorni proposta (arr2) non contenga giorni già impegnati (arr1)
        const [shortArr, longArr] = (arr1.length < arr2.length) ? [arr1, arr2] : [arr2, arr1];
        const longArrSet = new Set(longArr);
        return shortArr.some(el => longArrSet.has(el));
    };

}

class time_slot {

    constructor( obj = { days: [], times: [] }, index = 0 ) {
        this.days   = obj.days;
        this.times  = obj.times;
        for ( var i = 0; i < this.times.length; i++ ) {
            this.times[ i ]._index = i;
        }
        this._index = index
    }

    add_day( day_index ) {
        if ( this.days.includes( parseInt(day_index) ) === false ) { this.days.push( parseInt(day_index) ); }
    }

    remove_day( day_index ) {
        const index = this.days.indexOf( parseInt(day_index) );
        if (index > -1) {
            this.days.splice(index, 1);
        }
    }

    remove_time( time_index ) {
        var times = [];
        for ( var i = 0; i < this.times.length; i++ ) {
            if ( i !== time_index ) {
                var time    = this.times[ i ];
                time._index = times.length;
                times.push( time );
            }
        }
       this.times = times;
    }

    add_time( time ) {
        time._index = this.times.length;
        this.times.push( time );
    }

    check_day( day_index ) { return this.days.includes( day_index ); }

    check_time( time ) { // input: moment obj
        for ( var i = 0; i < this.times.length; i++ ) {
            var from = moment( this.times[ i ].from + ` ${ time.format('E') }`, 'HH:mm E' );
            var to   = moment( this.times[ i ].to   + ` ${ time.format('E') }`, 'HH:mm E' );
            if ( time.isBetween(from, to) ) { return true; }
        }
        return false;
    }

    get json() {
        return { days: this.days, times: this.times, _index: this._index };
    }

    get is_valid() {
        return this.times.length > 0 && this.days.length > 0;
    }

}

//module.exports = orari;
export default orari;


<template>
  <!-- Header Container
  ================================================== -->
  <div>
  <header id="header-container" :class="$route.path === '/shops-list' ? 'fixed fullwidth' : $route.path === '/shops-list' || $route.path === '/reset-password' ? 'fullwidth' : ''">

    <!-- Header -->
    <div id="header" :class="$route.path === '/shops-list' || $route.path === '/preview-myshop' || $route.path === '/shop' || $route.path === '/reset-password' ? 'not-sticky' : ''">
      <div class="container">
        
        <!-- Left Side Content -->
        <div class="left-side">
          
          <!-- Logo -->
          <div id="logo">
            <router-link to="/" v-if="$route.path === '/' || $route.path === '/home'"><img src="@/assets/images/Logo Vetrineonline.com.png" :data-sticky-logo="require('@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png')" alt=""></router-link>
            <router-link v-else to="/" ><img src="@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png" :data-sticky-logo="require('@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png')" alt=""></router-link>

            <!-- <router-link to="/" ><h4 style="color:#f91942;">Vetrine OnLine</h4></router-link> -->
          </div>
          

          <!-- Mobile Navigation -->
          <!-- <div class="mmenu-trigger">
            <button class="hamburger hamburger-collapse" type="button">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div> -->

          <!-- Main Navigation -->
          <nav id="navigation" class="style-1">
            <!-- icone social user non autenticato e su mobile-->
            <a class="hidecomputer" href="https://www.facebook.com/vetrineonline" target="_blank" v-if="!this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/face-icon2.png" alt="" style="width: 30px;margin-top:20px;margin-left:auto;"></a>
            <i style="margin-left:5px"></i>
            <a class="hidecomputer" href="https://www.instagram.com/vetrineonline/" target="_blank" v-if="!this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/instagram02.png" alt="" style="width: 30px;margin-top:20px;margin-left:auto;"></a>
            
            <ul id="responsive">

              <!-- <li><a class="current" href="#">Home</a>
                <ul>
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="index-2-airbnb.html">Home 2 (Airbnb)</a></li>
                  <li><a href="index-3.html">Home 3</a></li>
                  <li><a href="index-4.html">Home 4</a></li>
                  <li><a href="index-5.html">Home 5</a></li>
                  <li><a href="index-6.html">Home 6</a></li>
                </ul>
              </li> -->
              <!-- <li><a :class="$route.path === '/shops-list' ? 'current' : $route.path === '/preview-myshop' || $route.path === '/shop' || $route.path === '/confirm-registration' || $route.path === '/all-shops' ? 'to_dashboard_2' : ''" href="/shops-list">Cerca Negozi</a></li> -->
              <li><router-link  v-if="this.$store.state.user.isUserLoggedIn" :class="$route.path === '/shops-list' || $route.path === '/preview-myshop' || $route.path === '/shop' || $route.path === '/reset-password' || $route.path === '/confirm-registration' || $route.path === '/all-shops' ? 'to_dashboard_2' : ''" to="/dashboard-company-my-shop?shop=0">Dashboard</router-link></li>
              <!-- <li v-if="this.$store.state.user.user.role_id === 5"><router-link  v-if="this.$store.state.user.isUserLoggedIn" :class="$route.path === '/' ? 'current' : $route.path === '/shops-list' || $route.path === '/preview-myshop' ? 'to_dashboard_2' : ''" to="/preview-myshop">Preview mio Negozio</router-link></li> -->

              <!-- <li><a href="#">Listings</a>
                <ul>
                  <li><a href="#">List Layout</a>
                    <ul>
                      <li><a href="listings-list-with-sidebar.html">With Sidebar</a></li>
                      <li><a href="listings-list-full-width.html">Full Width</a></li>
                      <li><a href="listings-list-full-width-with-map.html">Full Width + Map</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Grid Layout</a>
                    <ul>
                      <li><a href="listings-grid-with-sidebar-1.html">With Sidebar 1</a></li>
                      <li><a href="listings-grid-with-sidebar-2.html">With Sidebar 2</a></li>
                      <li><a href="listings-grid-full-width.html">Full Width</a></li>
                      <li><a href="listings-grid-full-width-with-map.html">Full Width + Map</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Half Screen Map</a>
                    <ul>
                      <li><a href="listings-half-screen-map-list.html">List Layout</a></li>
                      <li><a href="listings-half-screen-map-grid-1.html">Grid Layout 1</a></li>
                      <li><a href="listings-half-screen-map-grid-2.html">Grid Layout 2</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Single Listings</a>
                    <ul>
                      <li><a href="listings-single-page.html">Single Listing 1</a></li>
                      <li><a href="listings-single-page-2.html">Single Listing 2</a></li>
                      <li><a href="listings-single-page-3.html">Single Listing 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Open Street Map</a>
                    <ul>
                      <li><a href="listings-half-screen-map-list-OpenStreetMap.html">Half Screen Map List Layout</a></li>
                      <li><a href="listings-half-screen-map-grid-1-OpenStreetMap.html">Half Screen Map Grid Layout 1</a></li>
                      <li><a href="listings-half-screen-map-grid-2-OpenStreetMap.html">Half Screen Map Grid Layout 2</a></li>
                      <li><a href="listings-list-full-width-with-map-OpenStreetMap.html">Full Width List</a></li>
                      <li><a href="listings-grid-full-width-with-map-OpenStreetMap.html">Full Width Grid</a></li>
                      <li><a href="listings-single-page-OpenStreetMap.html">Single Listing</a></li>
                    </ul>
                  </li>
                </ul>
              </li> -->

              <!-- <li><a href="#">User Panel</a>
                <ul>
                  <li><a href="dashboard.html">Dashboard</a></li>
                  <li><a href="dashboard-messages.html">Messages</a></li>
                  <li><a href="dashboard-bookings.html">Bookings</a></li>
                  <li><a href="dashboard-wallet.html">Wallet</a></li>
                  <li><a href="dashboard-my-listings.html">My Listings</a></li>
                  <li><a href="dashboard-reviews.html">Reviews</a></li>
                  <li><a href="dashboard-bookmarks.html">Bookmarks</a></li>
                  <li><a href="dashboard-add-listing.html">Add Listing</a></li>
                  <li><a href="dashboard-my-profile.html">My Profile</a></li>
                  <li><a href="dashboard-invoice.html">Invoice</a></li>
                </ul>
              </li> -->

              <!-- <li><a href="#">Pages</a>
                <div class="mega-menu mobile-styles three-columns">

                    <div class="mega-menu-section">
                      <ul>
                        <li class="mega-menu-headline">Pages #1</li>
                        <li><a href="pages-user-profile.html"><i class="sl sl-icon-user"></i> User Profile</a></li>
                        <li><a href="pages-booking.html"><i class="sl sl-icon-check"></i> Booking Page</a></li>
                        <li><a href="pages-add-listing.html"><i class="sl sl-icon-plus"></i> Add Listing</a></li>
                        <li><a href="pages-blog.html"><i class="sl sl-icon-docs"></i> Blog</a></li>
                      </ul>
                    </div>
      
                    <div class="mega-menu-section">
                      <ul>
                        <li class="mega-menu-headline">Pages #2</li>
                        <li><a href="pages-contact.html"><i class="sl sl-icon-envelope-open"></i> Contact</a></li>
                        <li><a href="pages-coming-soon.html"><i class="sl sl-icon-hourglass"></i> Coming Soon</a></li>
                        <li><a href="pages-404.html"><i class="sl sl-icon-close"></i> 404 Page</a></li>
                        <li><a href="pages-masonry-filtering.html"><i class="sl sl-icon-equalizer"></i> Masonry Filtering</a></li>
                      </ul>
                    </div>

                    <div class="mega-menu-section">
                      <ul>
                        <li class="mega-menu-headline">Other</li>
                        <li><a href="pages-elements.html"><i class="sl sl-icon-settings"></i> Elements</a></li>
                        <li><a href="pages-pricing-tables.html"><i class="sl sl-icon-tag"></i> Pricing Tables</a></li>
                        <li><a href="pages-typography.html"><i class="sl sl-icon-pencil"></i> Typography</a></li>
                        <li><a href="pages-icons.html"><i class="sl sl-icon-diamond"></i> Icons</a></li>
                      </ul>
                    </div>
                    
                </div>
              </li> -->
              
            </ul>
          </nav>
          <div class="clearfix"></div>
         
          <!-- Main Navigation / End -->
        </div>
        <!-- Left Side Content / End -->

        <!-- Right Side Content / End -->
        <div class="right-side">
          <div class="header-widget">
            <!-- User Menu -->
            <div class="user-menu" v-if="this.$store.state.user.isUserLoggedIn">
              <div class="user-name"><i class="sl sl-icon-user" style="margin-right:10px;"></i>Ciao, {{ this.$store.state.user.user.first_name }}!</div>
              <ul>
                <li><router-link to="/dashboard-company-my-shop?shop=0"><i class="sl sl-icon-settings"></i> Dashboard</router-link></li>
                <li v-if="this.$store.state.user.user.role_id === 5 && this.$store.state.shop.shop"><router-link :to="'/shop?id='+this.$store.state.shop.shop.id"><i class="im  im-icon-Shop"></i> Mio Negozio</router-link></li>
                <li><a @click="logout()"><i class="sl sl-icon-power"></i> Logout</a></li>
              </ul>
            </div>
            
            <!-- ICONE SOCIAL USER AUTENTICATO -->
            <a href="https://www.facebook.com/vetrineonline" target="_blank" v-if="this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/face-icon2.png" alt="" style="width: 25px;margin-top:10px;margin-left:25px"></a>
            <a href="https://www.instagram.com/vetrineonline/" target="_blank" v-if="this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/instagram02.png" alt="" style="width: 25px;margin-top:10px;margin-left:15px;margin-right:20px"></a>
            
            <a href="#sign-in-dialog" :class="$route.path === '/shops-list' || $route.path === '/preview-myshop' || $route.path === '/shop' || $route.path === '/reset-password' || $route.path === '/confirm-registration' || $route.path === '/all-shops' ? 'button sign-in sign-in_1 popup-with-zoom-anim custom_modal_log' : 'button sign-in popup-with-zoom-anim custom_modal_log'" v-if="!this.$store.state.user.isUserLoggedIn"><i class="sl sl-icon-login hidemobile"></i> Accedi</a>
            <a style="display: none" href="#small-dialog" :class="'button sign-in sign-in_1 popup-with-zoom-anim'" id="link-search" v-if="!this.$store.state.user.isUserLoggedIn"><i class="sl sl-icon-login hidemobile"></i> Cerca</a>
            <a href="#sign-in-dialog" :class="$route.path === '/shops-list' || $route.path === '/preview-myshop' || $route.path === '/shop' || $route.path === '/reset-password' || $route.path === '/confirm-registration' || $route.path === '/all-shops' ? 'button sign-in sign-in_1 popup-with-zoom-anim custom_modal_reg' : 'button sign-in popup-with-zoom-anim custom_modal_reg'" v-if="!this.$store.state.user.isUserLoggedIn"><i class="fa fa-sign-in hidemobile"></i> Registrazione</a>

            <!-- QUA NUOVE ICONE SOCIAL USER NON AUTENTICATO  -->
            <div style="display: inline; text-align:center" class="hidemobile">
              <a class="hidemobile" href="https://www.facebook.com/vetrineonline" target="_blank" v-if="!this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/face-icon2.png" alt="" style="width: 25px;margin-top:20px;margin-left:10px"></a>
              <a class="hidemobile" href="https://www.instagram.com/vetrineonline/" target="_blank" v-if="!this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/instagram02.png" alt="" style="width: 25px;margin-top:20px;margin-left:15px;"></a>
            </div>

          </div>
        </div>
        <!-- Right Side Content / End -->

            <!-- Sign In Popup -->
            <div id="sign-in-dialog" class="zoom-anim-dialog mfp-hide">

                <div class="small-dialog-header">
                    <h3>Sign In</h3>
                </div>

                <!--Tabs -->
                <div class="sign-in-form style-1">

                    <ul class="tabs-nav">
                        <li class=""><a id="a_log" href="#tab1">Accedi</a></li>
                        <li><a id="a_reg" href="#tab3">Registra Negoziante</a></li>
                        <li><a href="#tab2">Registra Utente</a></li>
                    </ul>

                    <div class="tabs-container alt">

                        <!-- Login -->
                        <login class="tab-content" id="tab1" style="display: none;"></login>

                        <!-- Register Customer -->
                        <register-customer class="tab-content" id="tab2" style="display: none;"></register-customer>

                        <!-- Register Company -->
                        <register-company class="tab-content" id="tab3" style="display: none;"></register-company>

                    </div>
                </div>
            </div>
            <!-- Sign In Popup / End -->

      </div>
    </div>
    <!-- Header / End -->

  </header>
  <div class="clearfix"></div>
  </div>
</template>

<script>

import Login from '@/components/Login'
import RegisterCustomer from '@/components/RegisterCustomer'
import RegisterCompany from '@/components/RegisterCompany'

export default {
  name: 'header',
  components: {
    Login,
    RegisterCustomer,
    RegisterCompany,
  },
  data() { return { windowWidth: window.innerWidth, cat_id: null }; },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      //console.log( this.windowWidth );
    });
  },
  computed: {
  },
  methods: {
    test_function( obj ) {
        //console.log( obj );
        this.cat_id = obj.cat_id;
    },
    logout () {
      console.log('logout')
      var role_id = this.$store.state.user.user.role_id
      this.$store.dispatch('user/setToken', null)
      this.$store.dispatch('user/setUser', null)
      this.$store.dispatch('shop/setShop', null)
      this.$store.dispatch('shop_meta/setShopMeta', null)
      this.$store.dispatch('shop_address/setAddress', null)
      this.$store.dispatch('user_address/setAddress', null)
      this.$store.dispatch('user_meta/setUserMeta', null)
      this.$store.dispatch('shop_images/setLogo', null)
      this.$store.dispatch('shop_images/setGallery', null)
      this.$store.dispatch('my_shops/setMyShops', [])
      if(role_id === 5) {
        this.$store.dispatch('presence_ws/setStatus', '')
      }
      // if (this.$route.fullPath !== '/') {
      //   this.$router.push({
      //     path: '/'
      //   })
      // }
      window.location.href = '/'
    },
  }
}
</script>

<style scoped>
  .sign-in:hover {
    /* color: #f91942 !important; */
    transition: 0.3s;
  }
  .sign-out {
    margin-left: 25px;
  }
  .sign-out:hover {
    cursor: pointer;
  }
  .sticky .sign-in {
    /* color: #333 !important; */
  }
  #sign-in-dialog {
    max-height: 700px;
    max-width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .not-sticky {
    background-color: #fff !important;
    background: #fff !important;
    box-shadow: 0 0 12px 0 rgba(0,0,0,.12) !important;
  }
  .not-sticky .user-name {
    color: #333 !important
  }
  .not-sticky .user-menu .user-name:after, .user-name-dashboard:after {
    color: #333 !important;
  }
  @media screen and (max-width: 500px) {
    .hidemobile {
      display: none;
    }
  }
  @media screen and (min-width: 500px) {
    .hidecomputer {
      display:  none;
    }
  }
  .to_dashboard_2 {
    color: #333 !important;
  }
  .to_dashboard_2:hover {
    color: white !important;
  }
  .not-sticky .current {
    background: rgba(249,25,66,.06) !important;
    color: #f91942 !important;
  }
  .sign-in_1 {
    color: #333 !important;
    border: 1px solid #333 !important;
  }face-icon2.png
Instagram02.png
  .sign-in_1:hover {
    color: white !important;
    border: 1px solid #f91942 !important;
  }
  @media only screen and (max-width: 991px) {
    .sign-in_1:nth-child(2) {
      /* margin-top: 10px; */
    }
  }
</style>

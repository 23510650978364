<template>
  <div id="wrapper">
    <div class="text-center" v-if="loading" style="position:absolute; top: 5%; left: 48%; height: 500px;">
      <div class="loader"></div>
    </div>
    <HeaderCustom/>
    <div class="col-xs-12 col-md-6 col-sm-offset-0 col-md-offset-3 text-center" style="text-align:center; padding-top:150px;">
      <!-- <ChangePassword :title="'Resetta password'" v-if="!loading && tokenValid" :type="'reset'" :token="$route.query.token"/> -->
      <h2 v-if="!loading && tokenValid">Il tuo account è stato confermato!</h2>
      <a v-if="!loading && tokenValid && okConfirmation" href="#sign-in-dialog" style="margin-top:50px;" class="button register sign-in popup-with-zoom-anim custom_modal_log">Accedi</a>
      <h2 style="margin-top: 150px; color: #f91942;" v-if="!loading && okConfirmation">Ora puoi accedere su Vetrine OnLine</h2>
      <h2 style="margin-top: 150px; color: #f91942;" v-if="!loading && !tokenValid">Link non valido!</h2>
    </div>
  </div>
</template>

<script>
import HeaderCustom from '@/components/Header'
import AuthenticationService from '@/services/AuthenticationService'
export default {
  name: 'confirm-registration',
  data() {
    return {
      loading: true,
      tokenValid: false,
      token: null,
      okConfirmation: false
    }
  },
  components: {
    HeaderCustom
  },
  async mounted() {
    if (!this.$route.query.token) {
      console.log('no token')
      this.tokenValid = false
      setTimeout(() => {
        this.$router.push({
          path: '/'
        })
      }, 3000)
    } else {
      console.log('si token')
      // this.$store.dispatch('user/setToken', this.$route.query.token)
      try {
        let ris = await AuthenticationService.confirmTokenAuthResetPassword(this.$route.query.token)
        if (ris) {
          this.tokenValid = true
          try {
            this.loading = true
            const result = await AuthenticationService.confirmRegistration(this.$route.query.token, {})
            if (result) {
              this.okConfirmation = true
            }
          } catch(error) {
            console.log('error: ', error)
            this.tokenValid = false
            this.okConfirmation = false
          } finally {
            this.$nextTick()
              .then(function () {
                let custom = document.createElement('script')
                custom.setAttribute('type', 'text/javascript')
                custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
                // custom.async = true;
                document.body.appendChild(custom)
              })
            this.loading = false
          }
        }
      } catch(error) {
        console.log('Token non valido')
        this.tokenValid = false
        setTimeout(() => {
          this.$router.push({
            path: '/'
          })
        }, 3000)
      }
    }
    this.loading = false
  },
  methods: {
    async confirmRegistration() {
      try {
        this.loading = true
        const result = await AuthenticationService.confirmRegistration(this.$route.query.token, {})
        if (result) {
          this.okConfirmation = true
        }
      } catch(error) {
        console.log('error: ', error)
        this.tokenValid = false
        this.okConfirmation = false
      } finally {
        this.loading = false
        setTimeout(() => {
        this.$router.push({
            path: '/'
          })
        }, 3000)
      }
    }
  }
}
</script>

<style>

</style>
<template>
  <div id="wrapper">
   <HeaderDashboard/><!-- tolto perche non caricava header v-if="!loading"-->
    <div id="dashboard">
      <!-- Responsive Navigation Trigger -->
	    <a href="#" class="dashboard-responsive-nav-trigger"><i class="fa fa-reorder"></i> Dashboard Navigation</a>
      <SideBar/>

      <!-- Content
      ================================================== -->
      <div class="dashboard-content">

        <!-- Titlebar -->
        <div id="titlebar">
          <div class="row">
            <div class="col-md-12">
              <!--<h2>Il mio Negozio</h2>-->
              <h2>Crea nuovo negozio</h2>
              <!-- Breadcrumbs -->
              <nav id="breadcrumbs">
                <ul>
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Dashboard</a></li>
                  <li>Crea Negozio</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <NewShop/>
          </div>
 
          <!-- Copyrights -->
          <div class="col-md-12">
            <div class="copyrights" style="font-size:12px;">
            <!-- <div class="col-xs-12 text-center">
              CONFCOMMERCIO SERVIZI DELLA CITTA' DELLA RIVIERA DEL BRENTA S.R.L.
            </div>
            <div class="col-xs-12 text-center">
                sede legale: Viale Ancona 9 30172 Mestre Ve
            </div>
            <div class="col-xs-12 text-center">
              sedi operative:
            </div>
            <div class="col-xs-12 text-center">
              Via Venezia 8 30034 Mira VE
            </div>
            <div class="col-xs-12 text-center">
              Via Benedetto Cairolo 18/D  30031 Dolo VE
            </div> -->
            <div class="col-xs-12 text-center">
              C.f.  e P.Iva 04498740275
            </div>
            <!-- <div class="col-xs-12 text-center">
              codice sdi  6EWHWLT
            </div> -->
            <div class="col-xs-12 text-center">
              <a class="privacy_policy_footer iubenda-black iubenda-embed" href="https://www.iubenda.com/privacy-policy/82105399" title="Privacy Policy ">Privacy e Policy</a>
            </div>
            <div class="col-xs-12 text-center">
              <router-link to="/all-shops">Tutti i Negozi</router-link>
            </div>
            <div class="col-xs-12 text-center">
              <a style="color: grey" href="mailto:assistenza@vetrineonline.com">assistenza@vetrineonline.com</a>
            </div>
          </div>
          </div>

        </div>

      </div>
      <!-- Content / End -->

    </div>
  </div>
</template>

<script>
//import ShopService from '@/services/ShopService'
import HeaderDashboard from '@/components/HeaderDashboard'
import SideBar from '@/components/SideBar'
import NewShop from '@/components/Negoziante/NewShop'
import { CodiceFiscale } from '@/utils/validations/codice_fiscale.js'
//import ShopImages from '@/components/Negoziante/ShopImages'
export default {
  name: 'dashboard-create-new-shop',
  data () {
    return {
      loading: true,
      connection: null
    }
  },
  // created: function() {
  //   console.log("Starting connection to WebSocket Server")
  //   this.connection = new WebSocket("wss://myescapi.stayted.com/ws/presence?shop_id=10")
  //   var vm = this
  //   this.connection.onmessage = function(event) {
  //     console.log(event);
  //     vm.confirmPresence()
  //   }

  //   this.connection.onopen = function(event) {
  //     console.log(event)
  //     console.log("Successfully connected to the echo websocket server...")
  //   }

  // },
  methods: {
    confirmPresence() {
      console.log("Mando un messaggio di risposta")
      console.log(this.connection);
      if (this.connection.connected) {
        var payload = { message: true }
        this.connection.sendUTF( JSON.stringify(payload) );
      }
    }
  },
  components: {
    HeaderDashboard,
    SideBar,
    NewShop,
    //ShopImages
  },
  async mounted() {
    this.loading = true
    if (!this.$store.state.user.isUserLoggedIn) {
        this.$router.push({
          //path: '/'
        });
    } else if (this.$store.state.user.user.role_id !== 5) {
      this.$router.push({
        //path: '/dashboard-company-my-profile'
      })
    }
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        document.body.appendChild(custom)
      })
  },
  computed: {
  }
}
</script>

<style scoped>
#dashboard {
  padding-top: 80px !important;
}
#dashboard .copyrights {
    padding: 40px 0;
    margin: 0;
    display: block;
    text-align: left;
    border: none;
}
.copyrights {
  color: #707070;
  border-top: 1px solid rgba(0,0,0,0.09) !important;
  margin-top: 60px !important;
  text-align: center !important;
  padding: 40px 0 !important;
  display: inline-block !important;
  width: 100% !important; 
}
</style>

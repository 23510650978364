import MultipartApi from '@/services/MultipartApi'
import Api from '@/services/Api'

export default {
  saveLogo (form, id) {
    return MultipartApi().patch('/model/shop/'+id+'/meta/logo', form)
  },
  saveGallery (form, id) {
    console.log('Post Immagini')
    return MultipartApi().post('/model/shop/'+id+'/meta/', form)
  },
  deleteImageGallery(id, id_meta) {
    return Api().delete('/model/shop/'+id+'/meta/'+id_meta)
  }
}

<template>
  <div id="wrapper">
    <HeaderDashboard v-if="!loading"/>
    <div id="dashboard">
      <!-- Responsive Navigation Trigger -->
	    <a href="#" class="dashboard-responsive-nav-trigger"><i class="fa fa-reorder"></i> Dashboard Navigation</a>
      <SideBar/>

      <!-- Content
      ================================================== -->
      <div class="dashboard-content">

        <!-- Titlebar -->
        <div id="titlebar">
          <div class="row">
            <div class="col-md-12">
              <h2>{{this.$store.state.shop.shop.label}}</h2>
              <!-- Breadcrumbs -->
              <nav id="breadcrumbs">
                <ul>
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Dashboard</a></li>
                  <li>Il mio Negozio</li>
                  <li>{{this.$store.state.shop.shop.label}}</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <ShopMetaData v-if="!loading" :key="this.$route.query.shop"/>
          </div>
          <!-- Change Password -->
          <div class="col-lg-6 col-md-12">
            <ShopData v-if="!loading" :key="this.$route.query.shop"/>
            <ShopImages v-if="!loading" :key="this.$route.query.shop"/>
          </div>


          <!-- Copyrights -->
          <div class="col-md-12">
            <div class="copyrights" style="font-size:12px;">
            <!-- <div class="col-xs-12 text-center">
              CONFCOMMERCIO SERVIZI DELLA CITTA' DELLA RIVIERA DEL BRENTA S.R.L.
            </div>
            <div class="col-xs-12 text-center">
                sede legale: Viale Ancona 9 30172 Mestre Ve
            </div>
            <div class="col-xs-12 text-center">
              sedi operative:
            </div>
            <div class="col-xs-12 text-center">
              Via Venezia 8 30034 Mira VE
            </div>
            <div class="col-xs-12 text-center">
              Via Benedetto Cairolo 18/D  30031 Dolo VE
            </div> -->
            <div class="col-xs-12 text-center">
              C.f.  e P.Iva 04498740275
            </div>
            <!-- <div class="col-xs-12 text-center">
              codice sdi  6EWHWLT
            </div> -->
            <div class="col-xs-12 text-center">
              <a class="privacy_policy_footer iubenda-black iubenda-embed" href="https://www.iubenda.com/privacy-policy/82105399" title="Privacy Policy ">Privacy e Policy</a>
            </div>
            <div class="col-xs-12 text-center">
              <router-link to="/all-shops">Tutti i Negozi</router-link>
            </div>
            <div class="col-xs-12 text-center">
              <a style="color: grey" href="mailto:assistenza@vetrineonline.com">assistenza@vetrineonline.com</a>
            </div>
          </div>
          </div>

        </div>
      </div>
      <!-- Content / End -->

    </div>
  </div>
</template>

<script>
import ShopService from '@/services/ShopService'
import HeaderDashboard from '@/components/HeaderDashboard'
import SideBar from '@/components/SideBar'
import ShopData from '@/components/Negoziante/ShopData'
import ShopMetaData from '@/components/Negoziante/ShopMetaData'
import ShopImages from '@/components/Negoziante/ShopImages'
export default {
  name: 'dashboard-company-my-profile',
  data () {
    return {
      loading: true,
      connection: null
    }
  },
  // created: function() {
  //   console.log("Starting connection to WebSocket Server")
  //   this.connection = new WebSocket("wss://myescapi.stayted.com/ws/presence?shop_id=10")
  //   var vm = this
  //   this.connection.onmessage = function(event) {
  //     console.log(event);
  //     vm.confirmPresence()
  //   }

  //   this.connection.onopen = function(event) {
  //     console.log(event)
  //     console.log("Successfully connected to the echo websocket server...")
  //   }

  // },
  methods: {
    confirmPresence() {
      console.log("Mando un messaggio di risposta")
      console.log(this.connection);
      if (this.connection.connected) {
        var payload = { message: true }
        this.connection.sendUTF( JSON.stringify(payload) );
      }
    }
  },
  components: {
    HeaderDashboard,
    SideBar,
    ShopData,
    ShopMetaData,
    ShopImages
  },
  async mounted() {
        this.$store.dispatch('classification/load_data');
    this.loading = true
    if (!this.$store.state.user.isUserLoggedIn) {
        this.$router.push({
          //path: '/'
        });
    } else if (this.$store.state.user.user.role_id !== 5) {
      this.$router.push({
        //path: '/dashboard-company-my-profile'
      })
    }
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        document.body.appendChild(custom)
      })
    try {
      var index =this.$route.query.shop
      
      const shop_meta = await ShopService.getShopMeta(this.$store.state.user.user.myworkshops[index].id)
      this.$store.dispatch('shop/setShop', shop_meta.data)
      this.$store.dispatch('shop_meta/setShopMeta', shop_meta.data.metas)
      this.$store.dispatch('shop_address/setAddress', shop_meta.data.addresses)
      console.log('data shop: ', shop_meta)

      console.log('IMMAGINI: ', shop_meta.data.logo, shop_meta.data.gallery)
      shop_meta.data.logo ? shop_meta.data.logo.value ? shop_meta.data.logo.value.id = shop_meta.data.logo.id : null : null
      this.$store.dispatch('shop_images/setLogo', shop_meta.data.logo ? shop_meta.data.logo.value : null)
      this.$store.dispatch('shop_images/setGallery', shop_meta.data.gallery.map((el) => {
         el ? el.value ? el.value.id = el.id : null : null
         return el.value
        }))
      this.loading = false
      //this.$eventHub.$on('loading', false) 
    } catch(error) {
      console.log('Errore in loading shop data: ', error)
    }
  },
  computed: {
  }
}
</script>

<style scoped>
#dashboard {
  padding-top: 80px !important;
}
#dashboard .copyrights {
    padding: 40px 0;
    margin: 0;
    display: block;
    text-align: left;
    border: none;
}
.copyrights {
  color: #707070;
  border-top: 1px solid rgba(0,0,0,0.09) !important;
  margin-top: 60px !important;
  text-align: center !important;
  padding: 40px 0 !important;
  display: inline-block !important;
  width: 100% !important; 
}
</style>

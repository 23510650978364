<template>
    <div id="wrapper">
    <HeaderDashboard/>
    <div id="dashboard">
      <!-- Responsive Navigation Trigger -->
	    <a href="#" class="dashboard-responsive-nav-trigger"><i class="fa fa-reorder"></i> Dashboard Navigation</a>
      <SideBar/>

      <!-- Content
      ================================================== -->
      <div class="dashboard-content">

        <!-- Titlebar -->
        <div id="titlebar">
          <div class="row">
            <div class="col-md-12">
              <h2>QR Code Menù</h2>
              <!-- Breadcrumbs -->
              <nav id="breadcrumbs">
                <ul>
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Dashboard</a></li>
                  <li>QRCode Menù</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="text-center" v-if="loading" style="position:absolute; top: 15%; left: 45%; height: 500px;">
            <div class="loader"></div>
          </div>
          <div id="qr_code" v-if="!loading">
            <div class="col-xs-12 text-center" style="padding-top: 40px;">
              <h3>{{$store.state.shop.shop.label}}</h3>
            </div>
            <div class="col-xs-12 text-center" style="margin-top:25px;">
              <img :src="'data:image/png;base64,'+QRimage" style="max-width:350px;">
            </div>
          </div>
          <div class="col-xs-12 text-center" style="margin-top:25px;">
            <button class="button margin-top-15" @click="print()">Stampa</button>
          </div>

          <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
            <span style="color:red;" class="col-sm-12 text-center">
              <span v-html="error"></span>
            </span>
          </div>
          <!-- Copyrights -->
          <div class="col-md-12">
           <div class="copyrights" style="font-size:12px;">
            <!-- <div class="col-xs-12 text-center">
              CONFCOMMERCIO SERVIZI DELLA CITTA' DELLA RIVIERA DEL BRENTA S.R.L.
            </div>
            <div class="col-xs-12 text-center">
                sede legale: Viale Ancona 9 30172 Mestre Ve
            </div>
            <div class="col-xs-12 text-center">
              sedi operative:
            </div>
            <div class="col-xs-12 text-center">
              Via Venezia 8 30034 Mira VE
            </div>
            <div class="col-xs-12 text-center">
              Via Benedetto Cairolo 18/D  30031 Dolo VE
            </div> -->
            <div class="col-xs-12 text-center">
              C.f.  e P.Iva 04498740275
            </div>
            <!-- <div class="col-xs-12 text-center">
              codice sdi  6EWHWLT
            </div> -->
            <div class="col-xs-12 text-center">
              <a class="privacy_policy_footer iubenda-black iubenda-embed" href="https://www.iubenda.com/privacy-policy/82105399" title="Privacy Policy ">Privacy e Policy</a>
            </div>
            <div class="col-xs-12 text-center">
              <router-link to="/all-shops">Tutti i Negozi</router-link>
            </div>
            <div class="col-xs-12 text-center">
              <a style="color: grey" href="mailto:assistenza@vetrineonline.com">assistenza@vetrineonline.com</a></h4>
            </div>
          </div>
          </div>

        </div>

      </div>
      <!-- Content / End -->

    </div>
  </div>
</template>

<script>
import HeaderDashboard from '@/components/HeaderDashboard'
import SideBar from '@/components/SideBar'
import DefaultService from '@/services/DefaultService'
export default {
  name: 'dashboard-qr-code',
  components: {
    HeaderDashboard,
    SideBar
  },
  data() {
    return {
      loading: true,
      QRimage: null,
      error: null
    }
  },
  async mounted() {
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        document.body.appendChild(custom)
      })
    try {
      let qrcode = await DefaultService.getQRCodeMenu(this.$store.state.shop.shop.id, 'myfood_listino')
      if(qrcode) {
        console.log('QR: ', qrcode)
        this.QRimage = qrcode.data.responseObject.imageBytes
      }
    } catch(error) {
        error.response ? this.error = error.response.data.message : this.error = error
        setTimeout(() => {
          this.error = null
        }, 3000)
    } finally {
      this.loading = false
    }
  },
  methods: {
    print() {
      var printContents = document.getElementById("qr_code").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
    }
  }
}
</script>

<style>

</style>

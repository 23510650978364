<template>
  <vue-google-autocomplete
    :country="['it']"
    :id="id_address"
    :value="addressInput"
    classname="form-control"
    placeholder="Inserire via, numero civico, comune"
    v-on:error="handleError"
    v-on:placechanged="getAddressData"
    v-on:inputChange="foo()"
    ref="indirizzo"
  >
  </vue-google-autocomplete>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import ParseLocation from '@/utils/parse_geolocation.js'
export default {
  components: { VueGoogleAutocomplete },
  props: ['id_address'],
  data () {
    return {
      address: '',
      obj: null,
      stop: false,
      addressInput: '',
      data_parsed: null
    }
  },
  mounted() {
    this.$refs.indirizzo.focus();
  },
  methods: {
    foo () {
      console.log(this.$refs);
      !this.stop ? this.obj = null : this.stop = false
      console.log(this.$refs['indirizzo'].autocompleteText);
      this.addressInput = this.$refs['indirizzo'].autocompleteText
    },
      /**
      * When the location found
      * @param {Object} addressData Data of the found location
      * @param {Object} placeResultData PlaceResult object
      * @param {String} id Input container ID
      */
      getAddressData: function (addressData, placeResultData, id) {
        // console.log('Parse: ', ParseLocation.main(placeResultData))
        this.stop = true
        this.address = addressData;
        this.obj = placeResultData
        this.data_parsed = ParseLocation.main(placeResultData)
      },
      handleError(error){
        alert(error)
      }
  }
}
</script>

<style>

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import DashboardCompanyMyProfile from '@/views/DashboardCompanyMyProfile'
import DashboardCompanyMyShop from '@/views/DashboardCompanyMyShop'
import DashboardMyShops from '@/views/DashboardMyShops'
import DashboardCreateNewShop from '@/views/DashboardCreateNewShop'
import DashboardQRCode from '@/views/DashboardQRCode'
import AppThirdPart from '@/views/AppThirdPart'
import ShopsList from '@/views/ShopsList'
import Informativa from '@/views/Informativa'
import Ateco from '@/components/Negoziante/ateco_autocomplete'
import PageShop from '@/views/PageShop'
import TestIframe from '@/views/TestIframe'
import ResetPassword from '@/views/ResetPassword'
import ConfirmRegistration from '@/views/ConfirmRegistration'
import CoomingSoon from '@/views/CoomingSoon'
import Manutenzione from '@/views/Manutenzione'
import VideoCall from '@/views/VideoCall'
import AllShopsList from '@/views/AllShopsList'
import TokenConverter from '@/views/TokenConverter'
import store from '@/store'
// import Settings from '@/views/Settings'

Vue.use(VueRouter)

const routes = [
//{
//  path: '/',
//  name: 'home',
//  component: Home
//},
  {
    path: '/token-converter',
    name: 'token-converter',
    component: TokenConverter,
  },
  {
    path: '/dashboard-company',
    name: 'dashboard-company',
    component: DashboardCompanyMyProfile,
    beforeEnter: (to, from, next) => {
      if (!store.state.user.isUserLoggedIn) {
        next({path: '/'})
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard-company-my-profile',
    name: 'dashboard-company-my-profile',
    component: DashboardCompanyMyProfile,
    beforeEnter: (to, from, next) => {
      if (!store.state.user.isUserLoggedIn) {
        next({path: '/'})
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard-company-my-shop',
    name: 'dashboard-company-my-shop',
    component: DashboardCompanyMyShop,
    beforeEnter: (to, from, next) => {
      console.log( to );
      var has_token = false;
      try {
          if ( to.hasOwnProperty('query') && Object.keys( to.query ).includes('jwt') ) { has_token = true; }
      } catch ( error ) {
          console.log( error );
      }
      console.log( has_token );
      if ( has_token === true ) {
        next({path: `/token-converter?jwt=${ to.query.jwt }&shop_id=${ to.query.shop_id }`})
      } else if (!store.state.user.isUserLoggedIn && has_token === false) {
        next({path: '/'})
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard-create-new-shop',
    name: 'dashboard-create-new-shop',
    component: DashboardCreateNewShop,
    beforeEnter: (to, from, next) => {
      console.log( to );
      var has_token = false;
      try {
          if ( to.hasOwnProperty('query') && Object.keys( to.query ).includes('jwt') ) { has_token = true; }
      } catch ( error ) {
          console.log( error );
      }
      console.log( has_token );
      if ( has_token === true ) {
        next({path: `/token-converter?jwt=${ to.query.jwt }&shop_id=${ to.query.shop_id }`})
      } else if (!store.state.user.isUserLoggedIn && has_token === false) {
        next({path: '/'})
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard-my-shops',
    name: 'dashboard-my-shops',
    component: DashboardMyShops,
    beforeEnter: (to, from, next) => {
      if (!store.state.user.isUserLoggedIn) {
        next({path: '/'})
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard-qr-code',
    name: 'dashboard-qr-code',
    component: DashboardQRCode,
    beforeEnter: (to, from, next) => {
      if (!store.state.user.isUserLoggedIn) {
        next({path: '/'})
      } else {
        next()
      }
    }
  },
  {
    path: '/shops-list',
    name: 'shops-list',
    component: ShopsList,
    beforeEnter: (to, from, next) => {
      if (to.query.page === '1')
        next()
      else
        next('/shops-list?page=1')
    }
  },
  {
    path: '/informativa',
    name: 'informativa',
    component: Informativa
  },
  {
    path: '/ateco',
    name: 'ateco',
    component: Ateco
  },
  // {
  //   path: '/preview-myshop',
  //   name: 'page-shop',
  //   component: PageShop,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.state.user.isUserLoggedIn) {
  //       next({path: '/'})
  //     } else {
  //       next()
  //     }
  //   }
  // },
  {
    path: '/shop/',
    name: 'page-shop',
    component: PageShop,
    beforeEnter: (to, from, next) => {
      if (to.query.id)
        next()
      else
        next({path: '/'})
    }
  },
  {
    path: '/app-third-part',
    name: 'app-third-part',
    component: AppThirdPart,
    beforeEnter: (to, from, next) => {
      if (!store.state.user.isUserLoggedIn) {
        next({path: '/'})
      } else {
        if (to.query.app)
          next()
        else
          next({path: '/dashboard-company-my-shop'})
      }
    }
  },
  //{ // 2021-02-18 per rimuovere il warning del duplicato
  //  path: '/shop/:id',
  //  name: 'page-shop',
  //  component: PageShop
  //},
  {
    path: '/test-iframe',
    name: 'test-iframe',
    component: TestIframe,
    beforeEnter: (to, from, next) => {
      if (to.query.id)
        next()
      else
        next({path: '/'})
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword
  },
  {
    path: '/confirm-registration',
    name: 'confirm-registration',
    component: ConfirmRegistration
  },
  {
    path: '/cooming-soon',
    name: 'cooming-soon',
    component: CoomingSoon
  },
  {
    path: '/manutenzione',
    name: 'manutenzione',
    component: Manutenzione
  },
  {
    path: '/all-shops',
    name: 'all-shops-list',
    component: AllShopsList
  },
  {
    path: '/video-call',
    name: 'video-call',
    component: VideoCall
  },
  // {
  //   path: '/',
  //   name: 'cooming-soon',
  //   component: CoomingSoon
  // },
  // {
  //   path: '/settings',
  //   name: 'settings',
  //   component: Settings
  // },
  // redirect in home altri percorsi
  {
    path: '*',
    redirect: '/shops-list'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

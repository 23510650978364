var backend =  {
  local: 'http://127.0.0.1:3000/',
  development: 'https://myescapi.stayted.com/',
  staging: 'https://volstagingapi.digitalautomations.it/',
  production: 'https://api.vetrineonline.com/'
}
var frontend = {
  local: 'http://127.0.0.1:8080/',
  development: 'https://myesc.stayted.com/',
  staging: 'https://volstaging.digitalautomations.it/',
  production: 'https://www.vetrineonline.com/'
}
module.exports = {
  backend_url: backend.staging,
  frontend_url: frontend.staging,
  pagination: {
    rows_per_page: 60
  },
  ONE_SIGNAL: {
    REST_KEY: 'NmUyNTZlZTctYjEzZS00YzQ4LWIyMzUtZTM1NGU5YjkxN2I0',
    APP_ID: '20d3b7b0-85e1-49d7-8f9a-1e74f587be12'
  }
}

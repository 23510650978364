

<template>
  <div class="register">
		<div class="text-center" v-if="loading" style="position:absolute; top: 40%; left: 43%; height: 100px !importan;">
      <div class="loader_login"></div>
    </div>
    <div :class="loading ? 'fs-container opacity' : ''">						
      <p class="form-row form-row-wide">
        <label for="shop_name">Nome Negozio:
          <i class="im im-icon-Shop"></i>
          <input v-model="shop_name" type="text" maxlength="64" :disabled="success" :class="error_shop_name || be_error_shop_name ? 'input-text is-invalid' : validationShopName() ? 'input-text is-valid' : 'input-text' " name="shop_name" id="shop_name"/>
        </label>
        <span class="error" v-if="error_shop_name">Inserisci il nome del negozio <i>(almeno 5 caratteri)</i></span>
        <span class="error" v-if="be_error_shop_name">{{ be_error_shop_name }}</span>
      </p>

      <!--
      <p class="form-row form-row-wide">
        <label for="shop_name">Categoria Negozio:
          <b-form-select v-model="shop_type" :options="options_shop_type" :disabled="success" :state="error_shop_type ? false : validationShopType() ? true : null"></b-form-select>
        </label>
        <span class="error" v-if="error_shop_type">Inserisci la tipologia</span>
        <span class="error" v-if="be_error_shop_type">{{ be_error_shop_type }}</span>
      </p>
      -->
      <p>
        <label for="shop_name">Settori:</label>
        <SectorEditRegistration ref="classification_sector" v-if="classification" :sectors="classification.s" />
      </p>

      <p class="form-row form-row-wide">
      <label for="shop_name">Categorie:</label>
      <ClassificationEditRegistration ref="classification_category"  v-if="classification" :classification="{ c: classification.c, u: classification.u }" @submit-save=""  />
      </p>
      <p class="form-row form-row-wide">
        <label for="pi">Partiva Iva:
          <i class="im im-icon-Fountain-Pen"></i>
          <input v-model="pi" type="text" maxlength="11" :disabled="success" :class="error_pi || be_error_pi ? 'input-text is-invalid' : validationPI() ? 'input-text is-valid' : 'input-text' " name="pi" id="pi"/>
        </label>
        <span class="error" v-if="error_pi">Inserisci la partita iva <i>(valida)</i></span>
        <span class="error" v-if="be_error_pi">{{ be_error_pi }}</span>
      </p>

      <p class="form-row form-row-wide">
        <label for="cf">Codice Fiscale:
          <i class="im im-icon-Identification-Badge"></i>
          <input v-model="cf" type="text" maxlength="16" :disabled="success" :class="error_cf || be_error_cf ? 'input-text is-invalid' : validationCF() ? 'input-text is-valid' : 'input-text' " name="cf" id="cf"/>
        </label>
        <span class="error" v-if="error_cf">Inserisci il codice fiscale o la partita iva <i>(validi)</i></span>
        <span class="error" v-if="be_error_cf">{{ be_error_cf }}</span>
      </p>
      
      <p class="form-row form-row-wide">
        <label for="name1">Nome:
          <i class="im im-icon-Male"></i>
          <input v-model="name" type="text" maxlength="64" :disabled="success" :class="error_name || be_error_name ? 'input-text is-invalid' : validationName() ? 'input-text is-valid' : 'input-text' " name="name1" id="name1"/>
        </label>
        <span class="error" v-if="error_name">Inserisci il nome</span>
        <span class="error" v-if="be_error_name">{{ be_error_name }}</span>
      </p>

      <p class="form-row form-row-wide">
        <label for="surname1">Cognome:
          <i class="im im-icon-Male-2"></i>
          <input v-model="surname" type="text" maxlength="64" :disabled="success" :class="error_surname || be_error_surname ? 'input-text is-invalid' : validationSurname() ? 'input-text is-valid' : 'input-text' " name="surname1" id="surname1" />
        </label>
        <span class="error" v-if="error_surname">Inserisci il cognome</span>
        <span class="error" v-if="be_error_surname">{{ be_error_surname }}</span>
      </p>
        
      <p class="form-row form-row-wide">
        <label for="email3">Indirizzo Email:
          <i class="im im-icon-Mail"></i>
          <input v-model="email" type="text" maxlength="64" :disabled="success" :class="error_email || be_error_email ? 'input-text is-invalid' : validationEmail() ? 'input-text is-valid' : 'input-text' " name="email3" id="email3" />
        </label>
        <span class="error" v-if="error_email">Inserisci l'email <i>(valida)</i></span>
        <span class="error" v-if="be_error_email">{{ be_error_email }}</span>
      </p>

      <p class="form-row form-row-wide">
        <label for="password2">Password:
          <i class="im im-icon-Lock-2"></i>
          <input v-model="password" type="password" maxlength="128" :disabled="success" :class="error_password || be_error_password ? 'input-text is-invalid' : validationPassword() ? 'input-text is-valid' : 'input-text' " name="password2" id="password2"/>
        </label>
        <span class="error" v-if="error_password">Inserisci la password <i>(almeno 8 caratteri)</i></span>
        <span class="error" v-if="be_error_password">{{ be_error_password }}</span>
      </p>

      <p class="form-row form-row-wide">
        <label for="conferma_password1">Conferma Password:
          <i class="im im-icon-Lock-2"></i>
          <input v-model="conferma_password" maxlength="128" :disabled="success" :class="error_conferma_password || be_error_conferma_password ? 'input-text is-invalid' : validationConfermaPassword() ? 'input-text is-valid' : 'input-text' " type="password" name="conferma_password1" id="conferma_password1"/>
        </label>
        <span class="error" v-if="error_conferma_password">Inserisci di nuovo password <i>(almeno 8 caratteri e uguale a password)</i></span>
        <span class="error" v-if="be_error_conferma_password">{{ be_error_conferma_password }}</span>
      </p>
      <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
        <span style="color:red;" class="col-sm-12 text-center">
          <span v-html="error"></span>
        </span>
      </div>
      <div class="col-sm-12">
        <span style="color:green;" class="col-sm-12 text-center">
          {{success? 'Registrazione avvenuto con successo' : ''}}
        </span>
        <span style="color:green;" class="col-sm-12 text-center">
          {{success? "Controlla l'email e conferma la registrazione!" : ''}}
        </span>
      </div>
      <div class="form-row">
        <div class="checkboxes margin-top-10">
          <input id="remember-me1" type="checkbox" v-model="privacy" name="check">
          <label for="remember-me1">Accetto l'informativa sulla <a href="https://www.iubenda.com/privacy-policy/82105399" download class="privacy" target="_blank">privacy</a></label>
          <span class="error" v-if="error_privacy && !privacy">Accetta la privacy.</span>
        </div>
        <button :class="checkForm() ? 'button border fw margin-top-10' : 'button border fw margin-top-10'" @click="registerCompany()"> Registrati </button>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultService from '@/services/DefaultService'
import AuthenticationService from '@/services/AuthenticationService'
import Vue from 'vue'
import { FormSelectPlugin } from 'bootstrap-vue'
import { PartitaIVA } from '@/utils/validations/partita_iva.js'
import { CodiceFiscale } from '@/utils/validations/codice_fiscale.js'
import config from '@/config'
import ClassificationEditRegistration from '@/components/classification/classification_edit_registration'
import SectorEditRegistration from '@/components/classification/sector_edit_registration'
Vue.use(FormSelectPlugin)
export default {
  name: 'register-company',
   components: {
    ClassificationEditRegistration,
    SectorEditRegistration
  },
  data () {
    return {
      loading: true,
      config: null,
      shop_name: '',
      shop_type: null,
      options_shop_type: [
       // { value: null, text: 'Scegli una categoria' }
      ],
      pi: '',
      cf: '',
      email: '',
      password: '',
      conferma_password: '',
      name: '',
      privacy: false,
      surname: '',
      success: false,
      error_shop_name: null,
      error_shop_type: null,
      error_pi: null,
      error_cf: null,
      error_email: null,
      error_password: null,
      error_conferma_password: null,
      error_name: null,
      error_surname: null,
      error_privacy: null,
      be_error_shop_name: null,
      be_error_shop_type: null,
      be_error_pi: null,
      be_error_cf: null,
      be_error_email: null,
      be_error_password: null,
      be_error_conferma_password: null,
      be_error_name: null,
      be_error_surname: null,
      error_psw_from_db: "The password provided failed to match the following rules:\n              <br>\n              1. It must contain ONLY the following characters: lower case, upper case, numerics.\n              <br>\n              2. It must be at least 8 characters in length and not greater than 32 characters in length.\n            ",
      error: null,

      classification     : {},
      sectors            : [],
      //key_sector         : 0,
    }
  },
  created () {
    this.config = config
  },
  async mounted () {
    // pi: 11359591002
    // cf: BNFSMN93R03A785W 
    try {
      const type = await DefaultService.getTypeDefault();
      if (type) {
        console.log("stampo type")
        console.log(type)
        type.data.forEach(element => {
            this.options_shop_type.push({value: element.id, text: element.label})
          })
      }
    } catch(error) {
      console.log('err: ', error)
      error.response ? this.error = error.response.data.message : this.error = error + '! Ricarica la pagina'
    } finally {
      this.loading = false
    }
  },
  methods: {
    validationShopName() {
      if (this.shop_name != '' && this.shop_name.length >= 5) {
        this.error_shop_name = false
        return true
      }
      return false
    },
    validationShopType() {
      /*
      if (this.shop_type != null) {
        this.error_shop_type = false
        return true
      }*/
      //this.$refs.popupAlert.show(warning)
      
      console.log("Stampo classification riga: ")
      console.log(this.classification)
      if (this.classification != null) {
        //TODO controllare che ci sia c,u,s
        if((this.classification.c!=null || this.classification.c!='') && (this.classification.u!=null || this.classification.u!='') && (this.classification.s!=null || this.classification.s!='')){
          console.log("in classification ci sono u,s,c")
          if(this.classification.c.length>0 && this.classification.u.length>0){
          this.error_shop_type = false
          return true
          }
        }  
      }
      return false
    },

    validationPI() {
      if (this.pi !== '') {
        this.error_pi = null
        this.error_cf = null
        return true
      }
      // if (this.pi === '' && this.cf !== '' && this.validationCF()) {
      //   this.error_pi = null
      //   this.error_cf = null
      //   return true
      // }
      return false
    },
    validationCF() {
      if (this.cf === '' && this.pi !== '' /*&& this.validationPI()*/) {
        // this.error_pi = null
        this.error_cf = null
        return true
      }
      if (!CodiceFiscale.validate(this.cf) || this.cf!== '') {
        // this.error_pi = null
        this.error_cf = null
        return true
      }
      return false
    },
    validationEmail () {
      if (this.email.length > 3 && /.+@.+/.test(this.email)) {
        this.error_email = null
        return true
      }
      return false
    },
    validationPassword () {
      if (this.password.length > 7) {
        this.error_password = null
        return true
      }
      return false
    },
    validationConfermaPassword () {
      if(this.password === this.conferma_password && this.conferma_password.length > 7) {
        this.error_conferma_password = null
        return true
      }
      return false
    },
    validationName() {
      if (this.name != '') {
        this.error_name = null
        return true
      }
      return false
    },
    validationSurname() {
      if (this.surname != '') {
        this.error_surname = null
        return true
      }
      return false
    },
    checkForm() {
      if (this.validationShopName() &&
        this.validationShopType() &&
        this.validationPI() &&
        this.validationCF() &&
        this.validationName() &&
        this.validationSurname() &&
        this.validationEmail() &&
        this.validationPassword() &&
        this.validationConfermaPassword() &&
        this.privacy) {
          // this.error = null
          return true
        }
      return false
    },
    checkValidationPreSave() {
      if (!this.checkForm()) {
        this.error = 'Errore! Controlla tutti i campi'
        if (!this.validationShopName())
          this.error_shop_name = true
          
        if (!this.validationShopType())
          this.error_shop_type = true
        if (!this.validationPI())
          this.error_pi = true
        if (!this.validationCF())
          this.error_cf = true
        if (!this.validationEmail())
          this.error_email = true
        if (!this.validationPassword())
          this.error_password = true
        if (!this.validationConfermaPassword())
          this.error_conferma_password = true
        if (!this.validationName())
          this.error_name = true
        if (!this.validationSurname())
          this.error_surname = true
        if (!this.privacy)
          this.error_privacy = true

        return false
      } else {
        return true
      }
    },
    resetError() {
      this.error_shop_name = null,
      this.error_shop_type = null,
      this.error_pi = null,
      this.error_cf = null,
      this.error_email = null,
      this.error_password = null,
      this.error_conferma_password = null,
      this.error_name = null,
      this.error_surname = null,
      this.error_privacy = null,
      this.be_error_shop_name = null,
      this.be_error_shop_type = null,
      this.be_error_pi = null,
      this.be_error_cf = null,
      this.be_error_email = null,
      this.be_error_password = null,
      this.be_error_conferma_password = null,
      this.be_error_name = null,
      this.be_error_surname = null,
      this.error = null
    },
    async registerCompany() {
      this.loading = true
      var new_classification = {
          s: this.$refs['classification_sector'].new_sectors_registration,
          c: this.$refs['classification_category'].new_classification.c,
          u: this.$refs['classification_category'].new_classification.u,
      };
      this.classification = new_classification
      console.log("stampo new_classification")
      console.log( new_classification );
      this.resetError()
      if(!this.checkValidationPreSave()) {
        this.loading = false
        return 0
      } else {
        try {
           this.error = null
           const response = await AuthenticationService.register({
            label: this.shop_name,
            //type: this.shop_type,
            //type: 39,
            type: new_classification.u[0],
            codice_fiscale: (this.cf.length > 0 ? this.cf : null),
            partita_iva: (this.pi.length > 0 ? this.pi : null),
            email: this.email,
            password: this.password,
            first_name: this.name,
            last_name: this.surname,
            role_id: 5,
            classification: new_classification//this.classification
          })
          if (response) {
            console.log(response)
            //this.$store.dispatch('setToken', response.data.token)
            // this.$store.dispatch('user/setUser', response.data.user)
            let risLogin = /*await this.login()*/ 1
            if (risLogin === 1) {
              this.success = true
              setTimeout(() => {
                location.reload();
                // window.location.href = '/dashboard-company-my-shop'
              }, 3000)
            } else {
              this.error = 'Errore durante la registrazione!'
              setTimeout(() => {
                this.error = null
              }, 3000)
            }
          }
        } catch (error) {
          if (error.response) {
            if (error.response.data.message === 'dati non validi') {
              Object.keys(error.response.data.errors).forEach(el2 => {
                if (el2 === 'first_name') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_name = error.response.data.errors[el2].message
                }
                if (el2 === 'last_name') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_surname = error.response.data.errors[el2].message
                }
                if (el2 === 'partita_iva') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_pi = error.response.data.errors[el2].message
                }
                if (el2 === 'codice_fiscale') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_cf = error.response.data.errors[el2].message
                }
                if (el2 === 'label') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_shop_name = error.response.data.errors[el2].message
                }
                if (el2 === 'email') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_email = error.response.data.errors[el2].message
                }
                if (el2 === 'password') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_password = error.response.data.errors[el2].message
                  this.be_error_conferma_password = error.response.data.errors[el2].message
                }
                if (el2 === 'not valid password') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_password =  error.response.data.errors[el2].message
                  this.be_error_conferma_password =  error.response.data.errors[el2].message
                }
                if (el2 === 'model') {
                  this.error = error.response.data.errors[el2].message
                }
                // if (el2.path === 'password') {
                //   this.error = 'la password deve essere tra gli 8 e i 16 caratteri'
                //   this.be_error_password = 'la password deve essere tra gli 8 e i 16 caratteri'
                //   this.be_error_conferma_password = 'la password deve essere tra gli 8 e i 16 caratteri'
                // }
              })
            } else if (error.response.data.message === `Username "${this.email}" is already taken`) {
              this.error = /*error.response.data.message*/ 'Questa email è già in uso'
              this.be_error_email = /*error.response.data.message*/ 'Questa email è già in uso'
            } else if (error.response.data.message === `l'email "${this.email}" è già registrata`) {
              this.error = 'Questa email è già in uso'
              this.be_error_email = 'Questa email è già in uso'
            } else if (error.response.data.message === `email "${this.email}" is already taken`) {
              this.error = 'Questa email è già in uso'
              this.be_error_email = 'Questa email è già in uso'
            } else if (error.response.data.error === this.error_psw_from_db) {
              this.error = 'La password deve essere minimo di 8 caratteri e non può contenere caratteri speciali'
              this.be_error_password = 'La password deve essere minimo di 8 caratteri e non può contenere caratteri speciali'
              this.be_error_conferma_password = 'La password deve essere minimo di 8 caratteri e non può contenere caratteri speciali'
            } else if (error.response.data.message === `email "${this.email}" is already taken`) {
              this.error = 'Questa email è già in uso'
              this.be_error_email = 'Questa email è già in uso'
            } else {
              this.success = false
              this.error = error.response.data.message || error.response.data.error
              console.log(error)
            }
            // if (error.response.data.errors[0].path === 'password') {
            //   this.error = 'la password deve essere tra gli 8 e i 16 caratteri'
            //   this.be_error_password = 'la password deve essere tra gli 8 e i 16 caratteri'
            //   this.be_error_conferma_password = 'la password deve essere tra gli 8 e i 16 caratteri'
            // }
          } else {
            this.error = error
          }
          setTimeout(() => {
            // this.be_error_name = null
            // this.be_error_surname = null
            // this.be_error_pi = null
            // this.be_error_cf = null
            // this.be_error_shop_name = null
            // this.be_error_email = null
            // this.be_error_password = null
            // this.be_error_conferma_password = null
            // this.be_error_conferma_password = null
            // this.error = null
          }, 3000)
        } finally {
          this.loading = false
        }
        // this.loading = false
      }
    },
    async login() {
      try {
        this.error = null
        const response = await AuthenticationService.login({
          email: this.email,
          password: this.password
        })
        this.success = true
        console.log('Login res: ', response)
        // window.OneSignal = window.OneSignal || [];
        // window.OneSignal.push(() => {
        //   window.OneSignal.init({
        //     appId: "20d3b7b0-85e1-49d7-8f9a-1e74f587be12",
        //     allowLocalhostAsSecureOrigin: true,
        //   });
        //   window.OneSignal.setExternalUserId(response.data.id);
        //   window.OneSignal.getUserId( function(userId) {
        //     console.log('player_id of the subscribed user is : ' + userId);
        //     // Make a POST call to your server with the user ID        
        //   });
        // });
        this.$store.dispatch('user/setToken', response.data.token) // serve?
        this.$store.dispatch('user/setUser', response.data)
        this.$store.dispatch('user_address/setAddress', response.data.addresses)
        this.$store.dispatch('user_meta/setUserMeta', response.data.metas)
        this.$store.dispatch('my_shops/setMyShops', response.data.myshops)
        this.$store.dispatch('shop/setShop', response.data.myworkshops[0].api)
        this.$store.dispatch('presence_ws/setStatus', 'free')
        return 1
      } catch (error) {
        console.log(error)
        this.error = 'Errore in login'
        return -1
      }
    }
  }
}
</script>

<style scoped>
  .error {
    color: #f91942;
  }
  .is-invalid {
    color: #f91942 !important;
    border-color: #f91942 !important;
  }
  .is-valid {
    /* color: green !important; */
    border-color: green !important;
    /* background: #e6e6e6 !important; */
  }
  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
  }
  .privacy {
    color: #333;
    cursor: pointer;
    transition: .3s;
  }
  .privacy:hover {
    color: #f91942 !important;
    transition: .3s;
  }
  /* .register {
    height: 500px;
    overflow-y: auto;
  } */
  .loader_login {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #fa3256;
    width: 70px;
    height: 70px;
    margin: auto;
    margin-top: 250px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
</style>
<script>
const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]

export default {

    name: 'sector-edit-registration',
    props: [ 'sectors' ],
    components: {},
    data() {
        return {
            new_sectors_registration : [],
            key         : 0,
            key_cat     : 0,
            div_sector  : false,

        };
    },
    computed: {
        classification_loaded() { return this.$store.state.classification.obj === null ? false : true },
        selected_sectors: {
            get() { return this.$store.state.classification.selected_sectors },
            set( selected_sectors ) { return this.$store.dispatch( 'classification/set_selected_sectors', selected_sectors ); },
        },
        all_sectors() { return this.$store.getters['classification/sectors']; },
    },
    watch: {},
    mounted() {},
    created() {
        this.reset_sectors();
    },
    methods: {
        set_active( sector ) {
            console.log("stampo sector")
            console.log(this.selected_sectors)
            var index = this.selected_sectors.map( x => x.id ).indexOf( sector.id );
            //var index = this.new_sectors_registration.indexOf( sector.id );
            console.log("stampo index")
            console.log(index)
            if ( index > -1 ) { // remove
                this.new_sectors_registration.splice( index, 1 );
            } else { // add
                this.new_sectors_registration.push( sector.id );
            }
            this.$store.dispatch('classification/set_selected_sectors_by_id', this.new_sectors_registration);
        },
        get_style( sector_id ) {
            var style = {
                'background-color' : 'white',
                'color'            : 'grey',
            };
            if ( this.new_sectors_registration.includes( sector_id ) ) {
                style['background-color'] = 'red';
                style['color'] = 'white';
            }
            return style;
        },
        open_dialog_edit_sector() {
             if( this.div_sector == true){
                this.div_sector = false
            }
            else{
                this.div_sector = true
            }
        },
        reset_sectors() {
            //this.new_sectors = JSON.parse( JSON.stringify( this.sectors ) );
            //this.$store.dispatch('classification/set_selected_sectors_by_id', this.new_sectors);
            this.new_sectors_registration = []
            this.$store.dispatch('classification/set_selected_sectors_by_id', this.new_sectors_registration);
            this.key++;
        },
        remove_sector( type ) {
            console.log("stampo type")
            console.log(type)
            var index = this.selected_sectors.map( x => x.id ).indexOf( type.id );
            this.new_sectors_registration.splice( index, 1 );
           // this.$store.dispatch('classification/set_selected_sectors_by_id', this.new_sectors_registration);
        },
        close_dialog_sector() {
            //var exit = document.getElementsByClassName("mfp-close");
            //exit[0].click();
             if( this.div_sector == true){
                this.div_sector = false
            }
            else{
                this.div_sector = true
            }
        },
    },

}

</script>

<template>

    <div>

        <div @click="open_dialog_edit_sector" style="border: 1px solid #dbdbdb; padding: 6px; min-height: 51px; cursor: pointer;">
            <div v-if="selected_sectors.length === 0" style="vertical-align: middle; color: grey; padding-left: 15px; padding-top: 6px;">Clicca per impostare i settori</div>
            <template v-if="selected_sectors.length > 0">
                <div v-for="type in selected_sectors" style="display: inline; cursor: default;">
                    <span style="font-weight: 600; font-size: 14px; background-color: red; padding: 3px; padding-left: 6px; padding-right: 6px; border-radius: 4px; margin-right: 3px; color: white">{{ type.name }} <i class="fa fa-close" style="cursor: pointer;" @click.stop="remove_sector( type )"></i></span>
                </div>
            </template>
        </div>
       <!--<p style="color: black;">{{new_sectors_registration}}</p>-->
        <!-- Search PopUp -->
          <div v-if="div_sector" id="dialog-sector" ref="dialog_edit_sectors" >
           
            <div class="small-dialog-content" :key="key">
                <div class="col"> <!-- column -->
                    <div class="row"  style="height: 385px; overflow-y: auto; width: 95%; margin: 10px;"> <!-- row -->
                        <div
                            class="col col-xs-12 col-md-6"
                            v-for="sector in all_sectors"
                            :key="'sector' + sector.id"
                            @click="set_active( sector )"
                            >
                            <span
                                :style="get_style( sector.id )"
                                style="padding: 4px; border-radius: 20px; font-weight: 600; cursor: pointer;"
                                >
                                {{ sector.name }}
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-1"> </div>
                        <div class="col-xs-5 text-right"> <!-- column -->
                            <input type="submit" @click="reset_sectors" class="button border fw margin-top-10 button-search" style="padding: 3px; width: 80px; background-color: #FFC107; color: grey;" name="reset" value="Reset" />
                        </div>
                        <div class="col-xs-5 text-right"> <!-- column -->
                            <input type="submit" @click="close_dialog_sector" class="button border fw margin-top-10 button-search" style="padding: 3px; width: 80px;" name="search" value="OK" />
                        </div>
                        <div class="col-xs-1"> </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>


<style>
</style>


import Api from '@/services/Api'

export default {
  updateDatatProfile(id, data) {
    return Api().patch('/model/user/'+id, data)
  },
  saveMetaUser(id, data) {
    return Api().patch('/model/user/'+id+'/meta', data)
  },
  getData(id){
    return Api().get('/model/user/'+id+'?include=true')
  }
}
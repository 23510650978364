<template>
  <div>
    <div class="text-center" v-if="loading" style="position:absolute; top: 70%; left: 32%; height: 500px;">
      <div class="loader"></div>
    </div>
    <div :class="loading ? 'dashboard-list-box margin-top-50 fs-container opacity': 'dashboard-list-box margin-top-50'">
      <h4 class="gray">Immagini Negozio</h4>
      <div class="dashboard-list-box-static">
        <!-- Images -->
        <div class="my-profile">
          <label>Immagine di Logo</label>
          <!-- Logo -->
          <div class="edit-profile-photo">
            <!-- <img :src="logo_db ? config.backend_url+logo_db.path : require('@/assets/images/6-free-image-upload.svg')" alt="upload_logo"> -->
            <div class="col-xs-12">
              <img v-if="logo_db" :src="config.backend_url+logo_db.path" alt="upload_logo">
            </div>
            <div class="change-photo-btn">
              <div class="photoUpload">
                  <span><i class="fa fa-upload"></i> Carica il Logo</span>
                  <!-- <input type="file" class="upload" /> -->
                  <b-form-file v-model="logo" class="upload" accept="image/jpeg, image/jpg, image/png, image/gif" plain></b-form-file>
                  <div class="mt-3">{{ logo ? logo.name : '' }}</div>
              </div>
            </div>
          </div>
          <label>Immagini di Galleria</label>
          <div class="edit-profile-photo">
            <div v-if="gallery_db.length > 0">
              <div style="margin-left: 15px; margin-top: 15px; width: auto; height: 100px; position:relative; float:left background-repeat: no-repeat; background-size: cover;" v-for="(image, i) in gallery_db" :key="i" :style="{backgroundImage: `url(${config.backend_url+image.path})`}">
                <!-- <a href="#" style="dispaly:block; position:absolute;top:0;right:0;width:30px;height:30px;text-indent:-999px;background:red;">Delete</a> -->
                <i class="fa fa-remove" style="cursor:pointer; color:#f91942; dispaly:block; position:absolute;top:0;right:0;width:15px; font-size: 18px;" @click="remove_image_gallery_caricate(image)"></i>
              </div>
            </div>
            <!-- <img :style="gallery_db.length > 0 ? 'width: 100px; height: auto; margin-top: 15px; margin-left: 15px;' : ''" src="@/assets/images/gallery-880815_960_720.png" alt="upload_gallery"> -->
            <div class="change-photo-btn">
              <div class="photoUpload">
                <!-- <span><i class="fa fa-upload"></i> Carica <span v-if="gallery_db.length === 0"> Immagini</span></span> -->
                <span><i class="fa fa-upload"></i> Carica Immagini</span>
                <!-- <input type="file" class="upload" /> -->
                <b-form-file @change="loaded = false" v-model="gallery" multiple class="upload" accept="image/jpeg, image/jpg, image/png, image/gif" plain></b-form-file>
                <!-- <div class="mt-3">{{ gallery. ? logo.name : '' }}</div> -->
              </div>
            </div>
          </div>
          <div v-if="gallery.length > 0 && !loaded">
            <span style="color:#f91942;"><strong>Immagini da caricare</strong></span><br>
            <span v-for="gal1 in gallery" :key="gal1.name">
              <b>{{ gal1.name }}</b>,
            </span>
          </div>

          <!-- <div v-if="gallery_db.length > 0" style="margin-top:20px;">
            <span style="color:#f91942;">Immagini caricate</span><br>
            <span v-for="gal in gallery_db" :key="gal">
              <b>{{ gal.original_name }}</b> <i class="fa fa-remove" style="cursor:pointer; color:#f91942;" @click="remove_image_gallery_caricate(gal)"></i>,
            </span>
          </div> -->

          <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
            <span style="color:red;" class="col-sm-12 text-center">
              <span v-html="error"></span>
            </span>
          </div>
          
          <div class="col-sm-12" v-if="success" style="border: 1px solid green; padding: 20px; border-radius: 4px;">
            <span style="color:green;" class="col-sm-12 text-center">
              <span v-html="success ? 'Dati Aggiornati!' : ''"></span>
            </span>
          </div>
          
          <button class="button margin-top-15" @click="upload_images">Salva</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShopService from '@/services/ShopService'
import ImageService from '@/services/ImageService'
import config from '@/config'
import Vue from 'vue'
import {FormCheckboxPlugin, BFormFile, SpinnerPlugin} from 'bootstrap-vue'
Vue.component('b-form-file', BFormFile)
Vue.use(SpinnerPlugin)
export default {
  name: 'shop-images',
  data() {
    return {
      loading: true,
      loaded: false,
      success: false,
      error: null,
      logo: null,
      gallery: [],
      logo_db: null,
      gallery_db: [],
      config: null
    }
  },
  computed: {
    get_gallery_db: function(){
      return this.gallery_db
    }
  },
  async mounted() {
    this.config = config
    try {
      // let shop_images = await ShopService.getShopMeta(this.$store.state.user.user.myworkshops[0].id)
      // console.log(shop_images.data.logo, shop_images.data.gallery)
      // shop_images.data.logo ? shop_images.data.logo.value ? shop_images.data.logo.value.id = shop_images.data.logo.id : null : null
      // this.$store.dispatch('shop_images/setLogo', shop_images.data.logo.value)
      // this.$store.dispatch('shop_images/setGallery', shop_images.data.gallery.map((el) => {
      //    el ? el.value ? el.value.id = el.id : null : null
      //    return el.value
      //   }))
      this.logo_db = this.$store.state.shop_images.logo
      this.gallery_db = this.$store.state.shop_images.gallery
    } catch (error) {
      console.log('err: ', error)
      error.response ? this.error = error.response.data.message : this.error = error + '! Ricarica la pagina'
    } finally {
      this.loading = false
    }
  },
  methods: {
    async remove_image_gallery_caricate (image) {
      this.loading = true
      console.log(image)
      try {
        let risDelete = await ImageService.deleteImageGallery(this.$store.state.user.user.myworkshops[this.$route.query.shop].id,image.id)
        this.gallery_db = this.gallery_db.filter(el => el.original_name !== image.original_name)
        this.$store.dispatch('shop_images/setGallery', this.$store.state.shop_images.gallery.filter(el => el.original_name !== image.original_name))
        console.log('delete: ',risDelete)
        this.error = null
        this.success = true
        setTimeout(() => {
          this.success = false
          //location.reload()
        }, 2000)
      } catch (error){
        this.success = false
        console.log(error)
        error.response ? this.error = error.response.data.message : this.error = error
        setTimeout(() => {
          this.error = null
        }, 3000)
      } finally {
        this.loading = false
      }
    },
    async upload_images() {
      // carica una alla volta le immagini
      this.loading = true
      try {
        let fd_logo = new FormData()
        if (this.logo) {
          fd_logo.append('file',this.logo)
          let risLogo = await ImageService.saveLogo(fd_logo,this.$store.state.user.user.myworkshops[this.$route.query.shop].id)
          console.log('ris logo: ', risLogo)
          risLogo.data.value.id = risLogo.data.id
          this.$store.dispatch('shop_images/setLogo', risLogo.data.value)
          this.logo_db = this.$store.state.shop_images.logo
        }

        let fd_gallery = new FormData()
        let gallery = this.$store.state.shop_images.gallery
        if (this.gallery.length > 0) {
          for (let i = 0; i<this.gallery.length; i++) {
            fd_gallery.append('file',this.gallery[i])
            fd_gallery.append('type','gallery_image')
            let risGallery = await ImageService.saveGallery(fd_gallery,this.$store.state.user.user.myworkshops[this.$route.query.shop].id)
            console.log('ris gallery '+ i + ': ', risGallery)
            risGallery.data.value.id = risGallery.data.id
            gallery.push(risGallery.data.value)
            fd_gallery = new FormData()
          }
        }
        this.$store.dispatch('shop_images/setGallery', gallery)
        this.gallery_db = this.$store.state.shop_images.gallery
        this.error = null
        this.success = true
        this.loaded = true
        this.loading = false
        this.logo=null
        this.gallery=[]
        setTimeout(() => {
          this.success = false
          //location.reload()
        }, 2000)
        return 1
      } catch (error) {
        this.success = false
        console.log(error)
        error.response ? this.error = error.response.data.message : this.error = error
        this.loading = false
        setTimeout(() => {
          this.error = null
        }, 3000)
        return
      }
    }
  }
}
</script>

<style scoped>
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #fa3256;
  width: 140px;
  height: 140px;
  margin:auto;
  margin-top: 250px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.change-photo-btn {
  position: relative;
  left: 0px;
  bottom: 0px;
  top:15px;
}
</style>
<template>
  <div id="wrapper"> 
     <div id="dashboard">
      <HeaderDashboard v-if="!loading"/>
      <a href="#" class="dashboard-responsive-nav-trigger"><i class="fa fa-reorder"></i> Dashboard Navigation</a>
      <SideBar/>
      <!-- Content
      ================================================== -->
      <div class="dashboard-content">

        <!-- Titlebar -->
        <div id="titlebar">
          <div class="row">
            <div class="col-md-12">
              <h2>I miei Negozi</h2>
              <!-- Breadcrumbs -->
              <nav id="breadcrumbs">
                <ul>
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Dashboard</a></li>
                  <li>I miei negozi</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="text-center" style="height: 500px;" v-if="loading">
          <div class="loader"></div>
        </div>
        <div v-if="!loading" :class="getting_result ? 'row opacity' : 'row'">
          <!-- Listings -->
          <div class="col-lg-12 col-md-12">
            <div class="dashboard-list-box margin-top-0">
              <div class="col-xs-12" style="background-color:white; padding-left:0px; padding-right:0px; border-bottom: 1px solid #eaeaea;">
                <h4 style="float: left; border-bottom:none;">{{ get_tot_shops }} Negozi{{ get_tot_shops === 1 ? 'o' : '' }} Preferit{{ get_tot_shops === 1 ? 'o' : 'i' }}</h4>
                 <b-form-select style="float:right; margin: 15px 30px; padding: 0px 18px;"  class="custom_select col-xs-10 col-md-4" v-model="shop_type" :options="get_shop_types"></b-form-select>
              </div>
              <!--  -->
              <div style="clear:both;"></div>
              <ul>

                <li v-for="(shop, index) in get_shops_list" :key="index">
                  <div class="list-box-listing" @click="goPageShop(shop)">
                    <div class="list-box-listing-img">
                      <img :src="shop.gallery.length > 0 ? config.backend_url+shop.gallery[0].value.path : require('@/assets/images/noimage.png')" alt="no-image">
                      <span class="tag">{{ shop.mytypes[0].label }}</span>
                    </div>
                    <div class="list-box-listing-content">
                      <div class="inner">
                        <h3>{{ shop.label }}</h3>
                        <span>{{ shop.mytypes[0].label }}</span>
                        <div class="col-xs-12">
                          <span v-if="shop.metas.contacts.value.telefono"><i class="im im-icon-Old-Telephone"></i> {{ shop.metas.contacts.value.telefono ? shop.metas.contacts.value.telefono : 'Non specificato' }}</span>
                        </div>
                        <div class="col-xs-12">
                          <span v-if="shop.metas.contacts.value.cellulare"><i class="im im-icon-Smartphone-3"></i> {{ shop.metas.contacts.value.cellulare ? shop.metas.contacts.value.cellulare : 'Non specificato' }}</span>
                        </div>
                        <div class="col-xs-12">
                          <span v-if="shop.metas.contacts.value.email"><i class="im im-icon-Email"></i> {{ shop.metas.contacts.value.email ? shop.metas.contacts.value.email : 'Non specificato' }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="buttons-to-right">
                    <button @click="removeFavorite(shop)" class="button gray"><i class="sl sl-icon-close"></i> Rimuovi</button>
                  </div>
                </li>

                <div v-if="get_tot_shops === 0" class="text-center">
                  <h4 style="color: #888;">Non hai ancora nessun negozio preferito!</h4>
                </div>

                <!-- <li>
                  <div class="list-box-listing">
                    <div class="list-box-listing-img"><a href="#"><img src="images/listing-item-04.jpg" alt=""></a></div>
                    <div class="list-box-listing-content">
                      <div class="inner">
                        <h3>Burger House</h3>
                        <span>2726 Shinn Street, New York</span>
                        <div class="star-rating" data-rating="5.0">
                          <div class="rating-counter">(31 reviews)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="buttons-to-right">
                    <a href="#" class="button gray"><i class="sl sl-icon-close"></i> Delete</a>
                  </div>
                </li>

                <li>
                  <div class="list-box-listing">
                    <div class="list-box-listing-img"><a href="#"><img src="images/listing-item-06.jpg" alt=""></a></div>
                    <div class="list-box-listing-content">
                      <div class="inner">
                        <h3>Think Coffee</h3>
                        <span>215 Terry Lane, New York</span>
                        <div class="star-rating" data-rating="5.0">
                          <div class="rating-counter">(31 reviews)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="buttons-to-right">
                    <a href="#" class="button gray"><i class="sl sl-icon-close"></i> Delete</a>
                  </div>
                </li> -->

              </ul>
              <!-- <List ref="list" v-show="!loading" :loading="loading"/> -->
            </div>
          </div>


          <!-- Copyrights -->
          <div class="col-md-12">
            <div class="copyrights" style="font-size:12px;">
            <!-- <div class="col-xs-12 text-center">
              CONFCOMMERCIO SERVIZI DELLA CITTA' DELLA RIVIERA DEL BRENTA S.R.L.
            </div>
            <div class="col-xs-12 text-center">
                sede legale: Viale Ancona 9 30172 Mestre Ve
            </div>
            <div class="col-xs-12 text-center">
              sedi operative:
            </div>
            <div class="col-xs-12 text-center">
              Via Venezia 8 30034 Mira VE
            </div>
            <div class="col-xs-12 text-center">
              Via Benedetto Cairolo 18/D  30031 Dolo VE
            </div> -->
            <div class="col-xs-12 text-center">
              C.f.  e P.Iva 04498740275
            </div>
            <!-- <div class="col-xs-12 text-center">
              codice sdi  6EWHWLT
            </div> -->
            <div class="col-xs-12 text-center">
              <a class="privacy_policy_footer iubenda-black iubenda-embed" href="https://www.iubenda.com/privacy-policy/82105399" title="Privacy Policy ">Privacy e Policy</a>
            </div>
            <div class="col-xs-12 text-center">
              <router-link to="/all-shops">Tutti i Negozi</router-link>
            </div>
            <div class="col-xs-12 text-center">
              <a style="color: grey" href="mailto:assistenza@vetrineonline.com">assistenza@vetrineonline.com</a></h4>
            </div>
          </div>
          </div>

        </div>

      </div>
      <!-- Content / End -->
     </div>
  </div>
</template>

<script>
import HeaderDashboard from '@/components/HeaderDashboard'
import SideBar from '@/components/SideBar'
import List from '@/components/ListingPage/List'
import ListingService from '@/services/ListingService'
import config from '@/config'
import { FormSelectPlugin } from 'bootstrap-vue'
import Vue from 'vue'
Vue.use(FormSelectPlugin)
export default {
  name: 'dashboard-my-shops',
  components: {
    HeaderDashboard,
    SideBar,
    List
  },
  data() {
    return {
      loading: true,
      getting_result: false,
      shop_list_length: null,
      shop_list: [],
      tot_shops: null,
      shop_type: null,
      options_shop_type: [
        { value: null, text: 'Tutte le categorie' }
      ],
      page: null,
      config: null
    }
  },
  async mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    } else {
      this.page = 1
    }
    this.loading = true
    this.config = config
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        document.body.appendChild(custom)
      })
    
    // Getting shops
    // const list_shops = this.$store.state.my_shops.my_shops
    // console.log('list shops: ', list_shops)
    // this.shop_list_length = list_shops.length
    // this.shop_list = list_shops
    // this.$refs['list'].shop_list = list_shops
    // list_shops.forEach(element => {
    //   !this.options_shop_type.includes(element.mytypes[0].id) ? this.options_shop_type.push({value: element.mytypes[0].id, text: element.mytypes[0].label}) : this.options_shop_type
    // })
    this.loading = false
  },
  computed: {
    get_shops_list: function() {
      if (!this.shop_type)
        return this.$store.state.my_shops.my_shops
      else {
        return this.$store.state.my_shops.my_shops.filter(el => el.mytypes[0].id === this.shop_type)
      }
    },
    get_tot_shops: function() {
      if (!this.shop_type)
        return this.$store.state.my_shops.my_shops.length
      else {
        return this.$store.state.my_shops.my_shops.filter(el => el.mytypes[0].id === this.shop_type).length
      }
    },
    get_shop_types: function() {
      let types = [];
      types.push({ value: null, text: 'Tutte le categorie' });
      this.$store.state.my_shops.my_shops.forEach(element => {
        types.push({ value: element.mytypes[0].id, text: element.mytypes[0].label });
      });
      return types;
    }
  },
  methods: {
    async removeFavorite(shop) {
      try {
        this.loading = true
        const user = await ListingService.removeFavoriteShop(shop.id)
        console.log('deleted shop user: ', user)
        this.$store.dispatch('my_shops/setMyShops', user.data.myshops)
        // const list_shops = this.$store.state.my_shops.my_shops
        // console.log('list shops updated: ', list_shops)
        // this.shop_list_length = list_shops.length
        // this.shop_list = list_shops
        this.shop_type = null
        this.loading = false
      } catch(err) {
        console.log('error in removeFavorite(): ', err)
      }
    },
    goPageShop(shop) {
      this.$router.push({
        path: '/shop?id='+shop.id
      })
    }
  }
}
</script>

<style scoped>
  .tag {
    position: absolute;
    bottom: 5px;
    left: 5px;
    margin: 0;
    z-index: 10;
    line-height: 14px;
    padding: 7px 16px;
    margin-right: 20px;
    text-align: center;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 2.5px;
    color: #fff;
    font-weight: 400;
    background: #f91942;
    border-radius: 50px;
  }
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .list-box-listing-img {
    max-width: 230px;
  }
  .list-box-listing {
    cursor: pointer;
  }
</style>

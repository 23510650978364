<script>
import ListingService from '@/services/ListingService'
const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]

export default {

    name: 'classification-subcat-list',
    props: [ 'input_subcategories','input_categories', 'cat_id', ],
    data() {
        return {
            active        : null,
            list_key      : 1,
            subcategories : [],
            anchors       : {},
        };
    },
    watch: {
        cat_id( newvalue, oldvalue ) {
            if ( newvalue ) {
                var container = document.getElementById('subcat_list');
                var anchor = document.getElementById(`subcat${ newvalue }`);
                if ( !container || !anchor ) { return; }
                var actual_scroll = container.scrollTop;
                var rect_container = container.getBoundingClientRect();
                var rect_anchor = anchor.getBoundingClientRect();
                var top = rect_anchor.top - rect_container.top + actual_scroll;
                container.scrollTop = top;
            }
        },
        categories( newvalue, oldvalue ) {
            if ( newvalue.length > 0 && newvalue[0].children > 0 ) {
            }
        },
    },
    computed: {
      categories() { return this.$store.getters['classification/categories'] || [] },
      selected_ids: {
          get() { return this.$store.state.classification.selected_ids },
          set( selected_ids ) { return this.$store.dispatch( 'classification/set_selected_ids', selected_ids ); },
      },
      is_edit() { return typeof this.input_subcategories !== 'undefined' }
    },
    methods: {
      set_active( cat ) {
        this.active = cat.id;
            console.log( this.is_edit );
        if ( this.is_edit === false ) {
            if ( this.selected_ids.map( x => x.id ).includes( cat.id ) === false ) {
                this.selected_ids.push( { id: cat.id, label: cat.name } );
                console.log("selected ids")
                console.log(this.selected_ids)
            } else {
                var selected_ids = JSON.parse( JSON.stringify( this.selected_ids ) );
                var index = selected_ids.map( x => x.id ).indexOf( cat.id );
                selected_ids.splice( index, 1 );
                this.selected_ids = selected_ids;
            }
        } else {
            var obj = { action: 'add', subcat: cat };
            if ( this.input_subcategories.includes( cat.id ) === false ) {
                //this.input_subcategories.push( { id: cat.id, label: cat.name } );
                //this.input_subcategories.push( cat.id );
            } else {
                obj.action = 'remove';
                var selected_ids = JSON.parse( JSON.stringify( this.input_subcategories ) );
                var index = selected_ids.indexOf( cat.id );
                selected_ids.splice( index, 1 );
                //this.input_subcategories = selected_ids;
            }
            this.$emit( 'set-subcategory', obj );
        }
      },
      set_active_cat( cat ) {
        this.active = cat.id;
            console.log( this.is_edit );
        if ( this.is_edit === false ) {
            if ( this.selected_ids_c.map( x => x.id ).includes( cat.id ) === false ) {
                this.selected_ids_c.push( { id: cat.id, label: cat.name } );
                console.log("selected ids_c")
                console.log(this.selected_ids_c)
            } else {
                var selected_ids_c = JSON.parse( JSON.stringify( this.selected_ids_c ) );
                var index = selected_ids_c.map( x => x.id ).indexOf( cat.id );
                selected_ids_c.splice( index, 1 );
                this.selected_ids_c = selected_ids_c;
            }
        } else {
            var obj = { action: 'add', subcat: cat };
            if ( this.input_categories.includes( cat.id ) === false ) {
                //this.input_subcategories.push( { id: cat.id, label: cat.name } );
                //this.input_subcategories.push( cat.id );
            } else {
                obj.action = 'remove';
                var selected_ids_c = JSON.parse( JSON.stringify( this.input_categories ) );
                var index = selected_ids_c.indexOf( cat.id );
                selected_ids_c.splice( index, 1 );
                //this.input_subcategories = selected_ids;
            }
            this.$emit( 'set-category', obj );
        }
      },
     
      set_subcategories() {
        this.categories.map( x => {
          if ( x.id === this.cat_id ) { this.subcategories = x.children; }
        })
      },

      is_selected( subcat_id ) {
        if ( this.input_subcategories && Array.isArray( this.input_subcategories ) ) {
            return this.input_subcategories.includes( subcat_id );
        }
        return false;
      },
      get_style( subcat_id ) {
          var style = {
              'background-color' : 'white',
              'color'            : 'grey',
          };
          if ( this.is_selected( subcat_id ) ) {
              style['background-color'] = 'red';
              style['color'] = 'white';
          } else if ( this.selected_ids.map( x => x.id ).includes( subcat_id ) ) {
              style['background-color'] = 'red';
              style['color'] = 'white';
          }
          return style;
        },
        
    },
    created () {
        if ( this.cat_id ) { this.active = this.cat_id; }
    },

}

</script>

<template>
    <div style="margin-left: 30px; height: 405px; overflow-y: auto" id="subcat_list">
        <template v-for="cat in categories">
            <div :key="cat.id" @click="set_active( cat )" style="font-weight: bold; margin-top: 20px; margin-bottom: 7px;">
                <span :style="get_style( cat.id )" :id="'subcat' + cat.id" style="padding: 4px; border-radius: 10px; cursor: pointer;">
                    {{ cat.name.toUpperCase() }}
                   <!--<h5 :id="'subcat' + cat.id" style="font-weight: bold; margin-top: 20px; margin-bottom: 7px;">{{ cat.name.toUpperCase() }}</h5>-->
                </span>
            </div>
            <div class="row" style="width: 80%">
                <div
                    class="col col-xs-12 col-md-6"
                    v-for="subcat in cat.children"
                    :key="subcat.id"
                    @click="set_active( subcat )"
                    >
                    <span
                        :style="get_style( subcat.id )"
                        style="border-radius: 20px; font-weight: 600; cursor: pointer;"
                        > <!-- tolto da style padding: 4px; -->
                        {{ subcat.name }}
                    </span>
                </div>
            </div>
        </template>
    </div>

</template>


<style>
</style>


<template>
  <div>
    <div class="text-center" v-if="loading" style="position:absolute; top: 15%; left: 32%; height: 500px;">
      <div class="loader"></div>
    </div>
    <div :class="loading ? 'dashboard-list-box margin-top-0 fs-container opacity' : 'dashboard-list-box margin-top-0'">
    <h4 class="gray">Informazioni Generali</h4>
    <div class="dashboard-list-box-static">
      
      <!-- Details -->
      <div class="my-profile">

        <p class="form-row form-row-wide">
          <label for="shop_name">Nome Negozio:
            <i class="im im-icon-Shop"></i>
            <input v-model="shop_name" type="text" maxlength="64" :disabled="success" :class="error_shop_name || be_error_shop_name ? 'input-text is-invalid' : validationShopName() ? 'input-text is-valid' : 'input-text' " name="shop_name" id="shop_name"/>
          </label>
          <span class="error" v-if="error_shop_name">Inserisci il nome del negozio <i>(almeno 5 caratteri)</i></span>
          <span class="error" v-if="be_error_shop_name">{{ be_error_shop_name }}</span>
        </p>

         <p>
        <label for="shop_name">Settori:</label>
            <SectorEditRegistration ref="classification_sector" v-if="classification" :sectors="classification.s" />
        </p>
        <p class="form-row form-row-wide">
        <!--<label for="shop_name">Categoria Negozio:
            <b-form-select v-model="shop_type" :options="options_shop_type" :disabled="success" :state="error_shop_type ? false : validationShopType() ? true : null"></b-form-select>
        </label>-->
        <label for="shop_name">Categorie:</label>
        <ClassificationEditRegistration ref="classification_category"  v-if="classification" :classification="{ c: classification.c, u: classification.u }" @submit-save=""  :state="error_shop_type ? false : validationShopType() ? true : null" />
        
        <span class="error" v-if="error_shop_type">Inserisci la tipologia</span>
        <span class="error" v-if="be_error_shop_type">{{ be_error_shop_type }}</span>
      </p>

        <label>Ragione Sociale</label>
        <input style="color:#333;" maxlength="1024" placeholder="Inserisci la ragione sociale.." v-model="ragione_sociale" :disabled="success" type="text" :class="false ? 'input-text is-invalid' : success ? 'input-text is-valid' : 'input-text' ">


       <p class="form-row form-row-wide">
        <label for="pi">Partiva Iva:
        <i class="im im-icon-Fountain-Pen"></i>
        <input v-model="pi" type="text" maxlength="11" :disabled="success" :class="error_pi || be_error_pi ? 'input-text is-invalid' : validationPI() ? 'input-text is-valid' : 'input-text' " name="pi" id="pi"/>
        </label>
        <span class="error" v-if="error_pi">Inserisci la partita iva <i>(valida)</i></span>
        <span class="error" v-if="be_error_pi">{{ be_error_pi }}</span>
      </p>

        <p class="form-row form-row-wide">
          <label for="cf">Codice Fiscale:
          <i class="im im-icon-Identification-Badge"></i>
          <input v-model="cf" type="text" maxlength="16" :disabled="success" :class="error_cf || be_error_cf ? 'input-text is-invalid' : validationCF() ? 'input-text is-valid' : 'input-text' " name="cf" id="cf"/>
          </label>
          <span class="error" v-if="error_cf">Inserisci il codice fiscale o la partita iva <i>(validi)</i></span>
          <span class="error" v-if="be_error_cf">{{ be_error_cf }}</span>
        </p>

        <div class="col-sm-12" v-if="error">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        
        <div class="col-sm-12" v-if="success">
          <span style="color:green;" class="col-sm-12 text-center">
            <span v-html="success ? 'Dati Aggiornati!' : ''"></span>
          </span>
        </div>
        <button :class="checkForm() ? 'button border fw margin-top-10' : 'button border fw margin-top-10'" @click="createShop()">Crea</button>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ShopService from '@/services/ShopService'
import DefaultService from '@/services/DefaultService'
import AuthenticationService from '@/services/AuthenticationService'
import ClassificationEditRegistration from '@/components/classification/classification_edit_registration'
import SectorEditRegistration from '@/components/classification/sector_edit_registration'
import { CodiceFiscale } from '@/utils/validations/codice_fiscale.js'//
import config from '@/config'
import Vue from 'vue'
import { FormSelectPlugin } from 'bootstrap-vue'
Vue.use(FormSelectPlugin)

export default {
  name: 'shop-data',
  components: {
   ClassificationEditRegistration, 
   SectorEditRegistration
  },
  data () {
    return {
      loading: true,
      config: null,
      shop_name: '',
      pi: '',
      cf: '',
      ragione_sociale: null,
      shop_type: null,
      options_shop_type: [
        { value: null, text: 'Scegli una categoria' }
      ],
      classification     : {},
      sectors            : [],
      success: false,
      error_shop_name: null,
      error_shop_type: null,
      error_pi: null,
      error_cf: null,
      
      be_error_shop_name: null,
      be_error_shop_type: null,
      be_error_pi: null,
      be_error_cf: null,
      error: null
    }
  },
  created () {
    this.config = config
  },
  async mounted() {
      console.log(this.$store.state.user.user.id)
    try {
      const type = await DefaultService.getTypeDefault();
      if (type) {
        type.data.forEach(element => {
          this.options_shop_type.push({value: element.id, text: element.label})
        });
        this.classification = this.$store.state.shop.shop.classification || {};
  
      }
    } catch(error) {
      console.log('err: ', error)
      error.response ? this.error = error.response.data.message : this.error = error + '! Ricarica la pagina'
    } finally {
      this.loading = false
    }
  },
  methods: {
      validationShopName() {
      if (this.shop_name != '' && this.shop_name.length >= 5) {
        this.error_shop_name = false
        return true
      }
      return false
    },
    validationShopType() {
      /*
       sif (this.shop_type != null) {
        this.error_shop_type = false
        return true
      }
      return false
      */
      console.log("Stampo classification riga: ")
      console.log(this.classification)
      if (this.classification != null) {
        //TODO controllare che ci sia c,u,s
        if((this.classification.c!=null || this.classification.c!='') && (this.classification.u!=null || this.classification.u!='') && (this.classification.s!=null || this.classification.s!='')){
          console.log("in classification ci sono u,s,c")
          if(this.classification.c.length>0 && this.classification.u.length>0){
            this.error_shop_type = false
            return true
          }
        }  
      }
      return false
    },
    validationPI() {
      if (this.pi !== '') {
        this.error_pi = null
        this.error_cf = null
        return true
      }
      return false
    },
    
    validationCF() {
      if (this.cf === '' && this.pi !== '' /*&& this.validationPI()*/) {
        // this.error_pi = null
        this.error_cf = null
        return true
      }
     
      if (!CodiceFiscale.validate(this.cf) || this.cf!== '') {
        // this.error_pi = null
        this.error_cf = null
        return true
      }
      return false
    },

    checkForm() {
      if (this.validationShopName() &&
        this.validationShopType() &&
        this.validationPI() &&
        this.validationCF()) {
          // this.error = null
          return true
        }
      return false
    },
    checkValidationPreSave() {
      if (!this.checkForm()) {
        this.error = 'Errore! Controlla tutti i campi'
        if (!this.validationShopName())
          this.error_shop_name = true
        if (!this.validationShopType())
          this.error_shop_type = true
        if (!this.validationPI())
          this.error_pi = true
        if (!this.validationCF())
          this.error_cf = true
        
        return false
      } else {
        return true
      }
    },
    resetError() {
      this.error_shop_name = null,
      this.error_shop_type = null,
      this.error_pi = null,
      this.error_cf = null,
      this.be_error_shop_name = null,
      this.be_error_shop_type = null,
      this.be_error_pi = null,
      this.be_error_cf = null,
      this.error = null
    },

    async createShop() {
      this.loading = true
      this.resetError()
      var new_classification = {
          s: this.$refs['classification_sector'].new_sectors_registration,
          c: this.$refs['classification_category'].new_classification.c,
          u: this.$refs['classification_category'].new_classification.u,
      };
      this.classification = new_classification
      if(!this.checkValidationPreSave()) {
        this.loading = false
        return 0
      } else {
        try {
          console.log("valore shop type "+this.shop_type)
            this.error = null
            const response = await AuthenticationService.createShop({
              partita_iva: (this.pi.length > 0 ? this.pi : null),
              label: this.shop_name,
              user_id:this.$store.state.user.user.id,
              codice_fiscale: (this.cf.length > 0 ? this.cf : null),
              classification: this.classification,
              type: new_classification.u[0],//this.shop_type,
            /**
             * {
                "partita_iva":"01234567891",
                "label":"nuovo",
                "user_id":145,
                "codice_fiscale":"GLLSNT98E43L424L",
                "classification":"{}",
                "type":15
            }
             */
          })
          if (response) {
            console.log(response)
            //this.$store.dispatch('setToken', response.data.token)
            // this.$store.dispatch('user/setUser', response.data.user)
            let risLogin = /*await this.login()*/ 1
            if (risLogin === 1) {
              this.success = true
              setTimeout(() => {
                location.reload();
                // window.location.href = '/dashboard-company-my-shop'
              }, 3000)
            } else {
              this.error = 'Errore durante la creazione dello shop!'
              setTimeout(() => {
                this.error = null
              }, 3000)
            }
          }
        } catch (error) {
          if (error.response) {
            if (error.response.data.message === 'dati non validi') {
              Object.keys(error.response.data.errors).forEach(el2 => {
                
                if (el2 === 'partita_iva') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_pi = error.response.data.errors[el2].message
                }
                if (el2 === 'codice_fiscale') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_cf = error.response.data.errors[el2].message
                }
                if (el2 === 'label') {
                  this.error = error.response.data.errors[el2].message
                  this.be_error_shop_name = error.response.data.errors[el2].message
                }
                
                if (el2 === 'model') {
                  this.error = error.response.data.errors[el2].message
                }
              })
            } else {
              this.success = false
              this.error = error.response.data.message || error.response.data.error
              console.log(error)
            }
          } else {
            this.error = error
          }
          setTimeout(() => {
          }, 3000)
        } finally {
          this.loading = false
        }
      }
    },
  }
}
</script>

<style scoped>
  .error {
    color: #f91942;
  }
  .is-invalid {
    color: #f91942 !important;
    border-color: #f91942 !important;
  }
  .is-valid {
    /* color: green !important; */
    border-color: green !important;
    /* background: #e6e6e6 !important; */
  }
  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
  }

  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
  }
  .privacy {
    color: #333;
    cursor: pointer;
    transition: .3s;
  }
  .privacy:hover {
    color: #f91942 !important;
    transition: .3s;
  }
  /* .register {
    height: 500px;
    overflow-y: auto;
  } */
  .loader_login {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #fa3256;
    width: 70px;
    height: 70px;
    margin: auto;
    margin-top: 250px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
</style>